<template>
  <div id="app_holder">

    <div id="content">
          <Sidebar/>

        <div class="container flex mb32 settings-panel">
          <SidebarSettings/> 

          <div data-v-63b81b20="" class="content ml32 ml0-sm-down mt32-sm-down">
            <div class="panel big-shadow">


                <div class="toast-container position-fixed top-50 start-50 translate-middle">
                    <div class="toast align-items-center border-0 fade" :class="{'show': showToastError ,'text-bg-danger': showToastError }" role="alert" aria-live="assertive" aria-atomic="true">
                        <div class="d-flex">
                            <div class="toast-body">
                                <p>{{errorMessage}}</p>
                            </div>
                            <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                    </div>
                </div>


                <div class="toast-container position-fixed top-50 start-50 translate-middle">
                    <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-success': showToast }" role="alert" aria-live="assertive" aria-atomic="true">
                        <div class="d-flex">
                            <div class="toast-body">
                                <p>{{message}}</p>
                            </div>
                            <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                    </div>
                </div>


                <div data-v-9074b6f5="" class="row mb24">
                    <div data-v-9074b6f5="" class="col-sm-7">
                    <h1 data-v-9074b6f5="" class="text-bold mb8">{{$t('settingsSection.walletsTl')}}</h1>
                    </div>
                    <div data-v-9074b6f5="" class="col-sm-5 col-xs-12 text-right">
                        <button data-v-9074b6f5="" @click="openModal" class="btn primary normal outline fluid">{{$t('settingsSection.addWlBtn')}}</button>
                    </div>
                </div>

                <div data-v-d723abed="" data-v-9074b6f5="" class="table-wrap relative table">
                            <div data-v-d723abed="" class="table-container">
                            <table data-v-d723abed="" cellspacing="0" cellpadding="0" class="nh padding-normal">
                                <thead data-v-d723abed="">
                                    <tr data-v-d723abed="">
                                        <th data-v-d723abed="" class="">
                                            {{$t('settingsSection.addressName')}}
                                        </th>
                                        <th data-v-d723abed="" class="">
                                            {{$t('settingsSection.wallet')}}
                                        </th>
                                        <th data-v-d723abed="" class="text-right">
                                            {{$t('settingsSection.actions')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody data-v-d723abed="">
                                    <tr data-v-d723abed="" class="" v-for="(wallet, index) in wallets" :key="index">
                                        <td data-v-d723abed="" class="">
                                            <span data-v-518562c9="" data-v-257941bc="" class="text-primary">{{wallet.wallet_name}} </span>
                                        </td>
                                        <td data-v-d723abed="" class="">
                                            <span data-v-257941bc="">{{ wallet.wallet }}</span>
                                        </td>
                                        <td data-v-d723abed="" class="text-wrap text-right">
                                            <i class="fa fa-trash pointer" @click="deleteWallet(wallet.id)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="overlay loader" v-if="isLoadingTable">
                                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                            </div>
                            
                            <div data-v-d723abed="" class="empty-state" v-if="wallets.length === 0 && !isLoadingTable">
                                <div data-v-d723abed="" class="empty-content">
                                    <div data-v-d723abed="" class="empty-icon">
                                        <i data-v-d723abed="" class="far fa-file"></i>
                                    </div>
                                    <div data-v-d723abed="" class="empty-title">{{$t('settingsSection.noData')}}</div>
                                </div>
                            </div>




                            </div>
                            
                        </div>


            </div>
          </div>


          <div data-v-eee2d117="" class="modal-bg" :class="showModal ? 'show' : ''">
                        <span data-v-eee2d117="">
                            <div data-v-eee2d117="" class="modal-add show" role="dialog">
                                <div data-v-96a15e09="" data-v-eee2d117="">
                                    <div class="modal-head">
                                    <div class="title">{{$t('settingsSection.addWallet')}}</div>
                                    <i class="fa fa-times pointer close" aria-label="Close" @click="closeModal"></i>
                                    </div>
                                    
                                    <div class="modal-content2">
                                    <div data-v-96a15e09="" class="content2 mt-4">
                                        <div data-v-96a15e09="" class="section2">
                                            <form @submit.prevent="addWallet">
                                            <div class="field-wrap input-undefined">
                                                <div class="label-row">
                                                    <label>{{$t('settingsSection.selectCurrency')}} </label>
                                                </div>
                                                <div class="selectbox large deposit-to" :class="{ open: isDropdownOpen }" tabindex="0">
                                                    <div class="field" @click="toggleDropdown" aria-haspopup="true" v-click-outside="hide" :aria-expanded="isDropdownOpen ? 'true' : 'false'">
                                                        <div class="arrows">
                                                            <i class="fa fa-caret-up"></i>
                                                            <i class="fa fa-caret-down"></i>
                                                        </div>
                                                        <span>
                                                            <img :src="getCurrencyImage(selectedCurrency)">
                                                            <div class="name">{{ selectedCurrency.label }}</div>
                                                        </span>
                                                    </div>
                                                    <div class="dropdown flex flex--no-wrap">
                                                        <div class="options" role="listbox">
                                                            <div class="option" 
                                                            :class="selectedCurrency.value === currency.value ? 'selected' : ''" 
                                                            role="option" 
                                                            :aria-selected="selectedCurrency.value === currency.value ? true :false" 
                                                            v-for="(currency, index) in supportedCurrencies" 
                                                            :key="index"
                                                            @click="changeCurrency2(currency)"
                                                            >
                                                            <img :src="getCurrencyImage(currency)">
                                                            <div class="name">{{ currency.label }}</div>    
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>

                                                <div data-v-45b1ddc6="" data-v-96a15e09="" class="field-wrap input-undefined">
                                                <div data-v-45b1ddc6="" class="label-row">
                                                <label data-v-45b1ddc6="">
                                                    {{$t('settingsSection.addressName')}}
                                                </label>
                                                </div>
                                                <div data-v-f5470495="" data-v-96a15e09="" class="input-group mb16">
                                                <input data-v-f5470495="" v-model="walletName" class="mb16 form-control" autocomplete="new-password" type="text" placeholder="">
                                                </div>
                                            </div>

                                            <div data-v-45b1ddc6="" data-v-96a15e09="" class="field-wrap input-undefined">
                                                <div data-v-45b1ddc6="" class="label-row">
                                                <label data-v-45b1ddc6="">
                                                    {{$t('settingsSection.wallet')}}
                                                </label>
                                                </div>
                                                <div data-v-f5470495="" data-v-96a15e09="" class="input-group mb16">
                                                <input data-v-f5470495="" v-model="walletAddress" class="mb16 form-control" autocomplete="new-password" type="text" placeholder="">
                                                </div>
                                            </div>
                                            <div data-v-96a15e09="" class="text-center">

                                                <button type="submit" class="btn primary normal fluid mt24" :disabled="isSubmitting">
                                                    <span v-if="!isSubmitting">{{$t('settingsSection.addWallet')}}</span>
                                                    <span v-else>
                                                        <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                                        {{ $t('loggingIn') }}...
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <div class="spacer"></div>
                                </div>
                            </div>
                        </span>
                    </div>




        </div>




    </div><!-- content -->


    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue';
import SidebarSettings from './SidebarSettings.vue';
import axios from 'axios';
import clickOutside from '../directives/clickOutside';
import WAValidator from 'multicoin-address-validator';
export default {
  name: 'CloudhashxFrontendWithdrawAddresses',
  components: { FooterDash, SidebarSettings, Sidebar },
  data() {
    return {
        showMessage: false,
        message: '',
        showToast: false,
        toastTimeout: null,
        isDropdownOpen: false,
        isLoadingTable: false,
        isSubmitting: false,
        wallets: [],
        coinType: 'BTC',
        selectedCurrency: { label: 'Bitcoin (BTC)', value: 'BTC', image: 'btc.png' },
        supportedCurrencies: [
            { label: 'Bitcoin (BTC)', value: 'BTC', image: 'btc.png' },
            { label: 'Dogecoin (DOGE)', value: 'DOGE', image: 'doge.png' },
            { label: 'Bitcoin cash (BCH)', value: 'BCH', image: 'bch.png' },
            { label: 'Litecoin (LTC)', value: 'LTC', image: 'ltc.png' }
        ],
        walletAddress: '',
        walletName:'',
        showModal: false,
        showToastError: false,
        errorMessage: ''
    };
  },
  destroyed() {
    clearTimeout(this.toastTimeout);
  },
  mounted() {
    this.getWallets()
  },
  directives: {
    clickOutside,
  },
  methods: {
    editWallet(wallet) {
        // Mostrar modal de edición con los datos de la billetera seleccionada
        //this.selectedWallet = { ...wallet };
      //  this.showEditModal = true;
    },
    closeToast() {
        this.showToastError = false;
    },
    closeToastSc() {
        this.showToast = false;
    },
    async deleteWallet(walletId) {
        if (confirm('Are you sure you want to delete this wallet?')) {
            try {
               const response =  await axios.post(`${process.env.VUE_APP_API_URL}deleteWallet`, {
                id: walletId,
            });
            if(response.data.success){
                this.showToast = true;
                this.message = response.data.message;
                this.toastTimeout = setTimeout(() => {
                    this.showToast = false;
                    this.message = '';
                }, 5000);
                this.getWallets();
            }
                //this.wallets = this.wallets.filter(wallet => wallet.id !== walletId);
                //this.$toast.success('Wallet deleted successfully!');
            } catch (error) {
                console.error(error);
                this.showToastError = true;
                this.errorMessage = error.response?.data?.message || "An error occurred.";
                this.toastTimeout = setTimeout(() => {
                    this.showToastError = false;
                    this.errorMessage = '';
                }, 5000);
            }
        }
    },
    validateWallet() {
      const isValid = WAValidator.validate(this.walletAddress, this.selectedCurrency.value);
      if (!isValid) {
        this.showToastError = true;
        this.errorMessage = `Invalid ${this.selectedCurrency.value} wallet address.`;
        this.toastTimeout = setTimeout(() => {
            this.showToastError = false;
            this.errorMessage = '';
        }, 5000);
        
        return false;
      }
      this.errorMessage = "";
      return true;
    },
    openModal() {
        this.showModal = true;
    },
    closeModal() {
        this.walletAddress = "";
        this.walletName = "";
        this.selectedCurrency = { label: 'Bitcoin (BTC)', value: 'BTC', image: 'btc.png' };
        this.errorMessage = "";
        this.isDropdownOpen = false;
        this.showModal = false;
    },
    async addWallet() {
      if (!this.validateWallet()) {
        return;
      }

      this.isSubmitting = true;

      try {
        const response = await axios.post(process.env.VUE_APP_API_URL+"addUserWallet", {
          wallet: this.walletAddress,
          wallet_name: this.walletName,
          coin_type: this.coinType,
        });

        this.showToast = true;
        this.message = response.data.message;
        this.toastTimeout = setTimeout(() => {
            this.showToast = false;
            this.message = '';
        }, 5000);

        //alert(response.data.message);
        this.closeModal();
        this.getWallets();
      } catch (error) {
        console.error(error);
        this.showToastError = true;
        this.errorMessage = error.response?.data?.message || "An error occurred.";
        this.toastTimeout = setTimeout(() => {
            this.showToastError = false;
            this.errorMessage = '';
        }, 5000);
      } finally {
        this.isSubmitting = false;
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getCurrencyImage(currency) {
        if (currency.value === 'BNB') {
            return require(`@/assets/images/currencies/${currency.image}`);
        } else {
            return require(`@/assets/images/currencies/${currency.image.toLowerCase()}`);
        }
    },
    changeCurrency2(currency) {
      this.selectedCurrency = currency;
      this.coinType = currency.value;
      this.isDropdownOpen = false;
    },
    hide() {
        this.isDropdownOpen = false;
    },
    getWallets(){
        this.isLoadingTable = true;
        axios.get(process.env.VUE_APP_API_URL+'getUserWallets')
        .then((res)=>{
            //console.log(res.data)
            this.wallets = res.data;
            this.isLoadingTable = false;
        })
        .catch((error)=>{
            console.log(error);
            this.isLoadingTable = false;
        })
    },
  },
};
</script>
<style scoped>


#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: rgb(255, 255, 255) !important;
    flex: 1 1 0%;
    border-radius: 4px;
}

@media only screen and (min-width : 769px) {
    .container {
        width:768px
    }
}

@media only screen and (min-width: 992px) {
    .container {
        width:992px
    }
}

@media only screen and (min-width: 1024px) {
    .container {
        width:1024px
    }
}

@media only screen and (min-width : 1200px) {
    .container {
        width:1200px
    }
}

@media only screen and (min-width : 1440px) {
    .container {
        width:1440px
    }
}

@media only screen and (max-width : 768px) {
    .content .panel {
        padding:24px
    }
}

@media only screen and (max-width : 991px) {
    .content {
        flex-basis:100%;
        max-width: calc(100vw - 32px)
    }
}

.empty-state .empty-icon[data-v-d723abed] {
    width: 85px;
    height: 85px;
    background: #23232329;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-state .empty-icon i[data-v-82182c58] {
    font-size: 40px;
    color: #002BAE;
}

.empty-state .empty-icon i[data-v-d723abed] {
    font-size: 40px;
    color: #002BAE;
}

.mined {
  background-color: #002BAE;
  color: white;
  border-radius: 4px;
}

.btn.primary:hover:not(.disabled) {
    background: linear-gradient(90deg,#19345D,#19345D);
    box-shadow: 0 24px 12px -19px #19345D8f
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#19345D,#19344D);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn.secondary {
    background: #fff;
    color: #3a3939;
    box-shadow: 0 1px 4px #23232329
}

.btn.secondary:hover:not(.disabled) {
    box-shadow: 0 4px 12px #23232329
}

.input-group.copy-on-hover .copy {
    display: none
}

.input-group.copy-on-hover:hover .copy {
    display: block
}

.input-group input:disabled[type=copy], .input-group input:read-only[type=copy] {
    cursor: default;
}

.input-group.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group input:disabled, .input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.input-group .copy {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.input-group.medium .copy {
    top: 16px;
    right: 16px;
}

.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #00ba94;
}

.copy-address {
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    width: 100%;
    background: #fff;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.relative {
    position: relative;
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1;
}

.input-group2 {
    position: relative;
}

.input-group2.large .input-group-prepend {
    width: 76px;
    line-height: 72px;
    height: 72px;
    color: #959595;
    font-size: 24px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px;
}

.input-group2.large input {
    height: 72px;
    font-size: 32px;
}

.input-group2.large .input-group-prepend span.smaller {
    font-size: 20px;
    position: relative;
    top: -2px
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px
}

.use-max {
    position: absolute;
    top: 25px;
    right: 40px;
}

.btn.outline.primary {
    color: rgb(25, 52, 93);
    box-shadow: inset 0 0 0 1px rgb(25, 52, 93)!important;
}

.input-group2 input:hover {
    box-shadow: 0 4px 12px #23232329
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2 input:-moz-read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 input:disabled,.input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.use-max button {
    width: 60px;
    margin-left: 8px;
    padding: 6px 0 !important;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.btn.outline.primary:hover,.btn.outline.primary:active {
    background: rgb(25, 52, 93);
    color: #fff;
    box-shadow: inset 0 0 0 1px rgb(25, 52, 93)!important
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

div:focus label:before {
    border-color: #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

div input+label:after {
    content: none
}

.field-wrap+.field-wrap {
    margin-top: 24px
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1
}

.field-wrap.dark label {
    color: #ffffff80
}

.btn.outline {
    background: transparent;
}

.text-secondary {
    color: #fba342 !important;
}

.fs16 {
  font-size: 16px;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}

.selectbox.deposit-to img,.selectbox.deposit-to .img,.selectbox.deposit-from img,.selectbox.deposit-from .img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 40px
}

.selectbox.deposit-to .field img,.selectbox.deposit-from .field img {
    position: relative;
    vertical-align: middle;
    top: -2px
}

.selectbox.deposit-to .field .name,.selectbox.deposit-from .field .name {
    position: absolute;
    top: 0px;
    left: 88px
}

div.selectbox.large .arrows {
    top: 28px;
}

div.selectbox.large .field {
    height: 72px;
    line-height: 72px
}

div.selectbox.large .input-group-prepend {
    line-height: 71px;
    height: 70px
}

div.selectbox {
    position: relative
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.theme-dark div.selectbox .field {
    background-color: #052339;
    border: 1px solid #40494f;
    color: #eee
}

div.selectbox .field.placeholder {
    color: #cecdcd
}

div.selectbox:focus {
    outline: none
}

div.selectbox:focus .field {
  border: 1px solid #15888a;
    box-shadow: 0 5px 10px #fba3421a
}

div.selectbox.open .field {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

div.selectbox .dropdown .options {
    overflow: hidden;
    overflow-y: auto;
    flex: 1
}

div.selectbox .dropdown .options .option {
    padding: 8px 16px;
    cursor: pointer
}

div.selectbox .dropdown .options .option:hover {
    background-color: #fba34240
}

div.selectbox .dropdown .options .option.selected {
    background-color: #fba342;
    color: #fff
}

div.selectbox .dropdown {
    position: absolute;
    background: #fff;
    z-index: 2;
    min-width: 100%;
    box-shadow: 0 1px 4px #23232329;
    max-height: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: max-height .2s;
    flex-direction: column;
    overflow: hidden
}

div.selectbox.open .dropdown {
    max-height: 350px
}

.selectbox.deposit-to img,.selectbox.deposit-to .img,.selectbox.deposit-from img,.selectbox.deposit-from .img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 40px
}

.selectbox.deposit-to .field img,.selectbox.deposit-from .field img {
    position: relative;
    vertical-align: middle;
    top: -2px
}

.selectbox.deposit-to .field .balance2,.selectbox.deposit-from .field .balance2 {
    position: absolute;
    top: 12px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option,.selectbox.deposit-from .option {
    position: relative
}

.selectbox.deposit-to .option img,.selectbox.deposit-from .option img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    position: relative;
    top: 2px
}

.selectbox.deposit-to .option .name,.selectbox.deposit-from .option .name {
    position: absolute;
    top: 11px;
    left: 88px
}

.selectbox.deposit-to .option .balance2,.selectbox.deposit-from .option .balance2 {
    position: absolute;
    top: 33px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option.selected .text-muted,.selectbox.deposit-from .option.selected .text-muted,.selectbox.deposit-to .option.selected .label-warning,.selectbox.deposit-from .option.selected .label-warning {
    color: #fff
}

.selectbox.deposit-to .option a .img,.selectbox.deposit-from .option a .img {
    border: 2px solid #FBA342;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    color: #fba342
}

.selectbox.deposit-to .option a .name,.selectbox.deposit-from .option a .name {
    color: #959595;
    top: 20px
}

.selectbox.deposit-to .option.selected a .name,.selectbox.deposit-from .option.selected a .name {
    color: #fff
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

div.selectbox .arrows i {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    left: 5px
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.selectbox.deposit-to img,.selectbox.deposit-to .img,.selectbox.deposit-from img,.selectbox.deposit-from .img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 40px
}

.selectbox.deposit-to .field img,.selectbox.deposit-from .field img {
    position: relative;
    vertical-align: middle;
    top: -2px
}

.selectbox.deposit-to .field .balance2,.selectbox.deposit-from .field .balance2 {
    position: absolute;
    top: 12px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option,.selectbox.deposit-from .option {
    position: relative
}

.selectbox.deposit-to .option img,.selectbox.deposit-from .option img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    position: relative;
    top: 2px
}

.selectbox.deposit-to .option .name,.selectbox.deposit-from .option .name {
    position: absolute;
    top: 11px;
    left: 88px
}

.selectbox.deposit-to .option .balance2,.selectbox.deposit-from .option .balance2 {
    position: absolute;
    top: 33px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option.selected .text-muted,.selectbox.deposit-from .option.selected .text-muted,.selectbox.deposit-to .option.selected .label-warning,.selectbox.deposit-from .option.selected .label-warning {
    color: #fff
}

.selectbox.deposit-to .option a .img,.selectbox.deposit-from .option a .img {
    border: 2px solid #FBA342;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    color: #fba342
}

.selectbox.deposit-to .option a .name,.selectbox.deposit-from .option a .name {
    color: #959595;
    top: 20px
}

.selectbox.deposit-to .option.selected a .name,.selectbox.deposit-from .option.selected a .name {
    color: #fff
}

div.selectbox .dropdown .options .option:hover {
    background-color: #15888a40
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #000000;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

div.selectbox .dropdown .options {
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
}

div.selectbox .dropdown .options .option.selected {
    background-color: #3883dd;
    color: #fff;
}

div.selectbox .dropdown .options .option:hover {
    background-color: #3883dd40
}

.arrows {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 15px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
}

div.selectbox .arrows i {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    top: 3px;
    left: 5px;
}

.flex--no-wrap {
    flex-wrap: nowrap;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.modal-bg {
    display: block;
    background: #3a3939cc;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 105;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in
}

.theme-dark .modal-bg {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: #010d15cc
}

.modal-bg.show {
    opacity: 1;
    visibility: visible
}

@keyframes slide-up {
    0% {
        opacity: 0;
        top: 100vh
    }

    to {
        opacity: 1;
        top: 0vh
    }
}

@keyframes slide-down {
    0% {
        opacity: 1;
        top: 0vh
    }

    to {
        opacity: 0;
        top: 100vh
    }
}

.modal-add {
    display: none;
    background: #fff;
    width: 400px;
    box-shadow: 0 32px 40px #23232329;
    z-index: 106;
    border-radius: 12px;
    text-align: left;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    margin-bottom: 24px
}

.theme-dark .modal-add {
    background: #031a2a;
    border: 1px solid #40494f
}

.modal-add.show {
    display: block
}

.modal-add.size0 {
    top: 160px;
    width: calc(100% - 16px);
    max-width: 560px
}

@media only screen and (max-width : 768px) {
    .modal-add.size0 {
        top:8px
    }
}

.modal-add.size0.solo {
    top: 48px
}

@media only screen and (max-width : 991px) {
    .modal-add.size0.solo {
        top:8px
    }
}

.modal-add.size0.merge {
    max-width: 880px
}

.modal-add.size0.shared {
    top: 48px;
    max-width: 697px
}

@media only screen and (max-width : 991px) {
    .modal-add.size0.shared {
        top:8px
    }
}

.modal-add.size1 {
    top: 48px;
    width: calc(100% - 16px);
    max-width: 610px
}

@media only screen and (max-width : 768px) {
    .modal-add.size1 {
        top:8px
    }
}

.modal-add.size1 .grecaptcha-badge {
    visibility: hidden
}

.modal-add.size2 {
    top: 24px;
    width: calc(100% - 16px);
    max-width: 1108px
}

@media only screen and (max-width : 768px) {
    .modal-add.size2 {
        top:8px
    }
}

.modal-add.size2 .grecaptcha-badge {
    visibility: hidden
}

.modal-add.medium {
    width: calc(100% - 16px);
    max-width: 640px
}

.modal-add.large {
    width: calc(100% - 16px);
    max-width: 1000px;
    top: 50px
}

@media only screen and (max-width : 768px) {
    .modal-add.large {
        top:8px
    }
}

.modal-add.hidden-overflow {
    overflow: hidden
}

.modal-add .spacer {
    height: 80px;
    margin: 0 0 -80px;
}

.modal-add .modal-content2 {
    padding: 24px 24px 32px !important;
    border-radius: 0 0 12px 12px !important;
}

.modal-add .modal-head .title {
    font-weight: 700;
    position: relative;
    top: 2px;
    font-size: 20px;
    text-align: left;
    line-height: 1.4;
    padding-right: 32px;
}

.modal-add .modal-head {
    border-bottom: 1px solid #EEE;
    padding: 16px 24px;
    border-radius: 12px 12px 0 0;
}

.modal-add .modal-head .close, .modal .modal-head .back {
    position: absolute;
    top: 20px;
    right: 24px;
    display: block;
    width: 24px;
    height: 24px;
    color: #3a3939;
    text-align: center;
    line-height: 24px;
    font-size: 20px;
}
</style>