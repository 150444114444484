<template>
  <div>
    <div class="section m-0" style="padding: 40px 0; background-color: #002BAE;">
        <!-- <img class="img-map position-absolute" src="@/assets/images/map-light.svg" alt="Image Map" style="opacity: 0.05; margin-top: 0px;"> -->

        

          <div class="toast-container start-50 translate-middle-x">
              <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                  <div class="toast-body">
                    <template>
                      <p>{{message}}</p>
                    </template>
                  </div>
                  <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
              </div>
          </div>

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-6">
        <div class="text-center mb-10">
            <img alt="Logo" class="mh-125px" src="@/assets/images/smartphone-2.svg">
        </div>
        
        <div class="text-center mb-10">
            <h1 class="text-white mb-3">
                {{$t('twoFactorTitle')}}
            </h1>
            <div class="text-white fw-semibold fs-6 mb-5">{{$t('twoFactorDescription')}}</div>
        </div>
        <div class="card bg-white shadow-lg border-0" >
                  <div class="card-body">
    <form class="form w-100 mb-13" @submit.prevent="verifyTwoFactorCode" novalidate="novalidate">
        
        <div class="mb-10">
            <div class="fw-bold text-start text-gray-900 fs-6 mb-1 ms-1">{{$t('sixDigitCode')}}</div>
            <div class="d-flex flex-wrap flex-stack">                      
                <input v-model="verificationCode[index]" autocomplete="off" type="text" v-for="(input, index) in 6" :key="index" :name="'code_' + (index + 1)" @keypress="restrictToNumbers" @keydown="navigateFields($event, index + 1)" maxlength="1" class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2" value="" inputmode="text">
            </div>                
        </div>
        <div class="d-flex flex-center">
            <button type="submit" class="btn w-100 text-white btn-primary rounded-3 py-3 fw-semibold text-uppercase mt-2" :disabled="isSubmitting">
                <span v-if="!isSubmitting">{{ $t('login') }}</span>
                <span v-else>
                    <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                    {{ $t('loggingIn') }}...
                </span>
            </button>
        </div>
        <div class="col-12 mt-3 d-grid gap-2 d-md-flex justify-content-md-end mt-3">
            <a href="javascript:void(0)" @click="onLogOut" class="text-decoration-none text-muted">{{ $t('cancelTwoFactorAuth') }}</a>
        </div>
    </form>    
</div>
</div>
    </div>
    </div>    
</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/i18n/index.js';
export default {
  name: 'CloudhashFrontend2FA',

  data() {
    return {
        verificationCode: Array(6).fill(''),
        isSubmitting: false,
        message: '',
        showToast: false,
        showError: false,
    };
  },

  mounted() {
    i18n.locale = this.locale;
    if (!this.$store.getters['user/requiresTwoFactorAuth']) {
      this.$router.push({ name: 'Login' }); // Redirige al login si no se necesita 2FA
    }
  },

  methods: {
    ...mapActions({
        logout: 'user/logout'
    }),
    ...mapActions({
      signIn: 'user/verifyTwoFactorCode'
    }),
    ...mapActions(['setLocale']),
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    },
    restrictToNumbers(event) {
      const keyCode = event.keyCode;
      if (!(keyCode >= 48 && keyCode <= 57) && // 0-9
          !(keyCode >= 96 && keyCode <= 105) && // NumPad 0-9
          keyCode !== 8 && keyCode !== 9 && keyCode !== 37 && keyCode !== 39) { // backspace, tab, left, right
        event.preventDefault();
      }
    },
    /* navigateFields(event, index) {
      if (event.keyCode === 8 && index > 1) { // backspace
        this.$refs[`code_${index - 1}`].focus();
      } else if (event.keyCode === 39 && index < 6) { // right arrow
        this.$refs[`code_${index + 1}`].focus();
      } else if (event.keyCode === 37 && index > 1) { // left arrow
        this.$refs[`code_${index - 1}`].focus();
      }
    }, */
    async verifyTwoFactorCode() {
      try {
        if (this.isSubmitting) return;

        this.isSubmitting = true; // Evitar múltiples envíos
        const code = this.verificationCode.join('');
        await this.signIn(code);
        this.$router.push({ name: 'Dashboard' }); // Redirigir al dashboard
      } catch (error) {
        const message = error.data?.error || 'Ocurrió un error inesperado.';
        this.message = message;
                this.showToast = true;
                this.showError = true;
                this.isSubmitting = false;
                setTimeout(()=>{
                this.showToast = false;
                this.showError = false;
                this.message = '';
                },5000);
        //this.errorMessage = error.message || 'Código inválido.';
      }
    },
    verifyTwoFactorCode2() {
        if (this.isSubmitting) return;

        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;
        // Código de verificación completo
        const code = this.verificationCode.join('');
        axios.post(process.env.VUE_APP_API_URL + 'twoFactorLogin', {
            code: code
        })
        .then(response => {
          // Limpia los campos después de enviar
          this.code = '';
          // Si la solicitud fue exitosa, puedes redirigir al usuario a su dashboard
          //this.$router.push({ name: "Dashboard" });
          this.$router.replace('/my/dashboard')
          .catch(error => {
            console.info(error.message)
          })
        })
        .catch((error)=>{
            if (error.response.status === 422) {
                this.message = error.response.data.error;
                this.showToast = true;
                this.showError = true;
                this.isSubmitting = false;
                setTimeout(()=>{
                this.showToast = false;
                this.showError = false;
                this.message = '';
                },5000);
            }
        })
    },
    onLogOut() {
        this.logout().then((res) => {
            this.$router.push({ name: "Login" });
        })
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    },
    navigateFields(event) {
        const currentInput = event.target;
        const nextInput = currentInput.nextElementSibling;

        if (currentInput.value.length === 1 && nextInput) {
            nextInput.focus();
        }
        if (currentInput.value.length === 0 && event.key === 'Backspace') {
            const prevInput = currentInput.previousElementSibling;
            if (prevInput) {
                prevInput.focus();
            }
        }
    }
  },
  computed: {
    ...mapGetters(['locale', 'supportedLanguages2']), // Obtener el idioma seleccionado y los idiomas admitidos desde Vuex
    selectedLanguage: {
      get() {
        // Devolver el idioma seleccionado del array de idiomas admitidos según el estado de Vuex
        return this.supportedLanguages.find(lang => lang.value === this.locale);
      },
      set(value) {
        // Podemos añadir lógica aquí si necesitamos actualizar selectedLanguage desde el componente
      }
    },
  },
  watch: {
    locale(newLocale) {
      this.selectedLanguage = this.supportedLanguages2.find(lang => lang.value === newLocale);
    }
  }
};
</script>
<style scoped>

.mb-10 {
    margin-bottom: 2.5rem!important;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-stack {
    justify-content: space-between;
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap!important;
}

.d-flex {
    display: flex!important;
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent!important;
}

.mb-13 {
    margin-bottom: 3.25rem!important;
}

.w-100 {
    width: 100%!important;
}

@media (min-width: 1200px) {
    .fs-2qx {
        font-size: 2.25rem!important;
    }
}

.w-60px {
    width: 60px!important;
}

.h-60px {
    height: 60px!important;
}

.fs-2qx {
    font-size: calc(1.35rem + 1.2vw)!important;
}
</style>