<template>
  <div id="wrapper">
    
    

    <div class="snowflakes" aria-hidden="true" v-if="snowActive">
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
</div>

    <div class="toast-container position-fixed top-50 start-50 translate-middle">
        <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-info': showMessage }" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="d-flex">
            <div class="toast-body">
              <template>
                <p>{{message}}</p>
              </template>
            </div>
            <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
    </div>

    <keep-alive v-if="!isSpecificRoute && !dynamicPrefixes">
      <BitcoinPrice key="bitcoin-price" />
    </keep-alive>
    <router-view></router-view>
  </div>
</template>
<script>
import BitcoinPrice from './components/BitcoinPrice.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
  components: { BitcoinPrice },
  data() {
    return {
      showToast: false,
      message: '',
      showMessage: false,
      snowActive: false
    };
  },
  computed: {
      isSpecificRoute() {
          const specificRoutes = [
            '/two-factor-auth', 
            '/my/dashboard', 
            '/my/balances', 
            '/my/settings/security', 
            '/my/settings/withdraw-addresses',
            '/my/settings/notification',
            '/my/settings/profile',
            '/my/upgrade', 
            '/my/partners',
            '/my/withdraw',
            '/my/notifications',
            '/my/marketplace',
            '/my/rewards',
            '/my/rewards/exchange',
            '/extra/mining',
            '/yoerlin/admin'
          ];
          return specificRoutes.includes(this.$route.path);
          //return this.$route.path === this.specificRoute;
      },
      dynamicPrefixes() {
        const dynamicPrefixes = [
          '/my/balances/', // Prefijo dinámico
          '/my/asics/'
        ];

        // Verificar coincidencia exacta o ruta dinámica
        return dynamicPrefixes.some(prefix => this.$route.path.startsWith(prefix));
      },
      ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
  },
  mounted() {
    //this.loadMode()
    //console.log(this.$route.path)
    this.getPageSettings()
    if (this.autenticado) {
      this.listenForDeposits();
    }
  },
  methods: {
    getPageSettings() {
      axios.get(process.env.VUE_APP_API_URL+'getSettings')
      .then((res)=>{
        //console.log(res.data);
        this.snowActive = res.data.value
      })
      .catch((error)=>{
        console.log(error);
      })
    },
    listenForDeposits() {
      window.Echo.private(`deposits.${this.user.user.id}`)
        .listen('DepositReceived', (data) => {
          this.notifyUser(data);
        });
    },
    closeToast() {
      this.showToast = false;
      this.showMessage = false;
    },
    notifyUser(data) {
      // Aquí puedes implementar la lógica para notificar al usuario,
      // como mostrar un mensaje en pantalla.
      this.showToast = true;
      this.showMessage = true;
      this.message = `Deposit received: ${data.amountUSD.toFixed(2)} USD`;
      setTimeout(()=>{
        this.showToast = false;
        this.showMessage = false;
        this.message = '';
      },5000);
      
    },
    normalNotify(data){
      this.showToast = true;
      this.showMessage = true;
      this.message = data;
      
      setTimeout(()=>{
        this.showToast = false;
        this.showMessage = false;
        this.message = '';
      },5000);
    }
  },
  created() {
    window.Echo.channel('test-channel')
    .listen('TestEvent', (e) => {
      this.normalNotify(e.message)
        //console.log(e.message); // Debería imprimir "Hello, WebSockets!"
    });
    // Escuchar los hooks del router
    this.$router.beforeEach((to, from, next) => {
      this.loading = true; // Mostrar el loader
      next(); // Continuar con la navegación
    });

    this.$router.afterEach(() => {
      // Retraso opcional para simular la carga
      setTimeout(() => {
        this.loading = false; // Ocultar el loader
      }, 1600); // Cambia este tiempo según lo necesario
    });
  },
}
</script>
<style>

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.stretched #wrapper {
    width: 100%;
    margin: 0;
    box-shadow: none;
}

main {
  flex: 1; /* Hace que el contenido principal ocupe todo el espacio disponible */
}

#wrapper {
  display: flex;
    min-height: 100vh;
    flex-direction: column
}

#wrapper.gray-bg {
    background: #f5f5f5;
}

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: 'Molle', cursive;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{
  0%{
    top:-10%
    }
    100%{
      top:100%
      }
  }
  @-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}



  .preloader {
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}
.preloader .animation-preloader {
  z-index: 1000;
}
.preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #384BFF;
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}
@media (max-width: 767px) {
  .preloader .animation-preloader .spinner {
    width: 7.5em;
    height: 7.5em;
    margin: 0 auto 1.5em auto;
  }
}
.preloader .animation-preloader .txt-loading {
  font: bold 5em "Jost", sans-serif, "Jost", sans-serif;
  text-align: center;
  user-select: none;
}
@media (max-width: 767px) {
  .preloader .animation-preloader .txt-loading {
    font-size: 2.5em;
  }
}
.preloader .animation-preloader .txt-loading .letters-loading {
  color: #384BFF;
  position: relative;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(9):before {
  animation-delay: 1.6s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(10):before {
  animation-delay: 1.8s;
}

.preloader .animation-preloader .txt-loading .letters-loading::before {
  animation: letters-loading 4s infinite;
  color: #0F0D1D;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  font-family: "Jost", sans-serif;
  position: absolute;
  top: -3px;
  transform: rotateY(-90deg);
}
.preloader p {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: #384BFF;
}
.preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}
.preloader .loader .row {
  height: 100%;
}
.preloader .loader .loader-section {
  padding: 0px;
}
.preloader .loader .loader-section .bg {
  background-color: #F3F7FB;
  height: 100%;
  left: 0;
  width: 100%;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}
.preloader.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
.preloader.loaded .loader-section .bg {
  width: 0;
  transition: 0.7s 0.3s cubic-bezier(0.1, 0.1, 0.1, 1);
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@-webkit-keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 90%;
  }
}
@keyframes load2 {
  0% {
    width: 0;
  }
  100% {
    width: 70%;
  }
}
@keyframes load3 {
  0% {
    width: 0;
  }
  100% {
    width: 55%;
  }
}

</style>
