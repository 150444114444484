<template>
  <div id="app_holder">
    <div id="content">
          <Sidebar/>

        <div class="container flex mb32 settings-panel">
          <SidebarSettings/> 


          <div class="toast-container position-fixed top-50 start-50 translate-middle">
                <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-success': showMessage }" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body">
                            <p>{{message}}</p>
                        </div>
                        <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
           </div>


          <div data-v-1d37ad5a="" class="content ml32 ml0-sm-down mt32-sm-down">
   <div data-v-1d37ad5a="" class="panel big-shadow">
      <div data-v-4d6be015="" data-v-1d37ad5a="">
         <div data-v-4d6be015="" class="mb32">
            <div data-v-4d6be015="" class="row">
               <div data-v-4d6be015="" class="col-sm-6">
                  <h1 data-v-4d6be015="" class="text-bold mb8">{{$t('settingsSection.profileTl')}}</h1>
                  <small data-v-4d6be015="" class="text-muted">{{$t('settingsSection.setupProfile')}}</small>
               </div>
               <div data-v-4d6be015="" class="col-sm-6 text-right">
                  <div data-v-4d6be015="" class="kyc-status">
                     <div data-v-4d6be015="" class="mb8">
                        <span data-v-4d6be015="" class="text-muted">{{$t('settingsSection.accountLevel')}}</span> 
                        <span data-v-4d6be015="" class="text-bold" :style="levelStyle">{{$t('settingsSection.level')}} {{ user?.user?.level }}</span>
                    </div>
                  </div>
               </div>
            </div>
         </div>
         <div data-v-4d6be015="" class="mb32">
            <h3 data-v-4d6be015="" class="mb8 text-light">{{$t('settingsSection.publicInfo')}}</h3>
            <hr data-v-4d6be015="" class="mb24">
            <div data-v-4d6be015="" class="row">
                <div data-v-4d6be015="" class="col-sm-6">
                  <div data-v-4d6be015="" class="avatar-holder">
                     <div data-v-e9688c3f="" data-v-4d6be015="" class="avatar"
                     :style="avatarStyle">
                     <span v-if="!user?.user?.avatar_url">{{ avatarInitial }}</span>
                    </div>
                  </div>
                  <div data-v-4d6be015="">
                     <span data-v-4d6be015="" class="block text-bold mb8">{{$t('settingsSection.publicPicture')}}</span>
                     <div data-v-45b1ddc6="" data-v-4d6be015="" class="field-wrap input-undefined">
                        <div data-v-45b1ddc6="" class="label-row">
                           <label data-v-45b1ddc6="">
                            {{$t('settingsSection.maxSize')}}
                           </label>
                        </div>
                        <button data-v-4d6be015="" 
                        @click="triggerFileInput"
                        class="btn primary normal outline">{{$t('settingsSection.changePhoto')}}</button>
                        <div data-v-4d6be015="" style="display: none;">
                            <input data-v-4d6be015="" 
                            ref="fileInput"
                            @change="onFileChange"
                            type="file" name="avatarImg" accept=".gif,.jpg,.jpeg,.png">
                        </div>
                     </div>
                  </div>
               </div>
               <div data-v-4d6be015="" class="col-sm-6 mt24-xs-down">
                  <span data-v-4d6be015="" class="block text-bold mb8">{{$t('settingsSection.publicNickname')}}</span>
                  <div data-v-45b1ddc6="" data-v-4d6be015="" class="field-wrap input-null">
                     <div data-v-45b1ddc6="" class="label-row">
                        <label data-v-45b1ddc6="">
                           {{$t('settingsSection.publicNickname')}}
                        </label>
                     </div>
                     <div data-v-f5470495="" data-v-4d6be015="" class="input-group">
                        <input data-v-f5470495="" type="text" class="form-control" placeholder="Public Nickname" maxlength="" :value="user?.user?.username" disabled>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div data-v-4d6be015="">
            <h3 data-v-4d6be015="" class="mb8 text-light"><i data-v-4d6be015="" class="fa fa-lock"></i>{{$t('settingsSection.privateInfo')}}</h3>
            <span data-v-4d6be015="" class="text-muted block mb32">{{$t('settingsSection.privateInfoSub')}}</span>
            <div data-v-4d6be015="" class="row mb24">
               <div data-v-4d6be015="" class="col-sm-6">
                  <div data-v-45b1ddc6="" data-v-4d6be015="" class="field-wrap input-undefined">
                     <div data-v-45b1ddc6="" class="label-row">
                        <label data-v-45b1ddc6="">
                            {{$t('settingsSection.mail')}}
                        </label>
                     </div>
                     <div data-v-f5470495="" data-v-4d6be015="" class="input-group">
                        <input data-v-f5470495="" type="text" name="email" class="form-control" placeholder="Email Address" maxlength="" :value="user?.user?.email" disabled>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>



        </div>

      </div><!-- content -->
    <FooterDash/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue';
import SidebarSettings from './SidebarSettings.vue';
import axios from 'axios'
export default {
  name: 'CloudhashxFrontendProfile',
  components: { FooterDash, SidebarSettings, Sidebar },
  data() {
    return {
        selectedFile: null, // Archivo seleccionado
        avatarBackgroundColor: "rgb(0, 43, 174)",
        showMessage: false,
        message: '',
        showToast: false,
        toastTimeout: null
        
    };
  },
  destroyed() {
    clearTimeout(this.toastTimeout);
  },
  computed: {
    levelStyle() {
      const levelColors = {
        0: "#dc3545", // Gris para nivel 0
        1: "#007bff", // Azul para nivel 1
        2: "#6E52FF", // Verde para nivel 2
        3: "#ffc107", // Amarillo para nivel 3
        4: "#fd7e14", // Naranja para nivel 4
        5: "#28a745", // Rojo para nivel 5
      };
      return { color: levelColors[this.user?.user?.level || 0] };
    },
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
      avatarInitial() {
        return this.getFirstLetter(this.user?.user?.email); // Ajusta si la estructura es diferente
      },
      avatarStyle() {
      // Estilo dinámico para el avatar
      return {
        color: "white",
        fontSize: "24px",
        lineHeight: "64px",
        width: "64px",
        height: "64px",
        borderRadius: "64px",
        backgroundColor: this.user?.user?.avatar_url ? "transparent" : this.avatarBackgroundColor,
        backgroundImage: this.user?.user?.avatar_url ? `url(${this.user?.user?.avatar_url})` : "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    }
  },
  mounted() {
    
  },

  methods: {
    getFirstLetter(email) {
      if (!email || typeof email !== 'string') {
        return '';
      }
      const firstLetter = email.trim()[0];
      return firstLetter ? firstLetter.toUpperCase() : '';
    },
    triggerFileInput() {
      this.$refs.fileInput.click(); // Dispara el input oculto
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file && this.validateFile(file)) {
        this.selectedFile = file;
        this.previewAvatar(file); // Previsualiza el avatar
        this.uploadAvatar(file); // Sube el avatar
      } else {
        alert("Invalid file. Please upload an image smaller than 2MB.");
      }
    },
    validateFile(file) {
      const allowedTypes = ["image/gif", "image/jpeg", "image/png"];
      return file.size <= 2 * 1024 * 1024 && allowedTypes.includes(file.type); // Máx. 2MB y tipos permitidos
    },
    previewAvatar(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.avatarUrl = e.target.result; // Genera la URL de previsualización
      };
      reader.readAsDataURL(file);
    },
    async uploadAvatar(file) {
      const formData = new FormData();
      formData.append("avatar", file);

      try {
        const response = await axios.post(process.env.VUE_APP_API_URL+"uploadAvatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.showToast = true;
        this.showMessage = true;
        this.message = response.data.message;
        this.toastTimeout = setTimeout(() => {
            this.showToast = false;
            this.showMessage = false;
            this.message = '';
        }, 5000);
        this.$store.dispatch('user/intento');
      } catch (error) {
        console.error("Error uploading avatar:", error);
        alert("Error uploading avatar. Please try again.");
      }
    },
    closeToast() {
      this.showToast = false;
      this.showMessage = false;
    },
  },
};
</script>
<style scoped>
i[data-v-4d6be015] {
    margin-right: 8px
}

span[data-v-4d6be015] {
    font-size: 12px
}

.kyc-status[data-v-4d6be015] {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #EEE;
    padding: 16px 24px;
    text-align: left
}

.theme-dark .kyc-status[data-v-4d6be015] {
    border: 1px solid #40494f
}

@media only screen and (max-width : 768px) {
    .kyc-status[data-v-4d6be015] {
        margin-top:16px;
        width: 100%
    }
}

.block[data-v-4d6be015] {
    display: block
}

.avatar-holder[data-v-4d6be015] {
    float: left;
    margin-right: 16px
}

[data-v-4d6be015] .country-codes .options .option {
    text-align: right
}

.country-codes[data-v-4d6be015] {
    min-width: 80px
}

.tiers td[data-v-4d6be015] {
    padding: 4px 0
}


.avatar[data-v-e9688c3f] {
    text-align: center;
    background-size: cover;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    position: relative;
}

.avatar-holder[data-v-4d6be015] {
    float: left;
    margin-right: 16px;
}

.field-wrap .label-row label[data-v-45b1ddc6] {
    color: #959595;
    display: block;
    flex: 1;
}

.field-wrap .label-row[data-v-45b1ddc6] {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.input-group[data-v-f5470495] {
    position: relative;
}

.input-group input[data-v-f5470495] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 9px 16px 11px;
    margin: 0;
}

.form-control {
    width: 100%;
}

.input-group input[data-v-f5470495]:disabled {
    cursor: not-allowed;
    opacity: .5;
}

.input-group input[data-v-f5470495]:disabled, .input-group input[data-v-f5470495]:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.text-light {
    font-weight: 300;
    color: black !important;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#002BAE,#002BAE);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

#app_holder.gray-bg {
    background: #f5f5f5;
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 4px;
}
</style>