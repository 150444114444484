import Vue from 'vue';
import App from './App.vue';
import VueGtag from "vue-gtag";
import VueMq from 'vue-mq';
import router from './router';
import { store } from './store/index';
import './plugins/axios';
import i18n from './i18n';
import FloatingVue from 'floating-vue';
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

Vue.use(LottieVuePlayer);
Vue.use(FloatingVue);
Vue.use(VueGtag, {
  config: { id: "G-J3QE04WLV4" }
});

/* Vue.use(VueMq, {
  breakpoints: {
    // Define tus puntos de quiebre aquí
    mobile: 600,
    tablet: 1024,
    desktop: Infinity,
  },
}); */
Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  }
})

require('./store/subscriber');
import './assets/main.css';
import 'floating-vue/dist/style.css';

Vue.config.productionTip = false

const jwtToken = localStorage.getItem('token');

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authEndpoint: 'https://api.cloudhashx.com/broadcasting/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + jwtToken
    },
  },
});



let app = '';
store.dispatch('user/intento', localStorage.getItem('token')).then(() => {
  if (!app) {
    app = new Vue({
      router,
      i18n,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});