<template>
  <div id="app_holder">
    
    <div id="content">
        <Sidebar/>

        <div class="container flex mb32 settings-panel">
          <SidebarRewards/>

          <div data-v-63b81b20="" class="content ml32 ml0-sm-down mt32-sm-down">
            <div class="panel big-shadow">
                    <div data-v-4d6be015="" class="mb32">
                  <div data-v-4d6be015="" class="row">
                    <div data-v-4d6be015="" class="col-sm-6">
                        <h1 data-v-4d6be015="" class="text-bold mb8">{{$t('rewardsPage.menuTl')}}</h1>
                        <small data-v-4d6be015="" class="text-muted">{{$t('rewardsPage.subtitleRewards')}}</small>
                    </div>
                    <div data-v-4d6be015="" class="col-sm-6 text-right">
                        <div data-v-4d6be015="" class="kyc-status">
                          <div data-v-4d6be015="" class="mb8">
                              <span data-v-4d6be015="" class="text-muted">{{$t('rewardsPage.rewardsPoints')}}</span> 
                              <span data-v-4d6be015="" class="text-bold"> {{user?.user?.reward_points}} </span>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>


              <div data-v-257941bc="">
            <h3 data-v-257941bc="" class="text-light mt32">{{$t('rewardsPage.history')}}</h3>
            <hr data-v-257941bc="" class="mb8">
            <div data-v-257941bc="" class="mb24"><small data-v-257941bc="" class="text-muted">{{$t('rewardsPage.recent')}}</small></div>
            <div data-v-d723abed="" data-v-257941bc="" class="table-wrap relative table">
               
               <div data-v-d723abed="" class="table-container">
                  <table data-v-d723abed="" cellspacing="0" cellpadding="0" class="nh padding-normal">
                     <thead data-v-d723abed="">
                        <tr data-v-d723abed="">
                           <th data-v-d723abed="" class="">
                            {{$t('settingsSection.tbWhen')}}
                           </th>
                           <th data-v-d723abed="" class="">
                            {{$t('rewardsPage.pointsTb')}}
                           </th>
                           <th data-v-d723abed="" class="">
                              {{$t('settingsSection.tbAction')}} 
                           </th>
                        </tr>
                     </thead>
                     <tbody data-v-d723abed="">
                        <tr data-v-d723abed="" class="" v-for="(reward, index) in rewards" :key="index">
                           <td data-v-d723abed="" class=""><span data-v-518562c9="" data-v-257941bc="" class="text-primary">{{formatDate(reward.created_at)}} </span></td>
                           <td data-v-d723abed="" class="text-wrap">{{reward.points}}</td>
                           <td data-v-d723abed="" class="text-wrap">{{reward.action}}</td>
                        </tr>
                     </tbody>
                  </table>
                  

                  <div class="overlay loader" v-if="isLoadingTable">
                    <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                  </div>
                  
               </div>
               
            </div>
         </div>



            </div>
          </div>

        </div>

    </div><!-- content -->
    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue'
import SidebarRewards from './SidebarRewards.vue'
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'DocumentsRewards',
  components: {
    Sidebar, FooterDash, SidebarRewards
  },
  data() {
    return {
      rewards: [],
      isLoadingTable: false,
    };
  },
  computed: {
    ...mapGetters({
          user: 'user/usuario'
      }),
  },
  mounted() {
    this.getRewards()
  },

  methods: {
    formatDate(date){
      return moment.utc(date).local().fromNow();
      // moment(date).fromNow();
    },
    getRewards() {
      this.isLoadingTable = true;
        axios.get(process.env.VUE_APP_API_URL+'getUserRewards')
        .then((res)=>{
          //console.log(res.data);
            this.rewards = res.data;
            this.isLoadingTable = false;
        })  
        .catch((error)=>{
            console.log('error getting rewards',error);
            this.isLoadingTable = false;
        })
    }
  },
};
</script>
<style scoped>
h1 {
    font-size: 24px;
    font-weight: 700;
}

.mb8 {
    margin-bottom: 8px;
}

.text-muted {
    color: #959595;
}

small {
    font-size: 12px;
    line-height: 1.42;
}

.mb24 {
    margin-bottom: 24px;
}

hr {
    border: 0;
    border-bottom: 1px solid #EEE;
}

h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.mb16 {
    margin-bottom: 16px;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#002BAE,#002BAE);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mt32 {
    margin-top: 20px;
}

.btn.primary:hover:not(.disabled) {
    background: linear-gradient(90deg,#19345D,#19345D);
    box-shadow: 0 24px 12px -19px #19345D8f
}

.modal-pw .spacer {
    height: 80px;
    margin: 0 0 -80px;
}

.content2 {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.content2 .section {
    padding: 0px 24px;
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1;
}

.input-group input[type=password] {
    padding-right: 82px
}

.input-group input::placeholder {
    color: #cecdcd;
    font-size: 14px
}

.field-wrap+.field-wrap {
    margin-top: 24px;
}

.mt24 {
    margin-top: 20px;
}

.content[data-v-e6989c53] {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.toast-body ul {
    list-style-type: none; /* Quita el marcador de lista */
    padding: 0; /* Elimina el relleno predeterminado de la lista */
}

.content2 .section {
    padding: 0 24px;
}

a {
    color: #128ede !important;
    text-decoration: none;
    transition: .2s ease color;
    cursor: pointer;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.content2 .store {
    width: 160px;
}

.modal-dialog-custom {
    position: relative;
    width: 400px;
    margin: var(--bs-modal-margin);
    pointer-events: none;
}

@media (min-width: 576px){
.modal-dialog-custom {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
}
}

.content {
    background: transparent;
    flex: 1 1 0%;
    border-radius: 4px;
}

.section {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--nbhs-section-bg);
    overflow: hidden;
}

.content2 {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.flex__fill-gap {
    flex: 1;
}

.modal-pw p {
    margin: 16px 0 24px;
    color: #959595;
    line-height: 1.43;
    font-size: 14px;
}

.input-group.auth .input-group-prepend~input,.input-group.medium .input-group-prepend~input {
    padding-left: 56px
}

.input-group.auth .input-group-prepend,.input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px
}

.input-group input:focus-within + .input-group-prepend {
    color: #cecdcd; /* Cambia el color cuando el input está en foco */
}

.input-group .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group .input-group-prepend.active {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group-prepend.active {
  color: #cecdcd; /* Cambia el color del icono cuando está activo */
}

.input-group.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.client-info {
    margin: 0 0 16px;
    background: #eee;
    line-height: 18px;
    padding: 8px 0;
}

.fs12 {
    font-size: 12px;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: #959595;
}

.mb8 {
    margin-bottom: 8px;
}

@media only screen and (min-width: 321px) {
  .col-micro-6 {
      max-width: 50%;
      flex-basis: 50%;
  }
}

.mr4 {
    margin-right: 4px;
}

.text-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}



/* @media only screen and (min-width: 481px) {
  .col-xs-12 {
      max-width: 100%;
      flex-basis: 100%;
  }
} */

.input-group.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.mnemonic-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mnemonic-buttons button {
  margin-bottom: 10px;
}

.btn-outline-primary {
  text-transform: none;
}

.input-group2 input:hover {
    box-shadow: 0 4px 12px #23232329
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2 input:-moz-read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 input:disabled,.input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend~input {
    padding-left: 56px
}

@media (min-width: 576px) {
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
}

.text-light {
    font-weight: 300;
    color: black !important;

}

@media only screen and (max-width : 991px) {
    .content[data-v-63b81b20] {
        flex-basis:100%;
        max-width: calc(100vw - 32px)
    }
}

@media only screen and (max-width : 768px) {
    .content .panel[data-v-63b81b20] {
        padding:24px
    }
}

@media only screen and (max-width : 991px) {
    .pt0-sm-down {
        padding-top:0
    }

    .pt2-sm-down {
        padding-top: 2px
    }

    .pt4-sm-down {
        padding-top: 4px
    }

    .pt6-sm-down {
        padding-top: 6px
    }

    .pt8-sm-down {
        padding-top: 8px
    }

    .pt10-sm-down {
        padding-top: 10px
    }

    .pt12-sm-down {
        padding-top: 12px
    }

    .pt14-sm-down {
        padding-top: 14px
    }

    .pt16-sm-down {
        padding-top: 16px
    }

    .pt24-sm-down {
        padding-top: 24px
    }

    .pt32-sm-down {
        padding-top: 32px
    }

    .pt40-sm-down {
        padding-top: 40px
    }

    .pt48-sm-down {
        padding-top: 48px
    }

    .pt56-sm-down {
        padding-top: 56px
    }

    .pt64-sm-down {
        padding-top: 64px
    }

    .pt72-sm-down {
        padding-top: 72px
    }

    .pt80-sm-down {
        padding-top: 80px
    }

    .pt88-sm-down {
        padding-top: 88px
    }

    .pt96-sm-down {
        padding-top: 96px
    }

    .pt104-sm-down {
        padding-top: 104px
    }

    .pt112-sm-down {
        padding-top: 112px
    }

    .pt120-sm-down {
        padding-top: 120px
    }

    .pt128-sm-down {
        padding-top: 128px
    }

    .pt136-sm-down {
        padding-top: 136px
    }

    .pt144-sm-down {
        padding-top: 144px
    }

    .pt152-sm-down {
        padding-top: 152px
    }

    .pt160-sm-down {
        padding-top: 160px
    }

    .pb0-sm-down {
        padding-bottom: 0
    }

    .pb2-sm-down {
        padding-bottom: 2px
    }

    .pb4-sm-down {
        padding-bottom: 4px
    }

    .pb6-sm-down {
        padding-bottom: 6px
    }

    .pb8-sm-down {
        padding-bottom: 8px
    }

    .pb10-sm-down {
        padding-bottom: 10px
    }

    .pb12-sm-down {
        padding-bottom: 12px
    }

    .pb14-sm-down {
        padding-bottom: 14px
    }

    .pb16-sm-down {
        padding-bottom: 16px
    }

    .pb24-sm-down {
        padding-bottom: 24px
    }

    .pb32-sm-down {
        padding-bottom: 32px
    }

    .pb40-sm-down {
        padding-bottom: 40px
    }

    .pb48-sm-down {
        padding-bottom: 48px
    }

    .pb56-sm-down {
        padding-bottom: 56px
    }

    .pb64-sm-down {
        padding-bottom: 64px
    }

    .pb72-sm-down {
        padding-bottom: 72px
    }

    .pb80-sm-down {
        padding-bottom: 80px
    }

    .pb88-sm-down {
        padding-bottom: 88px
    }

    .pb96-sm-down {
        padding-bottom: 96px
    }

    .pb104-sm-down {
        padding-bottom: 104px
    }

    .pb112-sm-down {
        padding-bottom: 112px
    }

    .pb120-sm-down {
        padding-bottom: 120px
    }

    .pb128-sm-down {
        padding-bottom: 128px
    }

    .pb136-sm-down {
        padding-bottom: 136px
    }

    .pb144-sm-down {
        padding-bottom: 144px
    }

    .pb152-sm-down {
        padding-bottom: 152px
    }

    .pb160-sm-down {
        padding-bottom: 160px
    }

    .pr0-sm-down {
        padding-right: 0
    }

    .pr2-sm-down {
        padding-right: 2px
    }

    .pr4-sm-down {
        padding-right: 4px
    }

    .pr6-sm-down {
        padding-right: 6px
    }

    .pr8-sm-down {
        padding-right: 8px
    }

    .pr10-sm-down {
        padding-right: 10px
    }

    .pr12-sm-down {
        padding-right: 12px
    }

    .pr14-sm-down {
        padding-right: 14px
    }

    .pr16-sm-down {
        padding-right: 16px
    }

    .pr24-sm-down {
        padding-right: 24px
    }

    .pr32-sm-down {
        padding-right: 32px
    }

    .pr40-sm-down {
        padding-right: 40px
    }

    .pr48-sm-down {
        padding-right: 48px
    }

    .pr56-sm-down {
        padding-right: 56px
    }

    .pr64-sm-down {
        padding-right: 64px
    }

    .pr72-sm-down {
        padding-right: 72px
    }

    .pr80-sm-down {
        padding-right: 80px
    }

    .pr88-sm-down {
        padding-right: 88px
    }

    .pr96-sm-down {
        padding-right: 96px
    }

    .pr104-sm-down {
        padding-right: 104px
    }

    .pr112-sm-down {
        padding-right: 112px
    }

    .pr120-sm-down {
        padding-right: 120px
    }

    .pr128-sm-down {
        padding-right: 128px
    }

    .pr136-sm-down {
        padding-right: 136px
    }

    .pr144-sm-down {
        padding-right: 144px
    }

    .pr152-sm-down {
        padding-right: 152px
    }

    .pr160-sm-down {
        padding-right: 160px
    }

    .pl0-sm-down {
        padding-left: 0
    }

    .pl2-sm-down {
        padding-left: 2px
    }

    .pl4-sm-down {
        padding-left: 4px
    }

    .pl6-sm-down {
        padding-left: 6px
    }

    .pl8-sm-down {
        padding-left: 8px
    }

    .pl10-sm-down {
        padding-left: 10px
    }

    .pl12-sm-down {
        padding-left: 12px
    }

    .pl14-sm-down {
        padding-left: 14px
    }

    .pl16-sm-down {
        padding-left: 16px
    }

    .pl24-sm-down {
        padding-left: 24px
    }

    .pl32-sm-down {
        padding-left: 32px
    }

    .pl40-sm-down {
        padding-left: 40px
    }

    .pl48-sm-down {
        padding-left: 48px
    }

    .pl56-sm-down {
        padding-left: 56px
    }

    .pl64-sm-down {
        padding-left: 64px
    }

    .pl72-sm-down {
        padding-left: 72px
    }

    .pl80-sm-down {
        padding-left: 80px
    }

    .pl88-sm-down {
        padding-left: 88px
    }

    .pl96-sm-down {
        padding-left: 96px
    }

    .pl104-sm-down {
        padding-left: 104px
    }

    .pl112-sm-down {
        padding-left: 112px
    }

    .pl120-sm-down {
        padding-left: 120px
    }

    .pl128-sm-down {
        padding-left: 128px
    }

    .pl136-sm-down {
        padding-left: 136px
    }

    .pl144-sm-down {
        padding-left: 144px
    }

    .pl152-sm-down {
        padding-left: 152px
    }

    .pl160-sm-down {
        padding-left: 160px
    }

    .mt0-sm-down {
        margin-top: 0
    }

    .mt2-sm-down {
        margin-top: 2px
    }

    .mt4-sm-down {
        margin-top: 4px
    }

    .mt6-sm-down {
        margin-top: 6px
    }

    .mt8-sm-down {
        margin-top: 8px
    }

    .mt10-sm-down {
        margin-top: 10px
    }

    .mt12-sm-down {
        margin-top: 12px
    }

    .mt14-sm-down {
        margin-top: 14px
    }

    .mt16-sm-down {
        margin-top: 16px
    }

    .mt24-sm-down {
        margin-top: 24px
    }

    .mt32-sm-down {
        margin-top: 32px
    }

    .mt40-sm-down {
        margin-top: 40px
    }

    .mt48-sm-down {
        margin-top: 48px
    }

    .mt56-sm-down {
        margin-top: 56px
    }

    .mt64-sm-down {
        margin-top: 64px
    }

    .mt72-sm-down {
        margin-top: 72px
    }

    .mt80-sm-down {
        margin-top: 80px
    }

    .mt88-sm-down {
        margin-top: 88px
    }

    .mt96-sm-down {
        margin-top: 96px
    }

    .mt104-sm-down {
        margin-top: 104px
    }

    .mt112-sm-down {
        margin-top: 112px
    }

    .mt120-sm-down {
        margin-top: 120px
    }

    .mt128-sm-down {
        margin-top: 128px
    }

    .mt136-sm-down {
        margin-top: 136px
    }

    .mt144-sm-down {
        margin-top: 144px
    }

    .mt152-sm-down {
        margin-top: 152px
    }

    .mt160-sm-down {
        margin-top: 160px
    }

    .mb0-sm-down {
        margin-bottom: 0
    }

    .mb2-sm-down {
        margin-bottom: 2px
    }

    .mb4-sm-down {
        margin-bottom: 4px
    }

    .mb6-sm-down {
        margin-bottom: 6px
    }

    .mb8-sm-down {
        margin-bottom: 8px
    }

    .mb10-sm-down {
        margin-bottom: 10px
    }

    .mb12-sm-down {
        margin-bottom: 12px
    }

    .mb14-sm-down {
        margin-bottom: 14px
    }

    .mb16-sm-down {
        margin-bottom: 16px
    }

    .mb24-sm-down {
        margin-bottom: 24px
    }

    .mb32-sm-down {
        margin-bottom: 32px
    }

    .mb40-sm-down {
        margin-bottom: 40px
    }

    .mb48-sm-down {
        margin-bottom: 48px
    }

    .mb56-sm-down {
        margin-bottom: 56px
    }

    .mb64-sm-down {
        margin-bottom: 64px
    }

    .mb72-sm-down {
        margin-bottom: 72px
    }

    .mb80-sm-down {
        margin-bottom: 80px
    }

    .mb88-sm-down {
        margin-bottom: 88px
    }

    .mb96-sm-down {
        margin-bottom: 96px
    }

    .mb104-sm-down {
        margin-bottom: 104px
    }

    .mb112-sm-down {
        margin-bottom: 112px
    }

    .mb120-sm-down {
        margin-bottom: 120px
    }

    .mb128-sm-down {
        margin-bottom: 128px
    }

    .mb136-sm-down {
        margin-bottom: 136px
    }

    .mb144-sm-down {
        margin-bottom: 144px
    }

    .mb152-sm-down {
        margin-bottom: 152px
    }

    .mb160-sm-down {
        margin-bottom: 160px
    }

    .mr0-sm-down {
        margin-right: 0
    }

    .mr2-sm-down {
        margin-right: 2px
    }

    .mr4-sm-down {
        margin-right: 4px
    }

    .mr6-sm-down {
        margin-right: 6px
    }

    .mr8-sm-down {
        margin-right: 8px
    }

    .mr10-sm-down {
        margin-right: 10px
    }

    .mr12-sm-down {
        margin-right: 12px
    }

    .mr14-sm-down {
        margin-right: 14px
    }

    .mr16-sm-down {
        margin-right: 16px
    }

    .mr24-sm-down {
        margin-right: 24px
    }

    .mr32-sm-down {
        margin-right: 32px
    }

    .mr40-sm-down {
        margin-right: 40px
    }

    .mr48-sm-down {
        margin-right: 48px
    }

    .mr56-sm-down {
        margin-right: 56px
    }

    .mr64-sm-down {
        margin-right: 64px
    }

    .mr72-sm-down {
        margin-right: 72px
    }

    .mr80-sm-down {
        margin-right: 80px
    }

    .mr88-sm-down {
        margin-right: 88px
    }

    .mr96-sm-down {
        margin-right: 96px
    }

    .mr104-sm-down {
        margin-right: 104px
    }

    .mr112-sm-down {
        margin-right: 112px
    }

    .mr120-sm-down {
        margin-right: 120px
    }

    .mr128-sm-down {
        margin-right: 128px
    }

    .mr136-sm-down {
        margin-right: 136px
    }

    .mr144-sm-down {
        margin-right: 144px
    }

    .mr152-sm-down {
        margin-right: 152px
    }

    .mr160-sm-down {
        margin-right: 160px
    }

    .ml0-sm-down {
        margin-left: 0
    }

    .ml2-sm-down {
        margin-left: 2px
    }

    .ml4-sm-down {
        margin-left: 4px
    }

    .ml6-sm-down {
        margin-left: 6px
    }

    .ml8-sm-down {
        margin-left: 8px
    }

    .ml10-sm-down {
        margin-left: 10px
    }

    .ml12-sm-down {
        margin-left: 12px
    }

    .ml14-sm-down {
        margin-left: 14px
    }

    .ml16-sm-down {
        margin-left: 16px
    }

    .ml24-sm-down {
        margin-left: 24px
    }

    .ml32-sm-down {
        margin-left: 32px
    }

    .ml40-sm-down {
        margin-left: 40px
    }

    .ml48-sm-down {
        margin-left: 48px
    }

    .ml56-sm-down {
        margin-left: 56px
    }

    .ml64-sm-down {
        margin-left: 64px
    }

    .ml72-sm-down {
        margin-left: 72px
    }

    .ml80-sm-down {
        margin-left: 80px
    }

    .ml88-sm-down {
        margin-left: 88px
    }

    .ml96-sm-down {
        margin-left: 96px
    }

    .ml104-sm-down {
        margin-left: 104px
    }

    .ml112-sm-down {
        margin-left: 112px
    }

    .ml120-sm-down {
        margin-left: 120px
    }

    .ml128-sm-down {
        margin-left: 128px
    }

    .ml136-sm-down {
        margin-left: 136px
    }

    .ml144-sm-down {
        margin-left: 144px
    }

    .ml152-sm-down {
        margin-left: 152px
    }

    .ml160-sm-down {
        margin-left: 160px
    }
}

.modal .modal-head .title {
    font-weight: 700;
    position: relative;
    top: 2px;
    font-size: 20px;
    text-align: left;
    line-height: 1.4;
    padding-right: 32px;
}

.content .section {
    padding: 0 24px;
}

.btn.outline.danger {
    background: transparent;
    color: #e92424;
    box-shadow: inset 0 0 0 1px #e92424!important;
}

.btn.outline.danger:active,.btn.outline.danger:hover:not(.disabled) {
    box-shadow: inset 0 0 0 1px #e92424!important
}

.btn.danger:hover:not(.disabled) {
    box-shadow: 0 18px 12px -14px #e9242466!important;
    background: #e92424;
    color: #fff
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 4px;
}


.modal-pw {
    display: none;
    background: #fff;
    width: 400px;
    box-shadow: 0 32px 40px #23232329;
    z-index: 106;
    border-radius: 12px;
    text-align: left;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    margin-bottom: 24px
}

.theme-dark .modal-pw {
    background: #031a2a;
    border: 1px solid #40494f
}

.modal-pw.show {
    display: block
}

.modal-pw.size0 {
    top: 160px;
    width: calc(100% - 16px);
    max-width: 560px
}

@media only screen and (max-width : 768px) {
    .modal-pw.size0 {
        top:8px
    }
}

.modal-pw.size0.solo {
    top: 48px
}

@media only screen and (max-width : 991px) {
    .modal-pw.size0.solo {
        top:8px
    }
}

.modal-pw.size0.merge {
    max-width: 880px
}

.modal-pw.size0.shared {
    top: 48px;
    max-width: 697px
}

@media only screen and (max-width : 991px) {
    .modal-pw.size0.shared {
        top:8px
    }
}

.modal-pw.size1 {
    top: 48px;
    width: calc(100% - 16px);
    max-width: 610px
}

@media only screen and (max-width : 768px) {
    .modal-pw.size1 {
        top:8px
    }
}

.modal-pw.size1 .grecaptcha-badge {
    visibility: hidden
}

.modal-pw.size2 {
    top: 24px;
    width: calc(100% - 16px);
    max-width: 1108px
}

@media only screen and (max-width : 768px) {
    .modal-pw.size2 {
        top:8px
    }
}

.modal-pw.size2 .grecaptcha-badge {
    visibility: hidden
}

.modal-pw.medium {
    width: calc(100% - 16px);
    max-width: 640px
}

.modal-pw.large {
    width: calc(100% - 16px);
    max-width: 1000px;
    top: 50px
}

@media only screen and (max-width : 768px) {
    .modal-pw.large {
        top:8px
    }
}

.modal-pw.hidden-overflow {
    overflow: hidden
}

.modal-pw .spacer {
    height: 80px;
    margin: 0 0 -80px;
}

.modal-pw .modal-content2 {
    padding: 24px 24px 32px !important;
    border-radius: 0 0 12px 12px !important;
}

.modal-pw .modal-head .title {
    font-weight: 700;
    position: relative;
    top: 2px;
    font-size: 20px;
    text-align: left;
    line-height: 1.4;
    padding-right: 32px;
}

.modal-pw .modal-head {
    border-bottom: 1px solid #EEE;
    padding: 16px 24px;
    border-radius: 12px 12px 0 0;
}

.modal-pw .modal-head .close, .modal .modal-head .back {
    position: absolute;
    top: 20px;
    right: 24px;
    display: block;
    width: 24px;
    height: 24px;
    color: #3a3939;
    text-align: center;
    line-height: 24px;
    font-size: 20px;
}

i[data-v-4d6be015] {
    margin-right: 8px
}

span[data-v-4d6be015] {
    font-size: 12px
}

.kyc-status[data-v-4d6be015] {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #EEE;
    padding: 16px 24px;
    text-align: left
}

.theme-dark .kyc-status[data-v-4d6be015] {
    border: 1px solid #40494f
}

@media only screen and (max-width : 768px) {
    .kyc-status[data-v-4d6be015] {
        margin-top:16px;
        width: 100%
    }
}

.block[data-v-4d6be015] {
    display: block
}

.avatar-holder[data-v-4d6be015] {
    float: left;
    margin-right: 16px
}

[data-v-4d6be015] .country-codes .options .option {
    text-align: right
}

.country-codes[data-v-4d6be015] {
    min-width: 80px
}

.tiers td[data-v-4d6be015] {
    padding: 4px 0
}


.avatar[data-v-e9688c3f] {
    text-align: center;
    background-size: cover;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    position: relative;
}

.avatar-holder[data-v-4d6be015] {
    float: left;
    margin-right: 16px;
}

.field-wrap .label-row label[data-v-45b1ddc6] {
    color: #959595;
    display: block;
    flex: 1;
}

.field-wrap .label-row[data-v-45b1ddc6] {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.input-group[data-v-f5470495] {
    position: relative;
}

.input-group input[data-v-f5470495] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 9px 16px 11px;
    margin: 0;
}

.form-control {
    width: 100%;
}

.input-group input[data-v-f5470495]:disabled {
    cursor: not-allowed;
    opacity: .5;
}

.input-group input[data-v-f5470495]:disabled, .input-group input[data-v-f5470495]:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.text-light {
    font-weight: 300;
    color: black !important;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#002BAE,#002BAE);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

#app_holder.gray-bg {
    background: #f5f5f5;
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 4px;
}
</style>