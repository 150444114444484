<template>
  <div id="app_holder">

    

      <template v-if="showdis2faModal">
        <div data-v-82c01ab1="" class="modal-bg" :class="showdis2faModal ? 'show' : ''">
    
    <div data-v-82c01ab1="" class="modal-pw show" role="dialog">
        
          <div class="modal-head">
            <div class="title">{{$t('dis2FABtn')}}</div>
            <!-- <button type="button" class="btn-close" @click="hidedis2faModal"></button> -->
            <i class="fa fa-times pointer close" aria-label="Close" @click="hidedis2faModal"></i>
          </div>
          <div class="modal-content2">
            <div  class="content">
                <div class="section">
                  <br >
                  <p >{{$t('sixDigitDesc')}}</p>
                  <div class="field-wrap mb16">
                    
                    <div class="input-group2 auth">
                      <div class="input-group-prepend" >
                        <i class="fa fa-mobile-alt"></i>
                      </div>
                      <input v-model="verifyCode" autocomplete="off" type="tel" @keypress="restrictToNumbers" class="form-control" :placeholder="$t('sixDigitCodePlaceholder')" maxlength="">
                    </div>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-xs-12 flex flex--justify-center mt24">
                <button @click="disableTwoFactor" class="btn primary normal flex__fill-gap" :disabled="isSubmitting">
                  <span v-if="!isSubmitting">{{$t('disableBtn')}}</span>
                  <span v-else>
                      <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                      {{ $t('loggingIn') }}...
                  </span>
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </template>

      <template v-if="show2faModal">
        <div data-v-82c01ab1="" class="modal-bg" :class="show2faModal ? 'show' : ''">
    
          <div data-v-82c01ab1="" class="modal-pw show" role="dialog">
        <div class="modal-content2">
          <div class="modal-head">
            <div class="title">{{$t('enable2FABtn')}}</div>
            <!-- <button type="button" class="btn-close" @click="hide2faModal"></button> -->
            <i class="fa fa-times pointer close" aria-label="Close" @click="hide2faModal"></i>
          </div>
          
            <template v-if="!showNext">
            <div  class="content2">
              <div  class="section mt32">
                <h3 class="mb24" v-html="$t('twoFactorDescription1')"></h3>
                <div  class="row">
                  <div  class="col-sm-6 flex justify-content-center">
                    <a  target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" rel="noopener">
                      <img  src="@/assets/images/google-play.png" class="store" alt="Play Store">
                    </a>
                  </div>
                  <div  class="col-sm-6 flex justify-content-center mt16-xs-down">
                    <a  target="_blank" href="https://itunes.apple.com/si/app/google-authenticator/id388497605?mt=8" rel="noopener">
                      <img  src="@/assets/images/apple-store.png" alt="App Store" class="store">
                    </a>
                  </div>
                </div>
              </div>
              <hr  class="mt32 mb24">
              <div  class="section">
                <h3 v-html="$t('twoFactorDescription2')">
                </h3>
              </div>
              <hr  class="mt24 mb24">
              <div  class="section">
                <h3 >
                  <span v-html="$t('twoFactorDescription3')"> </span> {{privateCode}}
                </h3>
                <div  class="flex justify-content-center">
                  <template v-if="isLoading">
                    <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                  </template>
                  <template v-else>
                    <qrcode-vue :value="qrCode" size="256" level="H"></qrcode-vue>
                  </template>
                </div>
              </div>
              
            </div>

            <div  class="row">
              <div  class="col-xs-12 flex flex--justify-center mt24">
                <button  @click="toggleNext" class="btn primary normal flex__fill-gap">
                  <span>{{$t('nextBtn')}}</span>
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div  class="content">
                
                  <div  class="section">
                    <br >
                    <p >{{$t('sixDigitDesc')}}</p>
                    <div class="field-wrap mb16">
                      
                      <div class="input-group2 auth">
                        <div class="input-group-prepend" >
                          <i class="fa fa-mobile-alt"></i>
                        </div>
                        <input v-model="verifyCode" autocomplete="off" type="tel" @keypress="restrictToNumbers" class="form-control" :placeholder="$t('sixDigitCodePlaceholder')" maxlength="">
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-xs-12 flex flex--justify-center mt24">
                  <button @click="enableTwoFactor" class="btn primary normal flex__fill-gap" :disabled="isSubmitting">
                    <span v-if="!isSubmitting">{{$t('verify')}}</span>
                    <span v-else>
                        <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                        {{ $t('loggingIn') }}...
                    </span>
                  </button>
                </div>
              </div>
                
          </template>
            
          </div>
        </div>
      
    </div>

    
  </template>

      <template v-if="showModalPw">
      <div data-v-82c01ab1="" class="modal-bg" :class="showModalPw ? 'show' : ''">

        <span data-v-82c01ab1="" v-if="showModalPw">
   <div data-v-82c01ab1="" class="modal-pw show" role="dialog">
      <div data-v-e10e9a62="" data-v-82c01ab1="">
         <div class="modal-head">
            <div class="title">{{$t('changePassword')}}</div>
            <i class="fa fa-times pointer close" aria-label="Close" @click="hideModalPw"></i>
         </div>
         <div class="modal-content2">
          
            <form @submit.prevent="changePassword">
                <div class="field-wrap mt-3">
                  <div class="label-row">
                    <label>{{$t('currentPassword')}}</label>
                  </div>
                  <div class="input-group">
                    <input type="password" class="form-control" v-model="form.current" :placeholder="$t('currentPassword')">
                  </div>
                </div>
                <div class="field-wrap">
                  <div class="label-row">
                    <label>{{$t('newPassword')}}</label>
                  </div>
                  <div class="input-group mb16">
                    <input type="password" class="form-control" v-model="form.password" :placeholder="$t('newPassword')">
                  </div>
                  <div class="input-group">
                    <input type="password" class="form-control" v-model="form.password_confirmation" :placeholder="$t('newPasswordAgain')">
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn primary normal fluid mt24" :disabled="isSubmitting">
                    <span v-if="!isSubmitting">{{$t('changePassword')}}</span>
                    <span v-else>
                        <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                        {{ $t('loggingIn') }}...
                    </span>
                  </button>
                </div>
              </form>
          
        </div>
      </div>
   </div>
</span>


      </div>
    </template>


      <div id="content">
          <Sidebar/>

        <div class="container flex mb32 settings-panel">
          <SidebarSettings/>  


          <div data-v-63b81b20="" class="content ml32 ml0-sm-down mt32-sm-down">
   <div class="panel big-shadow">

    <div class="toast-container start-50 translate-middle-x">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <template v-if="message === ''">
                  <ul>
                    <template v-for="(fieldErrors, field) in errors">
                      <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
                    </template>
                  </ul>
              </template>
              <template v-else>
                <p class="text-center">{{message}}</p>
              </template>
              </div>
              <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

      <div class="toast-container start-50 translate-middle-x">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToastSc ,'text-bg-success': showSuccess }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>


      <div data-v-257941bc="" data-v-63b81b20="" class="securityPanel">
         <h1 data-v-257941bc="" class="text-bold mb8">{{$t('security')}}</h1>
         <div data-v-257941bc="" class="mb32"><small data-v-257941bc="" class="text-muted">{{$t('securityReview')}}</small></div>
         <div data-v-257941bc="">
            <h3 data-v-257941bc="" class="text-light mb8">{{$t('changePassword')}}</h3>
            <hr data-v-257941bc="" class="mb16">
            <div data-v-257941bc="" class="row">
               <div data-v-257941bc="" class="col-xs-12 col-sm-6"><button data-v-257941bc="" class="btn primary normal fluid" @click="openModalPw">{{$t('changePassword')}}</button></div>
            </div>
         </div>
         <div data-v-257941bc="">
            <h3 data-v-257941bc="" class="text-light mt32">
              {{$t('tfaDesc')}}
              <span class="text-success text-bold" v-if="user?.user?.google2fa_enable">{{$t('settingsSection.enabled')}} </span>
            </h3>
            <hr data-v-257941bc="" class="mb8">
            <div data-v-257941bc="" class="mb16">
              <small data-v-257941bc="" class="text-muted">{{$t('tfaDescSmall')}}</small>
            </div>
            <div data-v-257941bc="" class="row">
               <div data-v-257941bc="" class="col-xs-12 col-sm-6">
                <template v-if="!user?.user?.google2fa_enable">
                  <button class="btn primary normal fluid" @click="open2faModal"><span>{{$t('enable2FABtn')}}</span></button>
                </template>
                <template v-else>
                  <button class="btn primary normal danger outline fluid" @click="openDisModal"><span>{{$t('dis2FABtn')}}</span></button>
                </template>
              </div>
            </div>
         </div>
         
         
         
         <div data-v-257941bc="">
            <h3 data-v-257941bc="" class="text-light mt32">{{$t('settingsSection.activityTL')}}</h3>
            <hr data-v-257941bc="" class="mb8">
            <div data-v-257941bc="" class="mb24"><small data-v-257941bc="" class="text-muted">{{$t('settingsSection.activitySub')}}</small></div>
            <div data-v-d723abed="" data-v-257941bc="" class="table-wrap relative table">
               
               <div data-v-d723abed="" class="table-container">
                  <table data-v-d723abed="" cellspacing="0" cellpadding="0" class="nh padding-normal">
                     <thead data-v-d723abed="">
                        <tr data-v-d723abed="">
                           <th data-v-d723abed="" class="">
                            {{$t('settingsSection.tbWhen')}}
                           </th>
                           <th data-v-d723abed="" class="">
                            {{$t('settingsSection.tbLocation')}}
                           </th>
                           <th data-v-d723abed="" class="">
                            {{$t('settingsSection.tbIp')}}
                           </th>
                           <th data-v-d723abed="" class="">
                              {{$t('settingsSection.tbAction')}} 
                           </th>
                        </tr>
                     </thead>
                     <tbody data-v-d723abed="">
                        <tr data-v-d723abed="" class="" v-for="(activity, index) in activities" :key="index">
                           <td data-v-d723abed="" class=""><span data-v-518562c9="" data-v-257941bc="" class="text-primary">{{formatDate(activity.activity_time)}} </span></td>
                           <td data-v-d723abed="" class=""><span data-v-257941bc="">{{ activity.country_name }}</span></td>
                           <td data-v-d723abed="" class="text-wrap">{{activity.ip_address}}</td>
                           <td data-v-d723abed="" class="text-wrap">{{activity.action}}</td>
                        </tr>
                     </tbody>
                  </table>
                  <!-- <div v-else class="d-flex justify-content-center mt-4">
                      <p>{{$t('noData')}}</p>
                  </div> -->

                  <div class="overlay loader" v-if="isLoadingTable">
                    <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                  </div>
                  
               </div>
               
            </div>
         </div>
      </div>
   </div>
</div>



        </div>


      </div>

      
    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue';
import SidebarSettings from './SidebarSettings.vue';
import clickOutside from '../directives/clickOutside';
import QrcodeVue from 'qrcode.vue';
import axios from 'axios';
import moment from 'moment';
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  name: 'CloudhashFrontendSettings',
  components: { FooterDash, SidebarSettings, Sidebar, QrcodeVue },
  data() {
    return {
      showModalPw: false,
      show2faModal: false,
      showdis2faModal: false,
      form: {
          current: '',
          password: '',
          password_confirmation: ''
      },
      isSubmitting: false,
      errors: {},
      message: '',
      showToast: false,
      showError: false,
      showSuccess: false,
      showToastSc: false,
      qrCode: '',
      privateCode: '',
      showNext: false,
      ipClient: 0,
      verifyCode: '',
      isActive: false,
      isLoading: false,
      isLoadingTable: false,
      showoModalmnemonic: false,
      mnemonic: '',
      selectedWords: [],
      activities: []
    };
  },

  mounted() {
    this.getActivities()
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
  },
  directives: {
    clickOutside,
  },
  methods: {
    formatDate(date){
      return moment.utc(date).local().fromNow();
      // moment(date).fromNow();
    },
    getActivities(){
        this.isLoadingTable = true;
        axios.get(process.env.VUE_APP_API_URL+'getActivityLog')
        .then((res)=>{
            this.activities = res.data;
            this.isLoadingTable = false;
        })  
        .catch((error)=>{
            console.log('error getting activity log')
            this.isLoadingTable = false;
        })
    },
    restrictToNumbers(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        if (!/^[0-9]+$/.test(keyValue)) {
            event.preventDefault();
        }
    },
    selectWord(index) {
        // Lógica para seleccionar las palabras en orden
        if (!this.isSelected(index)) {
          this.selectedWords.push(index);
        } else {
          this.selectedWords.splice(this.selectedWords.indexOf(index), 1);
        }
    },
    isSelected(index) {
      // Verifica si la palabra está seleccionada
      return this.selectedWords.includes(index);
    },
    disableTwoFactor() {
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;
      if(this.verifyCode === ''){
        this.message = 'Invalid Code'
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
            return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'disableTwoFactor', {
        verifyCode: this.verifyCode
      })
      .then((res)=>{
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = res.data.message;
        this.hidedis2faModal();
        this.verifyCode = '';
        this.isSubmitting = false;
        this.$store.dispatch('user/intento');
        setTimeout(()=>{
          this.showToastSc = false;
          this.showSuccess = false;
          this.message = '';
        },5000);
        //console.log(res.data);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          
        }
        //console.log(error.response.data.error);
      })
    },
    enableTwoFactor() {
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;
      if(this.verifyCode === ''){
        this.message = 'Invalid Code'
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
            return;
      }
      axios.post(process.env.VUE_APP_API_URL + 'enableTwoFactor', {
        verifyCode: this.verifyCode
      })
      .then((res)=>{
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = res.data.message;
        this.hide2faModal();
        this.verifyCode = '';
        this.isSubmitting = false;
        this.$store.commit('user/SET_REQUIRES_TWO_FACTOR_AUTH', true);
        this.$store.dispatch('user/intento');
        setTimeout(()=>{
          this.showToastSc = false;
          this.showSuccess = false;
          this.message = '';
        },5000);
        //console.log(res.data);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          
        }
        //console.log(error.response.data.error);
      })
    },
    toggleNext() {
      this.showNext = true;
    },
    hide2faModal() {
      this.show2faModal = false;
      this.showNext = false;
    //  document.body.classList.remove('modal-open');
    },
    hidedis2faModal() {
      this.showdis2faModal = false;
      //document.body.classList.remove('modal-open');
    },
    openDisModal() {
      this.showdis2faModal = true;
      //document.body.classList.add('modal-open');
    },
    open2faModal() {
      this.show2faModal = true;
      //document.body.classList.add('modal-open');
      this.getTwoGoogleCode();
    },
    getTwoGoogleCode() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getGoogleCode')
      .then((res)=>{
        this.qrCode = res.data.qrCode;
        this.privateCode = res.data.secret;
        this.isLoading = false;
      })
      .catch((error)=>{
        console.log(error.response.data.error);
      })
    },
    closeToastSc() {
      this.showToastSc = false;
      this.showSuccess = false;
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    },
    changePassword () {
      // Evita el envío si el formulario ya se está enviando
      if (this.isSubmitting) return;

      // Inicia la animación del botón y deshabilítalo
      this.isSubmitting = true;

      axios.post(process.env.VUE_APP_API_URL + 'changePassword', {
        current: this.form.current,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      })
      .then(response => {
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = response.data.message;
        this.hideModalPw();
        this.form.current = '';
        this.form.password = '';
        this.form.password_confirmation = '';
        this.isSubmitting = false;
        setTimeout(()=>{
          this.showToastSc = false;
          this.showSuccess = false;
          this.message = '';
        },5000);
      })
      .catch((error)=>{
        if (error.response.status === 422) {
          if (typeof error.response.data.error === 'string') {
            this.message = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
          } else {
            this.errors = error.response.data.error;
            this.showToast = true;
            this.showError = true;
            this.isSubmitting = false;
            setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.errors = {};
            },5000);
          }
        }
      })
    },
    hideModalPw2() {
      this.showModalPw = false;
     // document.body.classList.remove('modal-open');
    },
    hideModalPw() {
      this.showModalPw = false;
     // document.body.classList.remove('modal-open');
    },
    openModalPw() {
      this.showModalPw = true;
      //document.body.classList.add('modal-open');
    }
  },
};
</script>
<style scoped>
h1 {
    font-size: 24px;
    font-weight: 700;
}

.mb8 {
    margin-bottom: 8px;
}

.text-muted {
    color: #959595;
}

small {
    font-size: 12px;
    line-height: 1.42;
}

.mb24 {
    margin-bottom: 24px;
}

hr {
    border: 0;
    border-bottom: 1px solid #EEE;
}

h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.mb16 {
    margin-bottom: 16px;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#002BAE,#002BAE);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mt32 {
    margin-top: 20px;
}

.btn.primary:hover:not(.disabled) {
    background: linear-gradient(90deg,#19345D,#19345D);
    box-shadow: 0 24px 12px -19px #19345D8f
}

.modal-pw .spacer {
    height: 80px;
    margin: 0 0 -80px;
}

.content2 {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.content2 .section {
    padding: 0px 24px;
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px;
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1;
}

.input-group input[type=password] {
    padding-right: 82px
}

.input-group input::placeholder {
    color: #cecdcd;
    font-size: 14px
}

.field-wrap+.field-wrap {
    margin-top: 24px;
}

.mt24 {
    margin-top: 20px;
}

.content[data-v-e6989c53] {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.toast-body ul {
    list-style-type: none; /* Quita el marcador de lista */
    padding: 0; /* Elimina el relleno predeterminado de la lista */
}

.content2 .section {
    padding: 0 24px;
}

a {
    color: #128ede !important;
    text-decoration: none;
    transition: .2s ease color;
    cursor: pointer;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.content2 .store {
    width: 160px;
}

.modal-dialog-custom {
    position: relative;
    width: 400px;
    margin: var(--bs-modal-margin);
    pointer-events: none;
}

@media (min-width: 576px){
.modal-dialog-custom {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
}
}

.content {
    background: transparent;
    flex: 1 1 0%;
    border-radius: 4px;
}

.section {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--nbhs-section-bg);
    overflow: hidden;
}

.content2 {
    margin-left: -24px;
    width: calc(100% + 48px);
}

.flex__fill-gap {
    flex: 1;
}

.modal-pw p {
    margin: 16px 0 24px;
    color: #959595;
    line-height: 1.43;
    font-size: 14px;
}

.input-group.auth .input-group-prepend~input,.input-group.medium .input-group-prepend~input {
    padding-left: 56px
}

.input-group.auth .input-group-prepend,.input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px
}

.input-group input:focus-within + .input-group-prepend {
    color: #cecdcd; /* Cambia el color cuando el input está en foco */
}

.input-group .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group .input-group-prepend.active {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group-prepend.active {
  color: #cecdcd; /* Cambia el color del icono cuando está activo */
}

.input-group.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.client-info {
    margin: 0 0 16px;
    background: #eee;
    line-height: 18px;
    padding: 8px 0;
}

.fs12 {
    font-size: 12px;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: #959595;
}

.mb8 {
    margin-bottom: 8px;
}

@media only screen and (min-width: 321px) {
  .col-micro-6 {
      max-width: 50%;
      flex-basis: 50%;
  }
}

.mr4 {
    margin-right: 4px;
}

.text-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
}



/* @media only screen and (min-width: 481px) {
  .col-xs-12 {
      max-width: 100%;
      flex-basis: 100%;
  }
} */

.input-group.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.mnemonic-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mnemonic-buttons button {
  margin-bottom: 10px;
}

.btn-outline-primary {
  text-transform: none;
}

.input-group2 input:hover {
    box-shadow: 0 4px 12px #23232329
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2 input:-moz-read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 input:disabled,.input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 1px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.large .input-group-prepend~input {
    padding-left: 92px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend~input {
    padding-left: 56px
}

@media (min-width: 576px) {
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
}

.text-light {
    font-weight: 300;
    color: black !important;

}

@media only screen and (max-width : 991px) {
    .content[data-v-63b81b20] {
        flex-basis:100%;
        max-width: calc(100vw - 32px)
    }
}

@media only screen and (max-width : 768px) {
    .content .panel[data-v-63b81b20] {
        padding:24px
    }
}

@media only screen and (max-width : 991px) {
    .pt0-sm-down {
        padding-top:0
    }

    .pt2-sm-down {
        padding-top: 2px
    }

    .pt4-sm-down {
        padding-top: 4px
    }

    .pt6-sm-down {
        padding-top: 6px
    }

    .pt8-sm-down {
        padding-top: 8px
    }

    .pt10-sm-down {
        padding-top: 10px
    }

    .pt12-sm-down {
        padding-top: 12px
    }

    .pt14-sm-down {
        padding-top: 14px
    }

    .pt16-sm-down {
        padding-top: 16px
    }

    .pt24-sm-down {
        padding-top: 24px
    }

    .pt32-sm-down {
        padding-top: 32px
    }

    .pt40-sm-down {
        padding-top: 40px
    }

    .pt48-sm-down {
        padding-top: 48px
    }

    .pt56-sm-down {
        padding-top: 56px
    }

    .pt64-sm-down {
        padding-top: 64px
    }

    .pt72-sm-down {
        padding-top: 72px
    }

    .pt80-sm-down {
        padding-top: 80px
    }

    .pt88-sm-down {
        padding-top: 88px
    }

    .pt96-sm-down {
        padding-top: 96px
    }

    .pt104-sm-down {
        padding-top: 104px
    }

    .pt112-sm-down {
        padding-top: 112px
    }

    .pt120-sm-down {
        padding-top: 120px
    }

    .pt128-sm-down {
        padding-top: 128px
    }

    .pt136-sm-down {
        padding-top: 136px
    }

    .pt144-sm-down {
        padding-top: 144px
    }

    .pt152-sm-down {
        padding-top: 152px
    }

    .pt160-sm-down {
        padding-top: 160px
    }

    .pb0-sm-down {
        padding-bottom: 0
    }

    .pb2-sm-down {
        padding-bottom: 2px
    }

    .pb4-sm-down {
        padding-bottom: 4px
    }

    .pb6-sm-down {
        padding-bottom: 6px
    }

    .pb8-sm-down {
        padding-bottom: 8px
    }

    .pb10-sm-down {
        padding-bottom: 10px
    }

    .pb12-sm-down {
        padding-bottom: 12px
    }

    .pb14-sm-down {
        padding-bottom: 14px
    }

    .pb16-sm-down {
        padding-bottom: 16px
    }

    .pb24-sm-down {
        padding-bottom: 24px
    }

    .pb32-sm-down {
        padding-bottom: 32px
    }

    .pb40-sm-down {
        padding-bottom: 40px
    }

    .pb48-sm-down {
        padding-bottom: 48px
    }

    .pb56-sm-down {
        padding-bottom: 56px
    }

    .pb64-sm-down {
        padding-bottom: 64px
    }

    .pb72-sm-down {
        padding-bottom: 72px
    }

    .pb80-sm-down {
        padding-bottom: 80px
    }

    .pb88-sm-down {
        padding-bottom: 88px
    }

    .pb96-sm-down {
        padding-bottom: 96px
    }

    .pb104-sm-down {
        padding-bottom: 104px
    }

    .pb112-sm-down {
        padding-bottom: 112px
    }

    .pb120-sm-down {
        padding-bottom: 120px
    }

    .pb128-sm-down {
        padding-bottom: 128px
    }

    .pb136-sm-down {
        padding-bottom: 136px
    }

    .pb144-sm-down {
        padding-bottom: 144px
    }

    .pb152-sm-down {
        padding-bottom: 152px
    }

    .pb160-sm-down {
        padding-bottom: 160px
    }

    .pr0-sm-down {
        padding-right: 0
    }

    .pr2-sm-down {
        padding-right: 2px
    }

    .pr4-sm-down {
        padding-right: 4px
    }

    .pr6-sm-down {
        padding-right: 6px
    }

    .pr8-sm-down {
        padding-right: 8px
    }

    .pr10-sm-down {
        padding-right: 10px
    }

    .pr12-sm-down {
        padding-right: 12px
    }

    .pr14-sm-down {
        padding-right: 14px
    }

    .pr16-sm-down {
        padding-right: 16px
    }

    .pr24-sm-down {
        padding-right: 24px
    }

    .pr32-sm-down {
        padding-right: 32px
    }

    .pr40-sm-down {
        padding-right: 40px
    }

    .pr48-sm-down {
        padding-right: 48px
    }

    .pr56-sm-down {
        padding-right: 56px
    }

    .pr64-sm-down {
        padding-right: 64px
    }

    .pr72-sm-down {
        padding-right: 72px
    }

    .pr80-sm-down {
        padding-right: 80px
    }

    .pr88-sm-down {
        padding-right: 88px
    }

    .pr96-sm-down {
        padding-right: 96px
    }

    .pr104-sm-down {
        padding-right: 104px
    }

    .pr112-sm-down {
        padding-right: 112px
    }

    .pr120-sm-down {
        padding-right: 120px
    }

    .pr128-sm-down {
        padding-right: 128px
    }

    .pr136-sm-down {
        padding-right: 136px
    }

    .pr144-sm-down {
        padding-right: 144px
    }

    .pr152-sm-down {
        padding-right: 152px
    }

    .pr160-sm-down {
        padding-right: 160px
    }

    .pl0-sm-down {
        padding-left: 0
    }

    .pl2-sm-down {
        padding-left: 2px
    }

    .pl4-sm-down {
        padding-left: 4px
    }

    .pl6-sm-down {
        padding-left: 6px
    }

    .pl8-sm-down {
        padding-left: 8px
    }

    .pl10-sm-down {
        padding-left: 10px
    }

    .pl12-sm-down {
        padding-left: 12px
    }

    .pl14-sm-down {
        padding-left: 14px
    }

    .pl16-sm-down {
        padding-left: 16px
    }

    .pl24-sm-down {
        padding-left: 24px
    }

    .pl32-sm-down {
        padding-left: 32px
    }

    .pl40-sm-down {
        padding-left: 40px
    }

    .pl48-sm-down {
        padding-left: 48px
    }

    .pl56-sm-down {
        padding-left: 56px
    }

    .pl64-sm-down {
        padding-left: 64px
    }

    .pl72-sm-down {
        padding-left: 72px
    }

    .pl80-sm-down {
        padding-left: 80px
    }

    .pl88-sm-down {
        padding-left: 88px
    }

    .pl96-sm-down {
        padding-left: 96px
    }

    .pl104-sm-down {
        padding-left: 104px
    }

    .pl112-sm-down {
        padding-left: 112px
    }

    .pl120-sm-down {
        padding-left: 120px
    }

    .pl128-sm-down {
        padding-left: 128px
    }

    .pl136-sm-down {
        padding-left: 136px
    }

    .pl144-sm-down {
        padding-left: 144px
    }

    .pl152-sm-down {
        padding-left: 152px
    }

    .pl160-sm-down {
        padding-left: 160px
    }

    .mt0-sm-down {
        margin-top: 0
    }

    .mt2-sm-down {
        margin-top: 2px
    }

    .mt4-sm-down {
        margin-top: 4px
    }

    .mt6-sm-down {
        margin-top: 6px
    }

    .mt8-sm-down {
        margin-top: 8px
    }

    .mt10-sm-down {
        margin-top: 10px
    }

    .mt12-sm-down {
        margin-top: 12px
    }

    .mt14-sm-down {
        margin-top: 14px
    }

    .mt16-sm-down {
        margin-top: 16px
    }

    .mt24-sm-down {
        margin-top: 24px
    }

    .mt32-sm-down {
        margin-top: 32px
    }

    .mt40-sm-down {
        margin-top: 40px
    }

    .mt48-sm-down {
        margin-top: 48px
    }

    .mt56-sm-down {
        margin-top: 56px
    }

    .mt64-sm-down {
        margin-top: 64px
    }

    .mt72-sm-down {
        margin-top: 72px
    }

    .mt80-sm-down {
        margin-top: 80px
    }

    .mt88-sm-down {
        margin-top: 88px
    }

    .mt96-sm-down {
        margin-top: 96px
    }

    .mt104-sm-down {
        margin-top: 104px
    }

    .mt112-sm-down {
        margin-top: 112px
    }

    .mt120-sm-down {
        margin-top: 120px
    }

    .mt128-sm-down {
        margin-top: 128px
    }

    .mt136-sm-down {
        margin-top: 136px
    }

    .mt144-sm-down {
        margin-top: 144px
    }

    .mt152-sm-down {
        margin-top: 152px
    }

    .mt160-sm-down {
        margin-top: 160px
    }

    .mb0-sm-down {
        margin-bottom: 0
    }

    .mb2-sm-down {
        margin-bottom: 2px
    }

    .mb4-sm-down {
        margin-bottom: 4px
    }

    .mb6-sm-down {
        margin-bottom: 6px
    }

    .mb8-sm-down {
        margin-bottom: 8px
    }

    .mb10-sm-down {
        margin-bottom: 10px
    }

    .mb12-sm-down {
        margin-bottom: 12px
    }

    .mb14-sm-down {
        margin-bottom: 14px
    }

    .mb16-sm-down {
        margin-bottom: 16px
    }

    .mb24-sm-down {
        margin-bottom: 24px
    }

    .mb32-sm-down {
        margin-bottom: 32px
    }

    .mb40-sm-down {
        margin-bottom: 40px
    }

    .mb48-sm-down {
        margin-bottom: 48px
    }

    .mb56-sm-down {
        margin-bottom: 56px
    }

    .mb64-sm-down {
        margin-bottom: 64px
    }

    .mb72-sm-down {
        margin-bottom: 72px
    }

    .mb80-sm-down {
        margin-bottom: 80px
    }

    .mb88-sm-down {
        margin-bottom: 88px
    }

    .mb96-sm-down {
        margin-bottom: 96px
    }

    .mb104-sm-down {
        margin-bottom: 104px
    }

    .mb112-sm-down {
        margin-bottom: 112px
    }

    .mb120-sm-down {
        margin-bottom: 120px
    }

    .mb128-sm-down {
        margin-bottom: 128px
    }

    .mb136-sm-down {
        margin-bottom: 136px
    }

    .mb144-sm-down {
        margin-bottom: 144px
    }

    .mb152-sm-down {
        margin-bottom: 152px
    }

    .mb160-sm-down {
        margin-bottom: 160px
    }

    .mr0-sm-down {
        margin-right: 0
    }

    .mr2-sm-down {
        margin-right: 2px
    }

    .mr4-sm-down {
        margin-right: 4px
    }

    .mr6-sm-down {
        margin-right: 6px
    }

    .mr8-sm-down {
        margin-right: 8px
    }

    .mr10-sm-down {
        margin-right: 10px
    }

    .mr12-sm-down {
        margin-right: 12px
    }

    .mr14-sm-down {
        margin-right: 14px
    }

    .mr16-sm-down {
        margin-right: 16px
    }

    .mr24-sm-down {
        margin-right: 24px
    }

    .mr32-sm-down {
        margin-right: 32px
    }

    .mr40-sm-down {
        margin-right: 40px
    }

    .mr48-sm-down {
        margin-right: 48px
    }

    .mr56-sm-down {
        margin-right: 56px
    }

    .mr64-sm-down {
        margin-right: 64px
    }

    .mr72-sm-down {
        margin-right: 72px
    }

    .mr80-sm-down {
        margin-right: 80px
    }

    .mr88-sm-down {
        margin-right: 88px
    }

    .mr96-sm-down {
        margin-right: 96px
    }

    .mr104-sm-down {
        margin-right: 104px
    }

    .mr112-sm-down {
        margin-right: 112px
    }

    .mr120-sm-down {
        margin-right: 120px
    }

    .mr128-sm-down {
        margin-right: 128px
    }

    .mr136-sm-down {
        margin-right: 136px
    }

    .mr144-sm-down {
        margin-right: 144px
    }

    .mr152-sm-down {
        margin-right: 152px
    }

    .mr160-sm-down {
        margin-right: 160px
    }

    .ml0-sm-down {
        margin-left: 0
    }

    .ml2-sm-down {
        margin-left: 2px
    }

    .ml4-sm-down {
        margin-left: 4px
    }

    .ml6-sm-down {
        margin-left: 6px
    }

    .ml8-sm-down {
        margin-left: 8px
    }

    .ml10-sm-down {
        margin-left: 10px
    }

    .ml12-sm-down {
        margin-left: 12px
    }

    .ml14-sm-down {
        margin-left: 14px
    }

    .ml16-sm-down {
        margin-left: 16px
    }

    .ml24-sm-down {
        margin-left: 24px
    }

    .ml32-sm-down {
        margin-left: 32px
    }

    .ml40-sm-down {
        margin-left: 40px
    }

    .ml48-sm-down {
        margin-left: 48px
    }

    .ml56-sm-down {
        margin-left: 56px
    }

    .ml64-sm-down {
        margin-left: 64px
    }

    .ml72-sm-down {
        margin-left: 72px
    }

    .ml80-sm-down {
        margin-left: 80px
    }

    .ml88-sm-down {
        margin-left: 88px
    }

    .ml96-sm-down {
        margin-left: 96px
    }

    .ml104-sm-down {
        margin-left: 104px
    }

    .ml112-sm-down {
        margin-left: 112px
    }

    .ml120-sm-down {
        margin-left: 120px
    }

    .ml128-sm-down {
        margin-left: 128px
    }

    .ml136-sm-down {
        margin-left: 136px
    }

    .ml144-sm-down {
        margin-left: 144px
    }

    .ml152-sm-down {
        margin-left: 152px
    }

    .ml160-sm-down {
        margin-left: 160px
    }
}

.modal .modal-head .title {
    font-weight: 700;
    position: relative;
    top: 2px;
    font-size: 20px;
    text-align: left;
    line-height: 1.4;
    padding-right: 32px;
}

.content .section {
    padding: 0 24px;
}

.btn.outline.danger {
    background: transparent;
    color: #e92424;
    box-shadow: inset 0 0 0 1px #e92424!important;
}

.btn.outline.danger:active,.btn.outline.danger:hover:not(.disabled) {
    box-shadow: inset 0 0 0 1px #e92424!important
}

.btn.danger:hover:not(.disabled) {
    box-shadow: 0 18px 12px -14px #e9242466!important;
    background: #e92424;
    color: #fff
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.content {
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 4px;
}


.modal-pw {
    display: none;
    background: #fff;
    width: 400px;
    box-shadow: 0 32px 40px #23232329;
    z-index: 106;
    border-radius: 12px;
    text-align: left;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    margin-bottom: 24px
}

.theme-dark .modal-pw {
    background: #031a2a;
    border: 1px solid #40494f
}

.modal-pw.show {
    display: block
}

.modal-pw.size0 {
    top: 160px;
    width: calc(100% - 16px);
    max-width: 560px
}

@media only screen and (max-width : 768px) {
    .modal-pw.size0 {
        top:8px
    }
}

.modal-pw.size0.solo {
    top: 48px
}

@media only screen and (max-width : 991px) {
    .modal-pw.size0.solo {
        top:8px
    }
}

.modal-pw.size0.merge {
    max-width: 880px
}

.modal-pw.size0.shared {
    top: 48px;
    max-width: 697px
}

@media only screen and (max-width : 991px) {
    .modal-pw.size0.shared {
        top:8px
    }
}

.modal-pw.size1 {
    top: 48px;
    width: calc(100% - 16px);
    max-width: 610px
}

@media only screen and (max-width : 768px) {
    .modal-pw.size1 {
        top:8px
    }
}

.modal-pw.size1 .grecaptcha-badge {
    visibility: hidden
}

.modal-pw.size2 {
    top: 24px;
    width: calc(100% - 16px);
    max-width: 1108px
}

@media only screen and (max-width : 768px) {
    .modal-pw.size2 {
        top:8px
    }
}

.modal-pw.size2 .grecaptcha-badge {
    visibility: hidden
}

.modal-pw.medium {
    width: calc(100% - 16px);
    max-width: 640px
}

.modal-pw.large {
    width: calc(100% - 16px);
    max-width: 1000px;
    top: 50px
}

@media only screen and (max-width : 768px) {
    .modal-pw.large {
        top:8px
    }
}

.modal-pw.hidden-overflow {
    overflow: hidden
}

.modal-pw .spacer {
    height: 80px;
    margin: 0 0 -80px;
}

.modal-pw .modal-content2 {
    padding: 24px 24px 32px !important;
    border-radius: 0 0 12px 12px !important;
}

.modal-pw .modal-head .title {
    font-weight: 700;
    position: relative;
    top: 2px;
    font-size: 20px;
    text-align: left;
    line-height: 1.4;
    padding-right: 32px;
}

.modal-pw .modal-head {
    border-bottom: 1px solid #EEE;
    padding: 16px 24px;
    border-radius: 12px 12px 0 0;
}

.modal-pw .modal-head .close, .modal .modal-head .back {
    position: absolute;
    top: 20px;
    right: 24px;
    display: block;
    width: 24px;
    height: 24px;
    color: #3a3939;
    text-align: center;
    line-height: 24px;
    font-size: 20px;
}
</style>