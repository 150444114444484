import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '../store'
import Home from '@/views/Home.vue';
import BalanceDetails from '@/views/BalanceDetails.vue';
import MarketPlace from '@/views/MarketPlace.vue';
import Admin from '@/views/admin/Index.vue';
import WithdrawAddresses from '@/views/WithdrawAddresses.vue';
import Wallets from '@/views/Wallets.vue';
import Pricing from '@/views/Pricing.vue';
import Insights from '@/views/Insights.vue';
import About from '@/views/About.vue';
import FAQs from '@/views/FAQs.vue';
import Affiliate from '@/views/Affiliate.vue';
import Register from '@/views/Register.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Login from '@/views/Login.vue';
import Forgot from '@/views/Forgot.vue';
import TwoFactorAuth from '@/views/2FA.vue';
import Terms from '@/views/Terms.vue';
import Privacy from '@/views/Privacy.vue';
import Dashboard from '@/views/Dashboard.vue';
import Upgrade from '@/views/Upgrade.vue';
import NewWithdraw from '@/views/NewWithdraw.vue';
import NewPartners from '@/views/NewPartners.vue';
import Profile from '@/views/Profile.vue';
import Settings from '@/views/Settings.vue';
import AccountSettings from '@/views/AccountSettings.vue';
import Notification from '@/views/Notification.vue';
import Notifications from '@/views/Notifications.vue';
import ReferralRegister from '@/views/ReferralRegister.vue';
import Contact from '@/views/Contact.vue';
import Bounty from '@/views/Bounty.vue';
import ContractDetail from '@/views/ContractDetail.vue';
import PartneringProgram from '@/views/PartneringProgram.vue';
import BecomeContact from '@/views/BecomeContact.vue';
import Rewards from '@/views/Rewards.vue';
import Exchange from '@/views/Exchange.vue';

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Home',
      description: 'Welcome to CloudHashX - the cloud bitcoin mining platform. Start mining bitcoin today and maximize your profits.'
    },
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Contact us',
      description: 'Welcome to CloudHashX - the cloud bitcoin mining platform. Start mining bitcoin today and maximize your profits.'
    },
  },
  {
    path: '/bounty-program',
    name: 'Bounty',
    component: Bounty,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Bounty program',
      description: 'Welcome to CloudHashX - the cloud bitcoin mining platform. Start mining bitcoin today and maximize your profits.'
    },
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Pricing',
      description: 'Explore our flexible pricing plans for cloud bitcoin mining at CloudHashX. Choose the plan that suits your needs and start mining today.'
    },
  },
  {
    path: '/insights',
    name: 'Insights',
    component: Insights,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Insights',
      description: 'Gain valuable insights into the world of bitcoin mining with CloudHashX. Stay updated with the latest trends, news, and analysis to optimize your mining strategy.'
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | About Us',
      description: 'Descripción de los términos de uso de CloudHashX.'
    },
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | FAQs',
      description: 'Find answers to frequently asked questions about bitcoin mining at CloudHashX. Learn about our platform, services, and how to get started.'
    },
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    component: Affiliate,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Affiliate',
      description: 'Join the CloudHashX affiliate program and earn rewards for referring others to our cloud bitcoin mining platform. Start earning passive income today!'
    },
  },
  {
    path: '/mining-partners',
    name: 'PartneringProgram',
    component: PartneringProgram,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Affiliate',
      description: 'Join the CloudHashX affiliate program and earn rewards for referring others to our cloud bitcoin mining platform. Start earning passive income today!'
    },
  },
  {
    path: '/become-partners',
    name: 'BecomeContact',
    component: BecomeContact,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Affiliate',
      description: 'Join the CloudHashX affiliate program and earn rewards for referring others to our cloud bitcoin mining platform. Start earning passive income today!'
    },
  },
  {
    path: '/terms-of-use',
    name: 'Terms',
    component: Terms,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Terms of Use',
      description: 'Read the terms of use for CloudHashX, the cloud bitcoin mining platform. Understand our policies, rules, and regulations.'
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Privacy Policy',
      description: 'Review the privacy policy of CloudHashX, the cloud bitcoin mining platform. Learn how we collect, use, and protect your data.'
    },
  },
  {
    path: '/sign-up',
    name: 'Register',
    component: Register,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Register',
      description: 'Join CloudHashX, the cloud bitcoin mining platform, and start mining today. Sign up now to get started!'
    },
  },
  {
    path: '/reset-password/:token/:email',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/referral',
    name: 'ReferralRegister',
    component: ReferralRegister,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Register',
      description: 'Join CloudHashX, the cloud bitcoin mining platform, and start mining today. Sign up now to get started!'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Login',
      description: 'Log in to CloudHashX, the cloud bitcoin mining platform. Access your account and start mining bitcoin today.'
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: Forgot,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Forgot Password',
      description: 'Forgot your password? Reset it easily with CloudHashX, the cloud bitcoin mining platform. Follow the steps to regain access to your account.'
    },
  },
  {
    path: '/two-factor-auth',
    name: 'TwoFactorAuth',
    component: TwoFactorAuth,
    meta: {
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Login',
      description: 'Enable two-factor authentication (2FA) for added security on CloudHashX, the cloud bitcoin mining platform. Protect your account from unauthorized access.'
    },
  },
  {
    path: '/my/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: 'Dashboard | CloudHashX',
      description: 'Access your dashboard on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/rewards',
    name: 'Rewards',
    component: Rewards,
    meta: {
      requiresAuth: true,
      title: 'Rewards | CloudHashX',
      description: 'Access your rewards on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/rewards/exchange',
    name: 'Exchange',
    component: Exchange,
    meta: {
      requiresAuth: true,
      title: 'Exchange | CloudHashX',
      description: 'Access your exchange on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/balances/:currency',
    name: 'BalanceDetails',
    component: BalanceDetails,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Balance Details | CloudHashX',
      description: 'Access your balance on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/asics/:uuid',
    name: 'ContractDetail',
    component: ContractDetail, // Asegúrate de tener el componente para los detalles
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Contract Details | CloudHashX',
      description: 'Access your contract on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/marketplace',
    name: 'MarketPlace',
    component: MarketPlace,
    meta: {
      requiresAuth: true,
      title: 'MarketPlace | CloudHashX',
      description: 'Access our marketplace on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/yoerlin/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      title: 'Admin Dashboard | CloudHashX',
      description: 'Access your dashboard on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
      title: 'Notifications | CloudHashX',
      description: 'Access your notifications on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/settings/withdraw-addresses',
    name: 'WithdrawAddresses',
    component: WithdrawAddresses,
    meta: {
      requiresAuth: true,
      title: 'Settings | CloudHashX',
      description: 'Access your notifications on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    }
  },
  {
    path: '/my/settings/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      title: 'Settings | CloudHashX',
      description: 'Access your notifications on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    }
  },
  {
    path: '/my/balances',
    name: 'Wallets',
    component: Wallets,
    meta: {
      requiresAuth: true,
      title: 'Wallets | CloudHashX',
      description: 'Access your wallets on CloudHashX, the cloud bitcoin mining platform. Monitor your mining activities, earnings, and more.'
    },
  },
  {
    path: '/my/upgrade',
    name: 'Upgrade',
    component: Upgrade,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Upgrade Mining Power',
      description: 'Upgrade your mining power on CloudHashX, the cloud bitcoin mining platform. Increase your mining capabilities and earn more bitcoin.'
    },
  },
  {
    path: '/my/withdraw',
    name: 'Withdraw',
    component: NewWithdraw,
    meta: {
      requiresAuth: true,
      title: 'Withdraw | CloudHashX',
      description: 'Withdraw your earnings from CloudHashX, the cloud bitcoin mining platform. Easily convert your mined bitcoin to your preferred currency.'
    },
  },
  {
    path: '/my/partners',
    name: 'Partners',
    component: NewPartners,
    meta: {
      requiresAuth: true,
      title: 'Partners | CloudHashX',
      description: 'View your referrals and commissions on CloudHashX, the cloud bitcoin mining platform. Earn rewards for bringing new users to our platform.'
    },
  },
  {
    path: '/my/settings/security',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'Account Security | CloudHashX',
      description: 'Manage your account security settings on CloudHashX, the cloud bitcoin mining platform. Protect your account with two-factor authentication and more.'
    },
  },
  {
    path: '/my/settings/account',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Account Settings',
      description: 'Manage your account security settings on CloudHashX, the cloud bitcoin mining platform. Protect your account with two-factor authentication and more.'
    },
  },
  {
    path: '/my/settings/notification',
    name: 'Notification',
    component: Notification,
    meta: {
      requiresAuth: true,
      title: 'CloudHashX - Leading Cryptocurrency Platform for Mining | Account Notifications',
      description: 'Manage your account notification settings on CloudHashX, the cloud bitcoin mining platform. Stay informed about your mining activities and account updates.'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

/* router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['user/autenticado'];
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresTwoFactorAuth = store.getters['user/requiresTwoFactorAuth'];
  const guestOnly = ['login', 'register', 'forgot', 'reset-password'];

  // Caso 1: Usuario autenticado intenta acceder a una ruta de invitado
  if (isAuthenticated && guestOnly.includes(to.name)) {
    return next({ name: 'dashboard' });
  }

  // Caso 2: Ruta requiere autenticación pero el usuario no está autenticado
  if (requiresAuth && !isAuthenticated) {
    return next({ name: 'login' });
  }

  // Caso 3: Ruta requiere 2FA pero no ha sido completada
  if (requiresAuth && requiresTwoFactorAuth) {
    if (to.name === 'TwoFactorAuth') {
      return next(); // Permitir acceso a la página 2FA
    }
    return next({ name: 'TwoFactorAuth' }); // Redirigir a 2FA si no se ha completado
  }

  // Caso 4: Navegación permitida
  next();
}); */




router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.description) {
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
  }

  const isAuthenticated = store.getters['user/autenticado']; // Verifica si está autenticado
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth); // Rutas que requieren autenticación
  const requiresTwoFactorAuth = store.getters['user/requiresTwoFactorAuth']; // Estado de 2FA
  const guestOnly = ['Login', 'Register', 'Forgot', 'reset-password']; // Rutas solo para invitados

  //console.log(isAuthenticated, 'router')
  // Caso 1: Usuario autenticado intenta acceder a una ruta de invitado
  if (isAuthenticated && guestOnly.includes(to.name)) {
    return next({ name: 'Dashboard' });
  }

  // Caso 2: Ruta requiere autenticación pero el usuario no está autenticado
  if (requiresAuth && !isAuthenticated) {
    return next({ name: 'Login' });
  }

  // Caso 3: Ruta requiere 2FA pero no ha sido completada
  if (requiresAuth && requiresTwoFactorAuth && to.name !== 'TwoFactorAuth') {
    return next({ name: 'TwoFactorAuth' });
  }

  // Caso 4: Navegación permitida
  next();
});


export default router;
