<template>
  <div>
    

    <div class="section m-0 py-2">

        

        <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showError }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

      <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToastSc ,'text-bg-success': showSuccess }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <p class="text-center">{{message}}</p>
              </div>
              <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>


        <div class="container">
            <div class="row justify-content-center">

              <div class="col-md-6" v-if="isForgot">
                <div class="text-center mb-5">
                    <h2 class="fw-bold mb-2 forgot">{{ $t('forgotPassword') }}</h2>
                    <p class="fw-normal mb-2 lead">{{ $t('forgotPasswordDescription') }}</p>
                </div>
                <div class="card bg-white shadow-lg border-0" >
                  <div class="card-body">
                    <form @submit.prevent="sendResetLink">

                      <div class="field-wrap input- mb24">
                        <div class="input-group2 auth">
                          <div class="input-group-prepend">
                            <i class="fa fa-user"></i>
                          </div>
                          <input autocomplete="off" type="text" @focus="isEmailFocused = true" @blur="isEmailFocused = false" name="email" :class="{ 'up': isEmailFocused || formData.email  }" class="form-control" v-model="formData.email" placeholder="" maxlength="">
                          <span class="floating-placeholder prepend" :class="{ 'up': isEmailFocused || formData.email  }">{{ $t('email') }}</span>
                        </div>
                      </div>
                      <div class="col-12 mt-3 d-grid gap-2 d-md-flex justify-content-md-end mt-3">
                        <router-link to="/login" class="link fw-bold text-decoration-none text-primary">{{$t('backToLoginBtn')}}</router-link>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn w-100 text-white btn-primary rounded-3 py-3 fw-semibold text-uppercase mt-2" :disabled="isSubmitting">
                          <span v-if="!isSubmitting">{{ $t('submit') }}</span>
                          <span v-else>
                                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                {{ $t('loggingIn') }}...
                            </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              

              




            </div>
          </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CloudhashFrontendForgot',
  components: {  },
  data() {
    return {
        isEmailFocused: false,
        isForgot: true,
        isSecurityVerified: false,
        isPasswordChanged: false,
        securityMnemonic: '',
        formData: {
          email: ''
        },
        country: '',
        ip: '',
        isSubmitting: false,
        message: '',
        showToast: false,
        showError: false,
        showSuccess: false,
        showToastSc: false,
        newPassword: '',
        confirmPassword: '',
    };
  },

  mounted() {
    this.getIpaddress();
    this.getUserLocation();
  },

  methods: {
    closeToastSc() {
      this.showToastSc = false;
      this.showSuccess = false;
    },
    closeToast() {
      this.showToast = false;
      this.showError = false;
    },
    getIpaddress(){
      axios.get('https://ipinfo.io')
      .then((res)=>{
        //console.log(res.data);
        this.ip = res.data.ip;
      })
      .catch((error)=>{
        console.log(error)
      })
    },
    async getUserLocation() {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        //this.ip = data.ip;
        this.country = data.country_name;
      } catch (error) {
        console.error("Could not retrieve location data:", error);
      }
    },
    sendResetLink() {
      this.isSubmitting = true;
      this.showToast = false;
      

      axios.post(process.env.VUE_APP_API_URL + 'password/email', {
        email: this.formData.email,
        country: this.country,
        ip: this.ip
      })
      .then(response => {
        this.isSubmitting = false;
        this.showToastSc = true;
        this.showSuccess = true;
        this.message = response.data.message;
        this.formData.email = '';
        setTimeout(()=>{
              this.showToastSc = false;
              this.showSuccess = false;
              this.message = '';
            },5000);
        //window.scrollTo(0, 0); // Desplaza el scroll a la posición (0, 0)
      })
      .catch(error => {
        this.isSubmitting = false;
        this.showToast = true;
        this.showError = true;
        if (error.response && error.response.data && error.response.data.email) {
          this.message = error.response.data.email[0];
        } else {
          this.message = error.response.data.error;
        }
        setTimeout(()=>{
              this.showToast = false;
              this.showError = false;
              this.message = '';
            },5000);
       // window.scrollTo(0, 0); // Desplaza el scroll a la posición (0, 0)
      });
    }
  },
};
</script>
<style scoped>
.py-2 {
    padding-top: 120px !important;
}

.mb24 {
    margin-bottom: 24px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 3px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.auth .input-group-prepend~input, .input-group.medium .input-group-prepend~input {
    padding-left: 56px;
}

.input-group2.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group2.auth span.floating-placeholder, .input-group.medium span.floating-placeholder {
    position: absolute;
    pointer-events: none;
    left: 19px;
    top: 19px;
    transition: .2s ease all;
    color: #cecdcd;
    font-size: 14px;
}

.input-group2.auth span.floating-placeholder.prepend,.input-group.medium span.floating-placeholder.prepend {
    left: 56px!important
}

.input-group2.auth span.floating-placeholder.up,.input-group.medium span.floating-placeholder.up {
    top: 11px;
    left: 15px;
    font-size: 10px;
    color: #959595
}

.input-group2.auth input.up,.input-group.medium input.up {
    padding-top: 25px;
    padding-bottom: 11px
}

/* .up {
    position: absolute;
    color: #cecdcd
} */

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.forgot {
  font-size: 24px;
    font-weight: 700;  
    font-family: Lora, serif;
    font-style: italic;
}
</style>