<template>
  <div>
    <div class="section m-0 py-2">

        <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-danger': showToast }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                <template v-if="errors.length">
                    <ul>
                      <template v-for="(fieldErrors, field) in errors">
                        <li v-for="error in fieldErrors" :key="error">{{ field }}: {{ error }}</li>
                      </template>
                    </ul>
                </template>
                <template v-else>
                  <p>{{message}}</p>
                </template>
              </div>
              <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
       </div>


       <div class="toast-container position-fixed top-50 start-50 translate-middle">
          <div class="toast align-items-center border-0 fade" :class="{'show': showSuccess ,'text-bg-success': showSuccess }" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
              <div class="toast-body">
                  <p>{{message}}</p>
              </div>
              <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
      </div>

      <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="text-center mb-5">
                    <h2 class="fw-bold mb-2 register">{{ $t('resetPasswordTl') }}</h2>
                </div>

                <div class="card bg-white shadow-lg border-0" >
                    <div class="card-body">
                        <form @submit.prevent="resetPassword">
                            <input type="hidden" v-model="formData.token" />
                            <input type="hidden" v-model="formData.email" />


                            <div class="field-wrap input- mb24">
                    <div class="input-group2 auth">
                      <div class="input-group-prepend">
                        <i class="fa fa-lock"></i>
                      </div>
                      <input autocomplete="current-password" :type="showPassword ? 'text' : 'password'" 
                      @focus="isPasswordFocused = true" @blur="isPasswordFocused = false"
                      :class="{ 'up': isPasswordFocused || formData.password  }"
                      name="password" class="form-control"
                      v-model="formData.password" placeholder="" maxlength="">
                      <span class="floating-placeholder prepend" :class="{ 'up': isPasswordFocused || formData.password  }">{{ $t('newPassword') }}</span>
                      <div class="show-password" :class="showPassword ? 'show' : ''" @click="togglePasswordVisibility">
                        <span>{{!showPassword ? $t('showPw') : $t('hidePw')}}</span>
                        <i class="fa" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                      </div>
                    </div>
                  </div>

                  <div class="field-wrap input- mb24">
                    <div class="input-group2 auth">
                      <div class="input-group-prepend">
                        <i class="fa fa-lock"></i>
                      </div>
                      <input autocomplete="password-new" :type="showPassword2 ? 'text' : 'password'" 
                      @focus="isPasswordFocused2 = true" @blur="isPasswordFocused2 = false"
                      :class="{ 'up': isPasswordFocused2 || formData.password_confirmation  }"
                      name="password-new" class="form-control"
                      v-model="formData.password_confirmation" placeholder="" maxlength="">
                      <span class="floating-placeholder prepend" :class="{ 'up': isPasswordFocused2 || formData.password_confirmation  }">{{ $t('newPasswordAgain') }}</span>
                      <div class="show-password" :class="showPassword2 ? 'show' : ''" @click="togglePasswordVisibility2">
                        <span>{{!showPassword2 ? $t('showPw') : $t('hidePw')}}</span>
                        <i class="fa" :class="showPassword2 ? 'fa-eye-slash' : 'fa-eye'"></i>
                      </div>
                    </div>
                  </div>


                            <div class="col-12">
                                <button type="submit" class="btn w-100 text-white btn-primary rounded-3 py-3 fw-semibold text-uppercase mt-2" :disabled="isSubmitting">
                                    <span v-if="!isSubmitting">{{ $t('resetPasswordTl') }}</span>
                                    <span v-else>
                                        <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                        {{ $t('loggingIn') }}...
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'DocumentsResetPassword',

  data() {
    return {
        isPasswordFocused: false,
      isPasswordFocused2: false,
      showPassword: false,
      showPassword2: false,
      errors:{},
      showSuccess: false,
      showToast: false,
      message: '',
      isSubmitting: false,
      interval: null,
      formData: {
        token: this.$route.params.token,
        email: this.$route.params.email, // decode the base64 encoded email
        password: '',
        password_confirmation: ''
      },
    };
  },
  beforeDestroy() {
      clearInterval(this.interval);
  },
  mounted() {
    
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordVisibility2() {
      this.showPassword2 = !this.showPassword2;
    },
    closeToast(){
        this.showToast = false;
        this.showSuccess = false;
    },
    resetPassword() {
        // Evita el envío si el formulario ya se está enviando
        if (this.isSubmitting) return;

        // Inicia la animación del botón y deshabilítalo
        this.isSubmitting = true;
        this.errors = {};

        if(this.formData.password !== this.formData.password_confirmation){
            this.message = 'Passwords do not match.';
            this.showToast = true;
            this.isSubmitting = false;
            this.interval = setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
            return;
        }

        axios.post(process.env.VUE_APP_API_URL+'password/reset', this.formData)
        .then(response => {
            this.message = response.data.message;
            this.showSuccess = true;
            this.formData.password = '';
            this.formData.password_confirmation = '';

            this.interval = setTimeout(()=>{
              this.showSuccess = false;
              this.message = '';
              this.$router.push('/login');
            },5000);
            this.isSubmitting = false;
        })
        .catch(error => {
            this.isSubmitting = false;
            this.showToast = true;
            //console.log(error.response)
          if (error.response && error.response.data && error.response.data.errors.email) {
            this.message = error.response.data.errors.email[0];
            this.interval = setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          } else if(error.response && error.response.data && error.response.data.errors.password) {
            this.message = error.response.data.errors.password[0] || 'An error occurred. Please try again.';
            this.interval = setTimeout(()=>{
              this.showToast = false;
              this.message = '';
            },5000);
          }
          
        });
    },
  },
};
</script>
<style scoped>
.py-2 {
    padding-top: 120px !important;
}

.btn-alternate {
  background-color: transparent;
  color: #3883dd; /* Color azul */
  border: none;
  font-weight: bold;
  transition: color 0.3s;
}

.btn-alternate:hover {
  color: #007bff; /* Color azul más oscuro al pasar el mouse */
}

.pointer {
  cursor: pointer
}

.mb24 {
    margin-bottom: 24px;
}

.input-group2 {
    position: relative;
}

.input-group2.auth .input-group-prepend, .input-group.medium .input-group-prepend {
    line-height: 54px;
    height: 53px;
}

.input-group2 .input-group-prepend {
    position: absolute;
    top: 3px;
    min-width: 44px;
    border-right: 1px solid #EEE;
    z-index: 1;
    text-align: center;
    line-height: 38px;
    height: 37px;
    color: #cecdcd;
}

.input-group2.auth .input-group-prepend~input, .input-group.medium .input-group-prepend~input {
    padding-left: 56px;
}

.input-group2.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group2.auth span.floating-placeholder, .input-group.medium span.floating-placeholder {
    position: absolute;
    pointer-events: none;
    left: 19px;
    top: 19px;
    transition: .2s ease all;
    color: #cecdcd;
    font-size: 14px;
}

.input-group2.auth span.floating-placeholder.prepend,.input-group.medium span.floating-placeholder.prepend {
    left: 56px!important
}

.input-group2.auth span.floating-placeholder.up,.input-group.medium span.floating-placeholder.up {
    top: 11px;
    left: 15px;
    font-size: 10px;
    color: #959595
}

.input-group2.auth input.up,.input-group.medium input.up {
    padding-top: 25px;
    padding-bottom: 11px
}

.input-group2 input:focus {
    outline: none;
    border: 1px solid #19345D;
    box-shadow: 0 5px 10px #19345D1a
}

.input-group2.auth .show-password, .input-group.medium .show-password {
    line-height: 54px;
}

.input-group2.auth .show-password {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    z-index: 1;
    color: #19345D;
    cursor: pointer;
}

.input-group2.auth .show-password span {
    margin-right: 7px;
    font-size: 12px;
}

.input-group-text {
    background-color: transparent;
}

.toast-body ul {
    list-style-type: none; /* Quita el marcador de lista */
    padding: 0; /* Elimina el relleno predeterminado de la lista */
}

.mb24 {
    margin-bottom: 24px;
}

.requirements {
    padding: 0 15px;
}

.text-muted2, .text-disabled {
    color: #cecdcd;
}

small {
    font-size: 12px;
    line-height: 1.42;
}

.text-success {
    color: #68b641;
}

.input-group2 .show-password.show {
    color: #cecdcd;
    right: 20px
}

.register {
  font-size: 24px;
    font-weight: 700;  
    font-family: Lora, serif;
    font-style: italic;
}
</style>