<template>
  <div>
      

      <section id="slider" class="slider-element py-2">
        

        
                
                  <div class="container">

                    <div class="row mt-3">
                      <div class="col-lg-3">
                    
                    <!-- <div class="wallet-container"> -->

                      <div class="sidebar shadow">
                        <!-- <h3 class="mb16 text-dark">{{ $t('insights') }}</h3> -->
                        <div class="box">
                          <div class="total">
                            <small class="text-muted">{{ $t('totalCustomers')}} </small>
                              <h1 class="pt8 pb16 balance">
                                  {{userCount}}
                              </h1>
                          </div>
                          <div class="total">
                            <small class="text-muted">{{$t('totalMinedBitcoins')}} </small>
                            <h1 class="pt8 pb16 balance">{{totalMinedBitcoins.toFixed(2)}} {{currency}}</h1>
                            
                          </div>
                          <div class="available">
                            <small class="text-muted">{{$t('customersPayouts')}} </small>
                            <h1 class="pt8 pb16 balance">{{totalWithdrawals.toFixed(2)}} {{currency}}</h1>
                          </div>
                        </div>
                      </div>
                    </div>    

                    <div class="col-lg-9">
                      
                          <div class="content">
                            <h3 class="mb16 text-dark withdraws"> {{$t('recentCustomersWithdrawals')}}</h3>
                           <!--  <div class="table-wrap relative shadow">
                              <div class="table-responsive table-container" v-if="transactions.length > 0">
                                  <table class="table-striped nh padding-normal" >
                                      <thead>
                                          <tr>
                                              <th scope="col">{{$t('transaction')}}</th>
                                              <th scope="col" class="text-right">{{$t('amount')}}</th>
                                              <th scope="col" class="text-right">{{$t('username')}}</th>
                                              <th scope="col" class="text-right">{{$t('dateTime')}}</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(transaction, index) in transactions" :key="index">
                                              <td>
                                                  <template v-if="transaction.coin_type === 'BTC'">
                                                    <a :href="'https://blockchair.com/es/bitcoin/transaction/' + transaction.transaction_id" target="_blank">
                                                        {{ transaction.transaction_id.slice(0, 20) }}...
                                                    </a>
                                                  </template>
                                                  <template v-else-if="transaction.coin_type === 'BCH'">
                                                    <a :href="'https://blockchair.com/es/bitcoin-cash/transaction/' + transaction.transaction_id" target="_blank">
                                                        {{ transaction.transaction_id.slice(0, 20) }}...
                                                    </a>
                                                  </template>
                                                  <template v-else-if="transaction.coin_type === 'LTC'">
                                                    <a :href="'https://blockchair.com/es/litecoin/transaction/' + transaction.transaction_id" target="_blank">
                                                        {{ transaction.transaction_id.slice(0, 20) }}...
                                                    </a>
                                                  </template>
                                                  <template v-else-if="transaction.coin_type === 'DOGE'">
                                                    <a :href="'https://blockchair.com/es/dogecoin/transaction/' + transaction.transaction_id" target="_blank">
                                                        {{ transaction.transaction_id.slice(0, 20) }}...
                                                    </a>
                                                  </template>
                                              </td>
                                              <td class="text-right">{{ transaction.usd }} <span class="text-muted"> {{currency}}</span></td>
                                              <td class="text-right">{{ transaction.username }}</td>
                                              <td class="text-right">{{ formatDate(transaction.created_at) }}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>

<div v-else-if="!isLoading && transactions.length === 0" class="panel d-flex justify-content-center mt-4">
  <p class="text-dark">{{$t('noData')}}</p>
</div>

<div class="overlay loader" v-if="isLoading">
<span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
</div>

                            </div> -->

                            <div class="flex flex--column confirmed-blocks-list">

                              <!-- <div class="mb16 col-xs-12 padding0 block" >
                                  <div class="block-item flex flex--align-vertical flex--justify-space-between">
                                    <div class="bgImg" style="--img: url(&quot;https://www.nicehash.com/static/currencies/bch.png&quot;);"></div>
                                    <div class="reward">
                                        <div class="flex flex--align-vertical mb4">
                                          <div class="flex flex--align-vertical flex--no-wrap">
                                              <div  class="flex flex--align-vertical flex--no-wrap" style="--4b6f6cda: 16px; --672cb9a4: 50%; --22de310e: -6px; --456f9468: 12px;">
                                                <img  src="https://www.nicehash.com/static/currencies/bch.png" alt="BCH" class="icon">
                                              </div>
                                          </div>
                                          <div class="package-name ml8">Silver M</div>
                                        </div>
                                        <div class="">
                                          <h2 ><span >≈ </span><span >3.09456862</span> <small class="text-muted">BCH</small></h2>
                                          <h6 class="text-muted">≈ €1,405.49</h6>
                                        </div>
                                    </div>
                                    <div class="time flex flex--column flex--align-bottom">
                                        <h6 class="text-muted">Block caught</h6>
                                        <h3 class="block-height text-color">#881364</h3>
                                        <h6 ><time datetime="Thu Jan 16 2025 19:40:17 GMT-0500" title="16 January 2025, 19:40">14 minutes ago</time></h6>
                                    </div>
                                  </div>
                              </div> -->

                              <template v-if="transactions.length > 0">
                              <div class="mb16 col-xs-12 padding0 block" 
                              v-for="(transaction, index) in transactions" :key="index"
                              @click="openTransaction(transaction.coin_type, transaction.transaction_id)"
                              >
                                  <div class="block-item flex flex--align-vertical flex--justify-space-between">
                                    <!-- Icono de la moneda -->
                                    <div class="bgImg" :style="{ '--img': `url(${getCoinImage(transaction.coin_type)})` }"></div>
                                    
                                    <!-- Información de la transacción -->
                                    <div class="reward">
                                      <div class="flex flex--align-vertical mb4">
                                        <div class="flex flex--align-vertical flex--no-wrap" style="--4b6f6cda: 16px; --672cb9a4: 50%; --22de310e: -6px; --456f9468: 12px;">
                                          <img :src="getCoinImage(transaction.coin_type)" :alt="transaction.coin_type" class="icon">
                                        </div>
                                        <div class="package-name ml8">{{ transaction.username }}</div>
                                      </div>
                                      
                                      <!-- Monto en cripto y en fiat -->
                                      <!-- <div>
                                        <h2>
                                          <span>≈ </span>
                                          <span>{{ transaction.amount }}</span>
                                          <small class="text-muted">{{ transaction.coin_type }}</small>
                                        </h2>
                                        <h6 class="text-muted">≈ {{ transaction.usd }} {{ currency }}</h6>
                                      </div> -->
                                      <div class="">
                                          <h2 ><span >≈ </span><span >{{ transaction.amount }}</span> <small class="text-muted">{{ transaction.coin_type }}</small></h2>
                                          <h6 class="text-muted">≈ {{ transaction.usd }} {{ currency }}</h6>
                                        </div>
                                    </div>
                                    
                                    <!-- Fecha y transacción -->
                                    <div class="time flex flex--column flex--align-bottom">
                                      <h6 class="text-muted">{{$t('transaction')}}</h6>
                                      <h3 class="block-height text-color">
                                          {{ transaction.transaction_id.slice(0, 8) }}
                                      </h3>
                                      <h6><time :datetime="transaction.created_at">{{ formatDate(transaction.created_at) }}</time></h6>
                                    </div>
                                  </div>
                                </div>

                              </template>

                                <div v-else-if="!isLoading && transactions.length === 0" class="panel d-flex justify-content-center mt-4">
                                  <p class="text-dark">{{$t('noData')}}</p>
                                </div>

                                <div class="overlay loader" v-if="isLoading">
                                  <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                                </div>

                              

                              

                              
  

   
   
   
   

   
</div>
                            
                          </div>
                      </div>
                    </div>
                  
            
                </div>
                   
            
    </section>

    <Footer/>
    
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Footer from './Footer.vue';
import ICountUp from 'vue-countup-v2';
export default {
  name: 'CloudhashFrontendInsights',
  components: { Footer, ICountUp },
  data() {
    return {
      currency: 'USD',
      isLoading: true,
      showSkeleton: true,
      bgImg: {
            backgroundImage: `url(${require('@/assets/images/1.svg')})`
        },
       transactions:[],
       bitcoinValue: 0,
       totalMinedBitcoins: 0,
       totalMinedBitcoinsOriginal: 0,
       userCount: 0,
       totalMiningPower: 0,
       totalWithdrawalsOriginal: 0,
       totalWithdrawals: 0,
       interval: null,
       firstLoad: true,
       previousUserCount: 0,
       delay: 0,
       options: {
          separator: '',
          decimal: '.',
          prefix: '',
          suffix: ''
      }
    };
  },
  mounted() {
   // this.getBtcPrice();
    this.insightsData();
  },
  beforeMount() {
    this.interval = setInterval(this.insightsData, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    openTransaction(coinType, transactionId) {
      const baseUrls = {
        'BTC': 'https://blockchair.com/bitcoin/transaction/',
        'BCH': 'https://blockchair.com/bitcoin-cash/transaction/',
        'LTC': 'https://blockchair.com/litecoin/transaction/',
        'DOGE': 'https://blockchair.com/dogecoin/transaction/',
      };
      const url = baseUrls[coinType] ? baseUrls[coinType] + transactionId : '#';
      window.open(url, '_blank');
    },
    getCoinImage(cryptoName) {
      const icons = {
        BTC: require("@/assets/images/currencies/btc.png"),
        BCH: require("@/assets/images/currencies/bch.png"),
        LTC: require("@/assets/images/currencies/ltc.png"),
        DOGE: require("@/assets/images/currencies/doge.png"),
      };
      return icons[cryptoName] || "@/assets/images/currency/default-logo.svg"; // Logo por defecto
    },
    insightsData() {
      axios.get(process.env.VUE_APP_API_URL+'insightsData')
      .then((res)=>{
        const newUserCount = res.data.userCount;
        this.previousUserCount = this.userCount;
        this.userCount = newUserCount;
        this.totalMinedBitcoins = res.data.totalMinedCoins;
        //this.totalMinedBitcoinsOriginal = res.data.totalMinedBitcoins;
        this.totalWithdrawals = res.data.totalWithdrawals;
        //this.totalWithdrawalsOriginal = res.data.totalWithdrawals;
        this.transactions = res.data.lastWithdrawals;
        if (this.firstLoad) {
            this.firstLoad = false; // Desactiva la bandera después de la primera carga
        }
        
      })
      .catch((error)=>{
        console.log('error getting insightsData',error.response.error);
      })
      .finally(() => {
          this.showSkeleton = false; // Siempre oculta el efecto de carga al finalizar la llamada
          this.isLoading = false;
      });
    },
    onReady(instance, CountUp) {
      if (this.previousUserCount !== this.userCount) {
        instance.update(this.userCount);
      }
    },
    formatDate(date){
      return moment.utc(date).local().fromNow();
      // moment(date).fromNow();
    },
  },
};
</script>
<style scope>
.icon {
    width: var(--4b6f6cda);
    height: var(--4b6f6cda);
    border-radius: var(--672cb9a4);
}


.block {
    display: block;
}

.block-item {
    border-radius: 12px;
    box-shadow: 0 0 6px #2323231a;
    background-color: #fff;
    width: 100%;
    padding: 12px 16px 10px;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.confirmed-blocks-list {
    position: relative
}

@media only screen and (max-width : 1439px) {
    .confirmed-blocks-list {
        display:grid !important;
        grid-template-columns: repeat(3,minmax(0,1fr));
        -moz-column-gap: 16px;
        column-gap: 16px
    }
}

@media only screen and (max-width : 991px) {
    .confirmed-blocks-list {
        grid-template-columns:repeat(2,minmax(0,1fr))
    }
}

@media only screen and (max-width : 480px) {
    .confirmed-blocks-list {
        grid-template-columns:minmax(0,1fr);
        -moz-column-gap: 0;
        column-gap: 0
    }
}

.block-item .bgImg {
    position: absolute;
    bottom: -16px;
    right: -9px;
    background-image: linear-gradient(360deg,#fffffff2,#fffc),var(--img);
    background-repeat: no-repeat;
    background-size: 71px;
    background-clip: border-box;
    width: 71px;
    height: 71px;
    z-index: -1;
    content: "";
}

.block-item .reward {
    flex: 1;
}

.block-item h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.block-item h2 small {
    font-family: Lora,sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: .02em;
    text-align: left;
}

.block-item h6 {
    font-family: Lora,sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: .02em;
    text-align: left;
    margin: 0;
}

.block-item .time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    letter-spacing: .02em;
    color: #959595;
}

.flex--align-bottom {
    align-items: flex-end;
}

.block-item h3 {
    font-family: Open Sans,sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 21.79px;
}


.ml-3 {
  margin-left:15px
}

.br-table {
  border-radius: 0.375rem;
}

.mb-2 {
  margin-bottom: 3px
}

.py-2 {
    padding-top: 96px !important;
}

.feature-box {
  background-color: var(--bs-body-bg);
  border-radius: 4px;
  flex: 1 1 auto;
    padding: 1rem 1rem;
    color: var(--bs-card-color);
}

.active {
    background-color: #FF4D6B;
    color: white;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.text-muted {
    color: #959595 !important;
}

.text-right {
    text-align: right;
}

.wallet-container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

.sidebar {
    width: 232px;
}

.box {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    margin-bottom: 16px;
    background: #fff;
}

.box .total {
    padding: 24px 16px 0;
    border-bottom: 1px solid #EEE;
    position: relative;
}

.box .available {
    padding: 24px 16px 12px;
    position: relative;
}

.box i.info {
    position: absolute;
    top: 12px;
    right: 12px;
}

.box .total h1 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.box .available h1 {
  font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.pb16 {
    padding-bottom: 16px;
}

.pt8 {
    padding-top: 8px;
}

.pointer {
    cursor: pointer;
}

.text-primary {
    color: rgb(25, 52, 93) !important;
}

.content {
    flex: 1;
    margin-left: 0px;
}

.box .pending {
    padding: 24px 16px 18px;
    position: relative;
}

.text-bold {
    font-weight: 700;
}

.fs16 {
    font-size: 16px;
}

.mb8 {
    margin-bottom: 8px;
}

.mt8 {
    margin-top: 8px;
}

.box .available .deposit-withdraw {
    margin: 16px 0;
}

.box .available .deposit-withdraw .btn {
    padding: 6px!important;
    width: calc(50% - 4px);
    margin: 0;
}

.mb16 {
    margin-bottom: 18px;
}

.content {
    background: none !important;
    flex: 1 1 0%;
    border-radius: 4px;
}

.fs16 {
  font-size: 16px;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}

.withdraws {
    font-size: 24px;
    font-weight: 700;  
    font-family: Lora, serif;
    font-style: italic;
}

</style>
