<template>
  <div data-v-63b81b20="" class="sidebar">
   <div data-v-63b81b20="" class="panel">
        <h2 data-v-63b81b20="">{{$t('rewardsPage.menuTl')}}</h2>
        <router-link data-v-63b81b20="" to="/my/rewards" class="" exact="">{{$t('rewardsPage.menuTl')}}</router-link>
        <router-link data-v-63b81b20="" to="/my/rewards/exchange" class="" exact="">{{$t('rewardsPage.exchange')}}</router-link>
   </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentsSidebarRewards',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>
.mb32 {
    margin-bottom: 32px;
}

/* .sidebar {
    width: 232px
} */

.sidebar {
  width: 250px;
}

.panel {
  background-color: #fff;
  border-radius: 10px;
}

.sidebar-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

@media only screen and (max-width : 991px) {
    .sidebar {
        flex-basis:100%
    }
}

.sidebar .panel {
    padding-bottom: 16px;
}

/* .panel {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
} */

/* .panel {
    box-shadow: 0 1px 4px #23232329;
    background: #fff;
    border-radius: 4px;
} */

.sidebar h2 {
    height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #EEE;
    padding: 0 32px;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar a {
    padding: 16px 32px 16px 30px;
    display: block;
    color: #959595;
    border-left: 2px solid transparent;
}

.sidebar a:hover {
    border-left: 2px solid rgb(25, 52, 93)
}

.sidebar a.router-link-active {
    color: rgb(25, 52, 93);
    font-weight: 700;
    border-left: 2px solid rgb(25, 52, 93);
    padding-left: 40px
}

.panel.big-shadow {
    box-shadow: 0 8px 16px #23232329
}

.content {
    /* box-shadow: 0 1px 4px #23232329; */
    background: #fff;
    flex: 1;
    border-radius: 4px
}

@media only screen and (max-width : 991px) {
    .content {
        flex-basis:100%;
        max-width: calc(100vw - 32px)
    }
}

.content .panel {
    padding: 32px;
    box-shadow: none
}

@media only screen and (max-width : 768px) {
    .content .panel {
        padding:24px
    }
}

.content .panel .table {
    margin-left: -32px;
    width: calc(100% + 64px)
}

@media only screen and (max-width : 768px) {
    .content .panel .table {
        margin-left:-24px;
        width: calc(100% + 48px)
    }
}

@media only screen and (max-width : 991px) {
    .sidebar[data-v-63b81b20] {
        flex-basis:100%
    }
}

</style>