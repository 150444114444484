<template>
  <div>
    <section id="slider" class="slider-element dark min-vh-25 min-vh-md-50 include-header py-2">
        

        <div class="slider-inner">
            <div class="vertical-middle text-center">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-6 col-md-6">
                            <h2 class="fw-bold text-dark mb-2 privacy">{{$t('privacyPolicy')}}</h2>
                            <p class="lead mb-0 fw-normal text-dark">{{$t('privacyPolicySubtitle')}}</p>
                            <div class="privacy-policy mt-4">
                                <p class="text-dark">{{$t('privacyPMain')}}</p>
                                <p class="text-dark">{{$t('privacyP2')}}</p>
                                <p class="text-dark">{{$t('privacyP3')}}</p>
                                <ul>
                                    <li class="text-dark">{{ $t('email') }}</li>
                                    <li class="text-dark">{{ $t('username') }}</li>
                                    <li class="text-dark">{{$t('privacyPassword')}}</li>
                                </ul>
                                <p class="text-dark">{{$t('privacyP4')}}</p>
                                <p class="text-dark">{{$t('privacyP5')}}</p>
                                <h4 class="text-dark">{{$t('privacyTl')}}</h4>
                                <p class="text-dark">{{$t('privacyP6')}}</p>
                                <p class="text-dark">{{$t('privacyP7')}}</p>
                                <p class="text-dark">{{$t('privacyP8')}}</p>
                                <p class="text-dark">{{$t('privacyP9')}}</p>
                                <ul>
                                    <li class="text-dark">{{ $t('privacyLst1') }}</li>
                                    <li class="text-dark">{{ $t('privacyLst2') }}</li>
                                    <li class="text-dark">{{$t('privacyLst3')}}</li>
                                    <li class="text-dark">{{$t('privacyLst4')}}</li>
                                </ul>
                                <h4 class="text-dark">{{$t('privacyTl2')}}</h4>
                                <p class="text-dark">{{$t('privacyP10')}}</p>
                                <p class="text-dark">{{$t('privacyP11')}}</p>
                                <p class="text-dark">{{$t('privacyP12')}}</p>
                                <p class="text-dark">{{$t('privacyP13')}}</p>
                                <p class="text-dark">{{$t('privacyP14')}}</p>
                                <p class="text-dark">{{$t('privacyP15')}}</p>
                                <p class="text-dark">{{$t('privacyP16')}}</p>
                            </div>
                        </div>
                    </div><!-- row -->
                </div>
            </div>
        </div>


    </section>

    <Footer/>
  </div>
</template>

<script>
import BitcoinPrice from '../components/BitcoinPrice.vue';
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendPrivacy',
  components: { BitcoinPrice, Footer },
  data() {
    return {
        bgImg: {
            backgroundImage: `url(${require('@/assets/images/1.svg')})`
        },
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>

.py-2 {
    padding-top: 120px !important;
}

.privacy-policy {
  text-align: left; /* Alinea el texto a la izquierda */
  margin-top: 30px; /* Espacio superior */
  padding: 20px; /* Espaciado interior */
  flex-grow: 1;
  /* background-color: #f8f9fa; */ /* Color de fondo */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra */
}

.privacy-policy p {
  margin-bottom: 30px; /* Espacio entre párrafos */
}

.privacy-policy li {
    position: relative;
    padding-left: 20px;
}

.privacy-policy li:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: black;
    margin-top: -5px;
}

.privacy-policy ul {
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0;
}

.privacy {
    font-size: 24px;
    font-weight: 700;  
    font-family: Lora, serif;
    font-style: italic;
}
</style>