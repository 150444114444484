<template>
  <div>
    <!-- <BitcoinPrice key="bitcoin-price" /> -->

    <section id="slider" class="slider-element dark min-vh-25 min-vh-md-50 include-header py-2" >
        
        
        <div class="slider-inner">
          <div class="vertical-middle text-center">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-6">
                      <h2 class="fw-bold text-dark mb-2 terms">{{$t('termsOfUse')}}</h2>
                      <p class="lead mb-0 fw-normal text-dark">{{$t('termsOfUseSubtitle')}}</p>
                        <div class="terms-of-use shadow mt-4">
                            <p class="text-dark">{{$t('termsp1')}}</p>
                            <p class="text-dark">{{ $t('termsp2') }}</p>
                            <p class="text-dark">{{ $t('termsp3') }}</p>
                            <p class="text-dark">{{ $t('termsp4') }}</p>
                            <p class="text-dark">{{ $t('termsp5') }}</p>
                            <p class="text-dark">{{ $t('termsp6') }}</p>

                            <h4 class="mt-3 text-dark">{{ $t('termsTl1') }}</h4>
                            <p class="company-description text-dark">{{ $t('termsEx1') }}</p>
                            <h4 class="mt-3 text-dark">{{ $t('termsTl2') }}</h4>

                                <h5 class="text-dark">{{ $t('termsTl21') }}</h5>
                                <p class="text-dark">{{ $t('terms21p') }}</p>

                                <h5 class="text-dark">{{ $t('termsTl22') }}</h5>
                                <p class="text-dark">
                                    {{ $t('terms22p') }}
                                </p>

                                <h5 class="text-dark">{{ $t('termsTl23') }}</h5>
                                <p class="text-dark">
                                    {{ $t('terms23p') }}
                                </p>

                                <h5 class="text-dark">{{ $t('termsTl24') }}</h5>
                                <p class="text-dark">
                                    {{ $t('terms24p') }}
                                </p>

                                <h5 class="text-dark">{{ $t('termsTl25') }}</h5>
                                <p class="text-dark">
                                    {{ $t('terms25p') }}
                                </p>

                                <h5 class="text-dark">{{ $t('termsTl26') }}</h5>
                                <p class="text-dark">
                                    {{ $t('terms26p') }}
                                </p>

                                <h5 class="text-dark">{{ $t('termsTl27') }}</h5>
                                <p class="text-dark">
                                    {{ $t('terms27p') }} 
                                </p>

                                <h5 class="text-dark">{{ $t('termsTl28') }}</h5>
                                <p class="text-dark">
                                    {{ $t('terms28p') }} 
                                </p>
                        </div>
                        
                    </div>
                </div>
            </div>
          </div>
        </div>


    </section>

    <Footer/>
  </div>
</template>

<script>
import BitcoinPrice from '../components/BitcoinPrice.vue';
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendTerms',
  components: { BitcoinPrice, Footer },
  data() {
    return {
        bgImg: {
            backgroundImage: `url(${require('@/assets/images/1.svg')})`
        },
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>
.py-2 {
    padding-top: 120px !important;
}

.company-highlight {
  color: #0069d9; /* Color azul */
  font-weight: bold; /* Negrita */
}
.terms-of-use {
  text-align: left; /* Alinea el texto a la izquierda */
  margin-top: 30px; /* Espacio superior */
  padding: 20px; /* Espaciado interior */
  /* background-color: #f8f9fa; */ /* Color de fondo */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra */
}

.terms-of-use p {
  margin-bottom: 30px; /* Espacio entre párrafos */
}

.terms {
  font-size: 24px;
    font-weight: 700;  
    font-family: Lora, serif;
    font-style: italic;
}
</style>