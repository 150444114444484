<template>
  <div id="app_holder">


    <!-- deposit modal -->

    <div class="modal-deposit-bg" 
    :class="showDepositModal ? 'show' : ''" 
    >
    <span>
        <div role="dialog" class="modal-deposit size1"
        :class="showDepositModal ? 'show' : ''"
        >
            <div>
                <div class="modal-head">
                    <div class="title">{{$t('depositSection.title')}}</div>
                    <i class="fa fa-times pointer close" aria-label="Close" @click="closeDepositModal"></i>
                </div>

                <div class="modal-content">
                    <div class="field-wrap input-undefined">
                        <div class="label-row">
                            <label>
                                {{$t('depositSection.depositIn')}}
                            </label>
                        </div>
                        <div class="selectbox large deposit-to" :class="{ open: isDropdownOpen }" tabindex="0">
                            <div class="field" @click="toggleDropdown" aria-haspopup="true" :aria-expanded="isDropdownOpen ? true :false">
                                <div class="arrows">
                                    <i class="fa fa-caret-up"></i><i class="fa fa-caret-down"></i>
                                </div>
                                <span>
                                    <img :src="getCurrencyImage(selectedCurrency)">
                                    <div class="name">{{ selectedCurrency.label }}</div>
                                </span>
                            </div>
                            <div class="dropdown flex flex--no-wrap">
                                <div class="options" role="listbox">
                                    <div class="option" 
                                            :class="selectedCurrency.value === currency.value ? 'selected' : ''" 
                                            role="option" 
                                            :aria-selected="selectedCurrency.value === currency.value ? true :false" 
                                            v-for="(currency, index) in supportedCurrencies" 
                                            :key="index"
                                            @click="changeCurrency2(currency)"
                                            >
                                        <img :src="getCurrencyImage(currency)">
                                        <div class="name">{{ currency.label }}</div>
                                    </div>
                                </div>
                            </div><!-- dropdown -->
                        </div><!-- selectbox -->
                    </div>

                    <div class="before" v-if="!showAddress">
                        <div>
                            <div class="sign"><i class="fa fa-exclamation"></i></div>
                            <div class="title">{{$t('depositSection.only')}} {{ selectedCurrency.label }} {{$t('depositSection.toThis')}}</div>
                            <div class="desc">{{$t('depositSection.disclaimer')}}</div>
                            <button class="btn primary medium fluid" @click="openAddress">{{$t('depositSection.show')}} {{ selectedCurrency.value }}</button>
                        </div>
                    </div>
                    <div class="before deposit-addr" v-else>
                        <div>
                            <!-- <div class="sign"><i class="fa fa-exclamation"></i></div> -->
                            <div class="title mt-3">{{$t('depositSection.only')}} {{ selectedCurrency.label }} {{$t('depositSection.toThis')}}</div>
                            <div class="desc">{{$t('depositSection.disclaimer')}}</div>
                        </div>
                        <div class="text-left mt-3">
                        <div class="input-group medium">
                                <span class="copy-feedback" 
                                :style="showMessage 
                                ? 
                                'display:block;' 
                                : 
                                'display:none'">
                                {{$t('copiedClipboard')}}
                                </span>
                                <input readonly="" v-on:focus="$event.target.select()" type="text" ref="myinput" class="copy-address" :value="addressDeposit" maxlength="">
                              <button @click="copyRef" class="btn primary small copy" type="button">
                                <span>{{$t('copyBtn')}}</span>
                              </button>
                        </div>

                        <div class="alert mb24 text-center alert-orange mt-3" v-html="$t('depositSection.minimum')"></div>
                        <p class="mt-3">{{$t('depositSection.minimumDisclaimer')}}</p>
                    </div>

                        <div class="qrcodeBig mt-3">
                            <qrcode-vue :value="addressDeposit" size="250" level="H"></qrcode-vue>
                            
                        </div>
                        <button class="btn primary medium fluid mt-3" @click="openAddress">{{$t('depositSection.hide')}} {{ selectedCurrency.value }}</button>

                    </div>

                </div><!-- modal-content -->
                <div class="spacer"></div>
            </div>
        </div>
    </span>

    </div>

    <!-- end deposit modal -->
  
  <div id="content">
    <Sidebar/>
    <div data-v-c30e999c="" data-v-cc4eb09c="" class="container">
   <div data-v-c30e999c="" class="wallet-container">
      <div data-v-c30e999c="" class="sidebar">
         <div data-v-c30e999c="">
            <!---->
            <h2 data-v-c30e999c="" class="mb16">{{$t('assets')}}</h2>
            
            <div data-v-d45d9c5b="" data-v-c30e999c="" loading="false">
               <div data-v-d45d9c5b="" class="box">
                  <div data-v-d45d9c5b="" class="total">
                     <small data-v-d45d9c5b="" class="text-muted">{{$t('walletSection.totalAssets')}} <span data-v-d45d9c5b="" class="text-primary">USD</span></small>
                     <h1 data-v-d45d9c5b="" class="pt8 pb16 balance">$ {{ isBalanceHidden ? "****" : totalFiatBalance(currencies) }}</h1>
                     <div class="deposit-withdraw">
                        <router-link to="/my/withdraw" class="btn secondary small narrow">{{$t('walletSection.withdraw')}}</router-link>
                        <button 
                        @click="openDepositModal"
                        class="btn primary success small narrow pull-right">{{$t('walletSection.deposit')}}</button>
                    </div>

                  </div>
                  <div data-v-d45d9c5b="" class="available">
                    <i data-v-02f89b65="" data-v-d45d9c5b="" v-tooltip="$t('balanceFor')" class="fa fa-info-circle info v-popper--has-tooltip"></i>
                     <small data-v-d45d9c5b="" class="text-muted text-uppercase">{{$t('walletSection.available')}}</small>
                     <div data-v-d45d9c5b="" class="text-bold mt8 mb8 fs16">$ {{ isBalanceHidden ? "****" : balance }}</div>
                     
                  </div>
               </div>
            </div>
         </div>
      </div>
      
      <div data-v-c30e999c="" class="content">
         <div data-v-c30e999c="">
            <div data-v-c30e999c="">
               <div data-v-c30e999c="" class="row mb16">
                  <div data-v-c30e999c="" class="col-nano-8">
                     <h2 data-v-c30e999c="">{{$t('youWallets')}}</h2>
                     <p class="balances">
                        {{ $t('balancesDescription') }}
                    </p>
                  </div>
               </div>
               <div data-v-e428484b="" data-v-c30e999c="" class="wallets">

                <!-- <div data-v-e428484b="" class="list compact-view" v-if="activeTab === 'compact'">



                    <div data-v-e428484b="" class="list">
   <div data-v-e428484b="" class="header">
      <div data-v-e428484b="" class="col-name pointer">
         name 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-pending pointer">
         Pending &amp; in orders 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-available pointer">
         Available balance 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-total pointer">
         Total 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-actions"></div>
   </div>
   <div data-v-e428484b="" class="wallet-row pointer">
      <div data-v-e428484b="" class="col-name">
         <img data-v-e428484b="" src="https://www.nicehash.com/static/currencies/btc.png">
         <div data-v-e428484b="" class="cholder">
            <div data-v-e428484b="" class="currency">BTC</div>
            <div data-v-e428484b="" class="name">Bitcoin</div>
         </div>
      </div>
      <div data-v-e428484b="" class="col-pending">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BTC</span></div>
         <div data-v-e428484b="" class="mt4 fs12 text-muted fiat">$0.00</div>
      </div>
      <div data-v-e428484b="" class="col-available">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BTC</span></div>
         <div data-v-e428484b="" class="mt4 fs12 text-muted fiat">$0.00</div>
      </div>
      <div data-v-e428484b="" class="col-total">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BTC</span></div>
         <div data-v-e428484b="" class="mt4 fs12 text-muted fiat">$0.00</div>
      </div>
      <div data-v-e428484b="" class="col-actions">
         <span data-v-e428484b="" class="coptions stop-propagation">
            <div data-v-b8472b47="" data-v-e428484b="" class="v-popper v-popper--theme-dropdown inline-block dropdown">
               <div data-v-b8472b47="" class="dropdown">
                  <div data-v-b8472b47="" class="dropdown-field"><i data-v-b8472b47="" class="fas fa-ellipsis-v field"></i></div>
               </div>
            </div>
         </span>
         <div data-v-e428484b="" class="links stop-propagation"><a data-v-e428484b="" class="option" style="visibility: visible;">Deposit</a><a data-v-e428484b="" class="option" style="visibility: visible;">Withdrawal</a></div>
      </div>
   </div>
</div> -->





   <!-- <div data-v-e428484b="" class="header">
      <div data-v-e428484b="" class="col-name pointer">
         nombre 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-pending pointer">
         Pendiente y en pedidos 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-available pointer">
         Saldo disponible 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-total pointer">
         Total 
         <div data-v-e428484b="" class="sort"><i class="fas fa-caret-up up"></i><i class="fas fa-caret-down down"></i></div>
      </div>
      <div data-v-e428484b="" class="col-actions"></div>
   </div> -->
   
   <!-- <div data-v-e428484b="" class="wallet-row pointer">
      <div data-v-e428484b="" class="col-name">
         <img data-v-e428484b="" src="@/assets/images/currencies/btc.png">
         <div data-v-e428484b="" class="cholder">
            <div data-v-e428484b="" class="currency">BTC</div>
         </div>
      </div>
      <div data-v-e428484b="" class="col-pending">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BTC</span></div>
      </div>
      <div data-v-e428484b="" class="col-available">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BTC</span></div>
      </div>
      <div data-v-e428484b="" class="col-total">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BTC</span></div>
      </div>
   </div> -->

   <!-- <div data-v-e428484b="" class="wallet-row pointer">
      <div data-v-e428484b="" class="col-name">
         <img data-v-e428484b="" src="@/assets/images/currencies/doge.png">
         <div data-v-e428484b="" class="cholder">
            <div data-v-e428484b="" class="currency">DOGE</div>
         </div>
      </div>
      <div data-v-e428484b="" class="col-pending">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">DOGE</span></div>
      </div>
      <div data-v-e428484b="" class="col-available">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">DOGE</span></div>
      </div>
      <div data-v-e428484b="" class="col-total">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">DOGE</span></div>
      </div>
   </div> -->

   <!-- <div data-v-e428484b="" class="wallet-row pointer">
      <div data-v-e428484b="" class="col-name">
         <img data-v-e428484b="" src="@/assets/images/currencies/ltc.png">
         <div data-v-e428484b="" class="cholder">
            <div data-v-e428484b="" class="currency">LTC</div>
         </div>
      </div>
      <div data-v-e428484b="" class="col-pending">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">LTC</span></div>
      </div>
      <div data-v-e428484b="" class="col-available">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">LTC</span></div>
      </div>
      <div data-v-e428484b="" class="col-total">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">LTC</span></div>
      </div>
   </div> -->

   <!-- <div data-v-e428484b="" class="wallet-row pointer">
      <div data-v-e428484b="" class="col-name">
         <img data-v-e428484b="" src="@/assets/images/currencies/bch.png">
         <div data-v-e428484b="" class="cholder">
            <div data-v-e428484b="" class="currency">BCH</div>
         </div>
      </div>
      <div data-v-e428484b="" class="col-pending">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BCH</span></div>
      </div>
      <div data-v-e428484b="" class="col-available">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BCH</span></div>
      </div>
      <div data-v-e428484b="" class="col-total">
         <div data-v-e428484b="">0.00000000 <span data-v-e428484b="" class="text-muted">BCH</span></div>
      </div>
   </div> -->

<!-- </div> -->


                  
                  <div data-v-e428484b="" class="row" style="margin-bottom: -16px;" v-if="activeTab === 'row' || $mq === 'mobile'">

                    <div data-v-e428484b="" 
                    v-for="currency in displayedBalances" :key="currency.code"
                    @click="goToBalanceDetails(currency.code2)"
                    class="col-md-3 wallet pointer">
                        <div data-v-e428484b="" class="name">
                          <img data-v-e428484b="" :src="currency.image" :alt="currency.name">
                          <div data-v-e428484b="" class="cholder">
                            <span data-v-e428484b="" class="ccurrency">{{ currency.code2 }}</span>
                            <span data-v-e428484b="" class="cname">{{ currency.name }}</span></div>
                          <div data-v-e428484b="" class="values">
                              <div data-v-e428484b="" class="section2 stop-propagation">
                                <span data-v-e428484b="" class="cbalance">{{ isBalanceHidden ? "****" : currency.valueInCrypto }} <span data-v-e428484b="" class="text-muted">{{ currency.code2 }}</span></span>
                                <br data-v-e428484b="">
                                <span data-v-e428484b="" class="cfiat">$ {{ isBalanceHidden ? "****" : currency.valueInFiat }}</span>
                                <br data-v-e428484b="">
                            </div>
                          </div>
                        </div>
                    </div>
                    
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>
</div>


  </div>
  <FooterDash/>
</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue'
import QrcodeVue from 'qrcode.vue';
import clickOutside from '../directives/clickOutside';
import axios from 'axios';
export default {
  name: 'CloudhashxFrontendWallets',
    components: {
        Sidebar, FooterDash, QrcodeVue
    },
  data() {
    return {
        placement: 'auto',
      activeTab: 'row',
      showDepositModal: false,
      coinName: 'Bitcoin (BTC)',
      coinType: 'BTC',
      selectedCurrency: { label: 'Bitcoin (BTC)', value: 'BTC', image: 'btc.png' },
      supportedCurrencies: [
        { label: 'Bitcoin (BTC)', value: 'BTC', image: 'btc.png' },
        { label: 'Tether (USDT TRC20)', value: 'USDT_TRC20', image: 'usdt.png' },
        { label: 'Tron (TRX)', value: 'TRX', image: 'trx.png' },
        { label: 'Dogecoin (DOGE)', value: 'DOGE', image: 'doge.png' },
        { label: 'BinanceCoin (BNB)', value: 'BNB', image: 'bnb.svg' },
        { label: 'Bitcoin cash (BCH)', value: 'BCH', image: 'bch.png' },
        { label: 'Litecoin (LTC)', value: 'LTC', image: 'ltc.png' }
      ],
      isDropdownOpen: false,
      showAddress: false,
      addressDeposit: '',
      isLoading: false,
      showMessage: false,
      currencies: [
        { code: "btc_balance", code2: "BTC", name: "Bitcoin", crypto: "bitcoin", image: require("@/assets/images/currencies/btc.png") },
        { code: "ltc_balance", code2: "LTC", name: "Litecoin", crypto: "litecoin", image: require("@/assets/images/currencies/ltc.png") },
        { code: "doge_balance", code2: "DOGE", name: "Dogecoin", crypto: "dogecoin", image: require("@/assets/images/currencies/doge.png") },
        { code: "bch_balance", code2: "BCH", name: "Bitcoin Cash", crypto: "bitcoin-cash", image: require("@/assets/images/currencies/bch.png") },
      ],
    };
  },
  computed: {
    ...mapGetters({
            convertedBalances: "user/convertedBalances",
            totalFiatBalance: "user/totalFiatBalance",
            balances: "user/getBalances",
            prices: "user/getPrices",
            autenticado: 'user/autenticado',
            user: 'user/usuario',
            balance: 'user/balance',
            isBalanceHidden: 'user/isBalanceHidden'
      }),
    displayedBalances() {
        return this.convertedBalances(this.currencies); // Consume monedas definidas en el componente
    },

  },
  mounted() {
    this.getDirectAddress();
  },
  directives: {
    clickOutside,
  },
  methods: {
    ...mapActions("user", ["fetchBalances", "fetchPrices"]),
    async initializeBalances() {
      await Promise.all([this.fetchBalances(), this.fetchPrices()]);
    },
    goToBalanceDetails(currency) {
        this.$router.push({ name: 'BalanceDetails', params: { currency } });
    },
    copyRef() {
        this.showMessage = true;
        this.$refs.myinput.focus();
        document.execCommand('copy');
        setTimeout(() => {
            this.showMessage = false
        }, 5000)
    },
    openAddress() {
        this.showAddress = !this.showAddress;
    },
    changeTab(tab) {
      this.activeTab = tab; // Cambiar la pestaña activa
    },
    openDepositModal() {
        this.showDepositModal = !this.showDepositModal
    },
    closeDepositModal() {
        this.showDepositModal = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getCurrencyImage(currency) {
        if (currency.value === 'BNB') {
            return require(`@/assets/images/currencies/${currency.image}`);
        } else {
            return require(`@/assets/images/currencies/${currency.image.toLowerCase()}`);
        }
    },
    changeCurrency2(currency) {
      this.selectedCurrency = currency;
      this.coinType = currency.value;
      this.coinName = currency.label;
      this.isDropdownOpen = false;
      this.getDirectAddress();
    },
    getDirectAddress() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + 'getDirectAddress', {
          params: {
            coinType: this.coinType
          }
      })
      .then((res)=>{
        this.addressDeposit = res.data;
        this.isLoading = false;
      })
      .catch((error)=>{
        console.log('error getting DepositAddress',error.response.data);
        this.isLoading = false;
      })
    },
  },
};
</script>
<style scoped>

.alert-orange {
    border: none;
    background-color: #fee8d0;
    color: #fba342;
}

.alert-none {
    border: 1px solid #eeeeee;
    color: #959595;
    background: transparent;
}

.alert {
    border: 0;
    padding: 12px 16px;
    border-radius: 4px;
    width: 100%;
    font-size: 14px;
    line-height: 1.43;
}

.input-group.copy-on-hover .copy {
    display: none
}

.input-group.copy-on-hover:hover .copy {
    display: block
}

.input-group input:disabled[type=copy], .input-group input:read-only[type=copy] {
    cursor: default;
}

.input-group.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group input:disabled, .input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.input-group .copy {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.input-group.medium .copy {
    top: 16px;
    right: 16px;
}

.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #00ba94;
}

.copy-address {
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    width: 100%;
    background: #fff;
}

.text-left {
    text-align: left;
}
.selectbox.deposit-to img,.selectbox.deposit-to .img,.selectbox.deposit-from img,.selectbox.deposit-from .img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 40px
}

.selectbox.deposit-to .field img,.selectbox.deposit-from .field img {
    position: relative;
    vertical-align: middle;
    top: -2px
}

.selectbox.deposit-to .field .name,.selectbox.deposit-from .field .name {
    position: absolute;
    top: 0px;
    left: 88px
}

.selectbox.deposit-to .field .balance2,.selectbox.deposit-from .field .balance2 {
    position: absolute;
    top: 12px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option,.selectbox.deposit-from .option {
    position: relative
}

.selectbox.deposit-to .option img,.selectbox.deposit-from .option img {
    margin-left: 8px;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    position: relative;
    top: 2px
}

.selectbox.deposit-to .option .name,.selectbox.deposit-from .option .name {
    position: absolute;
    top: 11px;
    left: 88px
}

.selectbox.deposit-to .option .balance2,.selectbox.deposit-from .option .balance2 {
    position: absolute;
    top: 33px;
    left: 88px;
    font-size: 12px
}

.selectbox.deposit-to .option.selected .text-muted,.selectbox.deposit-from .option.selected .text-muted,.selectbox.deposit-to .option.selected .label-warning,.selectbox.deposit-from .option.selected .label-warning {
    color: #fff
}

.selectbox.deposit-to .option a .img,.selectbox.deposit-from .option a .img {
    border: 2px solid #FBA342;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    color: #fba342
}

.btn.primary {
    background: linear-gradient(90deg,#19345D,#19344D);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.selectbox.deposit-to .option a .name,.selectbox.deposit-from .option a .name {
    color: #959595;
    top: 20px
}

.selectbox.deposit-to .option.selected a .name,.selectbox.deposit-from .option.selected a .name {
    color: #fff
}

div.selectbox.large .arrows {
    top: 28px;
}

div.selectbox .arrows i {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    top: 3px;
    left: 5px;
}

div.selectbox .arrows {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 15px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
}

div.selectbox {
    position: relative
}

div.selectbox .field {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.theme-dark div.selectbox .field {
    background-color: #052339;
    border: 1px solid #40494f;
    color: #eee
}

div.selectbox .field.placeholder {
    color: #cecdcd
}

div.selectbox.large .field {
    height: 72px;
    line-height: 72px
}

div.selectbox.large .input-group-prepend {
    line-height: 71px;
    height: 70px
}

div.selectbox.large .arrows {
    top: 28px
}

div.selectbox.disabled,div.selectbox:disabled {
    opacity: .5;
    box-shadow: inset 1px 4px 6px -5px #23232373;
    border: 0
}

div.selectbox.disabled .field,div.selectbox:disabled .field {
    border: 0;
    box-shadow: 0 1px 4px #23232329;
    cursor: not-allowed
}

div.selectbox .dropdown {
    position: absolute;
    background: #fff;
    z-index: 2;
    min-width: 100%;
    box-shadow: 0 1px 4px #23232329;
    max-height: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: max-height .2s;
    flex-direction: column;
    overflow: hidden
}

div.selectbox .dropdown .search {
    padding: 8px;
    border-bottom: 1px solid #EEE;
    box-shadow: 0 1px 4px #23232329
}

.theme-dark div.selectbox .dropdown .search {
    border-bottom: 1px solid #40494f
}

div.selectbox .dropdown .search input {
    border-radius: 4px;
    font-size: 14px;
    padding: 10px 8px;
    border: 1px solid #EEE;
    width: 100%
}

.theme-dark div.selectbox .dropdown .search input {
    border: 1px solid #40494f;
    background-color: #00000040;
    color: #eee
}

div.selectbox .dropdown .search input:focus {
    outline: none;
    border: 1px solid #3883dd
}

div.selectbox .dropdown .search input::-moz-placeholder {
    color: #cecdcd;
    font-size: 14px
}

div.selectbox .dropdown .search input::placeholder {
    color: #cecdcd;
    font-size: 14px
}

div.selectbox .dropdown .options {
    overflow: hidden;
    overflow-y: auto;
    flex: 1
}

div.selectbox .dropdown .options .option {
    padding: 8px 16px;
    cursor: pointer
}

.before {
    text-align: center
}

.before .sign {
    width: 96px;
    height: 96px;
    border-radius: 96px;
    line-height: 96px;
    color: #19345D;
    font-size: 48px;
    background-color: #23232329;
    text-align: center;
    margin: 48px auto
}

.before .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px
}

.before .desc {
    margin-bottom: 32px
}

.deposit-addr .qrcode {
    margin: 32px auto;
    width: 190px;
    height: 190px;
    line-height: 160px;
    text-align: center;
    background: #eee
}

.deposit-addr .qrcodeBig {
    margin: 32px auto 16px;
    width: 320px;
    height: 320px;
    line-height: 320px;
    text-align: center
}

div.selectbox .dropdown .options .option:hover {
    background-color: #3883dd40
}

div.selectbox .dropdown .options .option.selected {
    background-color: #3883dd;
    color: #fff
}

div.selectbox .dropdown .options .option.disabled {
    opacity: .5;
    cursor: default
}

div.selectbox.open .dropdown {
    max-height: 350px
}

.theme-dark div.selectbox.open .dropdown {
    border: 1px solid #40494f
}

div.selectbox.up.open .field {
    border-radius: 0 0 4px 4px
}

div.selectbox.up .dropdown {
    bottom: 100%;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 -1px 4px #23232329
}

.field-wrap+.field-wrap {
    margin-top: 24px
}

.field-wrap .label-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    font-size: 12px
}

.field-wrap .label-row label {
    color: #959595;
    display: block;
    flex: 1
}

.modal-deposit-bg {
    display: block;
    background: #3a3939cc;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 105;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in
}

.modal-deposit-bg.show {
    opacity: 1;
    visibility: visible
}

.modal-deposit.size1 {
    top: 48px;
    width: calc(100% - 16px);
    max-width: 610px
}

@media only screen and (max-width : 768px) {
    .modal-deposit.size1 {
        top:8px
    }
}

.modal-deposit.show {
    display: block
}

.modal-deposit {
    display: none;
    background: #fff;
    width: 400px;
    box-shadow: 0 32px 40px #23232329;
    z-index: 106;
    border-radius: 12px;
    text-align: left;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    margin-bottom: 24px
}

.modal-deposit .modal-head {
    border-bottom: 1px solid #EEE;
    padding: 16px 24px;
    border-radius: 12px 12px 0 0
}

.modal-deposit .modal-head .title {
    font-weight: 700;
    position: relative;
    top: 2px;
    font-size: 20px;
    text-align: left;
    line-height: 1.4;
    padding-right: 32px
}

.modal-deposit .modal-head .title.title-muted {
    font-size: 14px;
    font-weight: 400;
    color: #959595;
    line-height: 1.43;
    height: 28px;
    display: flex;
    align-items: center
}

.modal-deposit .modal-head .close,.modal-deposit .modal-head .back {
    position: absolute;
    top: 20px;
    right: 24px;
    display: block;
    width: 24px;
    height: 24px;
    color: #3a3939;
    text-align: center;
    line-height: 24px;
    font-size: 20px
}

.modal-deposit .modal-head .close:hover,.modal-deposit .modal-head .back:hover {
    color: #cecdcd
}

.modal-deposit .divider {
    border-bottom: 1px solid #EEE;
    margin: 16px -24px
}

.modal-deposit .modal-content {
    padding: 24px 24px 32px;
    border-radius: 0 0 12px 12px
}

.modal-deposit .modal-content p:first-child {
    margin-top: 0
}

.modal-deposit p {
    margin: 16px 0 24px;
    color: #959595;
    line-height: 1.43;
    font-size: 14px
}

.modal-deposit .spacer {
    height: 80px;
    margin: 0 0 -80px
}




.content[data-v-c30e999c] {
    flex: 1 1 0%;
    margin-left: 32px;
}

.content .search[data-v-c30e999c] {
    position: relative;
    display: inline-block;
    margin-right: 16px;
}

.list.compact-view .col-name img[data-v-e428484b] {
    width: 24px;
    height: 24px;
}

.list .col-name img[data-v-e428484b] {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    float: left;
}

.list .col-actions[data-v-e428484b] {
    width: 56px;
    padding-right: 16px!important;
    text-align: right;
}


.btn-group {
    font-size: 0;
    border-radius: 4px;
    background: #eee;
    display: inline-block;
}

.content .search[data-v-c30e999c] div.input-group {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 200px;
}

.content .search[data-v-c30e999c] div.input-group input {
    padding: 5px 28px 5px 16px;
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
}

.input-group input[data-v-1831c830] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 9px 16px 11px;
    margin: 0;
}

.form-control {
    width: 100%;
}

.content .search i.fa[data-v-c30e999c] {
    position: absolute;
    top: 7px;
    right: 8px;
    color: rgb(149, 149, 149);
}

.list[data-v-e428484b] {
    box-shadow: 0 1px 4px #23232329;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 32px;
}

.list .header[data-v-e428484b] {
    display: flex;
    width: 100%;
    background: #fff;
    flex-direction: row;
    border-bottom: 2px solid #EEE;
}

.list .header>div[data-v-e428484b] {
    padding: 16px;
    text-transform: uppercase;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #959595;
    position: relative;
}

.list .col-name[data-v-e428484b] {
    flex: 1;
    padding-left: 16px!important;
}

.list .col-pending[data-v-e428484b] {
    text-align: right;
    width: 170px;
}

.list .col-available[data-v-e428484b], .list .col-total[data-v-e428484b] {
    width: 170px;
    text-align: right;
}

.list .col-actions[data-v-e428484b] {
    width: 56px;
    padding-right: 16px!important;
    text-align: right;
}

.list .wallet-row[data-v-e428484b]:last-child {
    border-bottom: 0;
}

.list .wallet-row[data-v-e428484b] {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EEE;
    color: #3a3939;
    align-items: center;
}

.list .header .sort[data-v-e428484b] {
    display: inline-block;
    position: absolute;
    top: 27px;
}

.list .header .sort[data-v-e428484b] i.up {
    top: -13px;
}

.list .header .sort[data-v-e428484b] i {
    position: absolute;
    display: block;
    color: #959595;
    margin-left: 4px;
}

.list .header .sort[data-v-e428484b] i.down {
    top: -7px;
}

.wallet-container[data-v-c30e999c] {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

@media only screen and (max-width: 768px) {
    .wallet-container[data-v-c30e999c] {
        flex-wrap: wrap;
    }
}

.sidebar[data-v-c30e999c] {
    width: 232px;
}

@media only screen and (max-width: 991px) {
    .sidebar[data-v-c30e999c] {
        width: 224px;
    }
}

@media only screen and (max-width: 768px) {
    .sidebar[data-v-c30e999c] {
        width: 100%;
        margin-bottom: 32px;
    }
}


@media only screen and (max-width: 991px) {
    .content[data-v-c30e999c] {
        margin-left: 24px;
    }
}

@media only screen and (max-width: 768px) {
    .content[data-v-c30e999c] {
        margin-left: 0px;
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .content .search[data-v-c30e999c] {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .content .search[data-v-c30e999c] div.input-group {
        width: 100%;
        top: 2px;
    }
}

@media only screen and (max-width: 768px) {
    .content .search i.fa[data-v-c30e999c] {
        top: 9px;
    }
}

.wallet[data-v-e428484b] {
    padding: 0 16px 32px;
}

.wallet .name[data-v-e428484b] {
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
}

.wallet .name .cholder[data-v-e428484b] {
    display: inline-block;
}

.wallet .name img[data-v-e428484b] {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    float: left;
}

.wallet .name .ccurrency[data-v-e428484b] {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0px;
    color: #3a3939;
    display: block;
}

.wallet .name .cname[data-v-e428484b] {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0px;
    color: #959595;
    display: block;
}

.wallet .name .coptions[data-v-e428484b] {
    display: inline-block;
    float: right;
}

.flex--justify-right {
    justify-content: flex-end;
}

.wallet .values[data-v-e428484b] {
    margin-top: 32px;
}

.wallet .values .cbalance[data-v-e428484b] {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0px;
    color: #3a3939;
}

.wallet .values .cfiat[data-v-e428484b] {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0px;
    color: #959595;
}

.dropdown[data-v-7d7fa855] {
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.dropdown .dropdown-field[data-v-7d7fa855] {
    border-radius: 4px;
    width: 24px;
    height: 24px;
    border: 1px solid #EEE;
    text-align: center;
    line-height: 24px;
    color: #959595;
    cursor: pointer;
    font-size: 12px;
    background: #fff;
}

.wallet-options-dropdown[data-v-6c18f7e8] {
    padding-bottom: 8px
}

.wallet-options-dropdown .actions[data-v-6c18f7e8] {
    margin-bottom: 16px;
    color: #959595;
    text-transform: uppercase;
    font-weight: 500;
    padding: 8px 16px 0;
    font-size: 12px
}

.wallet-options-dropdown .option[data-v-6c18f7e8] {
    display: flex;
    flex-direction: row;
    width: 220px;
    padding: 8px 16px;
    align-items: center
}

.wallet-options-dropdown .option .icon[data-v-6c18f7e8] {
    width: 24px
}

.wallet-options-dropdown .option .icon i[data-v-6c18f7e8] {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 24px;
    background: #fee8d0;
    color: #fba342;
    text-align: center;
    display: inline-block;
    vertical-align: middle
}

.wallet-options-dropdown .option .text[data-v-6c18f7e8] {
    padding-right: 24px;
    padding-left: 12px;
    flex: 1;
    font-size: 14px;
    white-space: normal
}

.compact[data-v-e428484b] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid #EEE;
    position: relative
}

.theme-dark .compact[data-v-e428484b] {
    border-top: 1px solid #40494f
}

.compact[data-v-e428484b]:hover {
    border-bottom-color: #fff
}

.compact[data-v-e428484b]:after {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: " ";
    z-index: 1;
    transition: box-shadow .2s ease
}

.compact[data-v-e428484b]:hover:after {
    box-shadow: 0 8px 16px #23232329
}

.compact button[data-v-e428484b] {
    padding-left: 12px;
    padding-right: 12px
}

.compact .name[data-v-e428484b] {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #3a3939
}

.theme-dark .compact .name[data-v-e428484b] {
    color: #eee
}

.compact .name img[data-v-e428484b] {
    position: relative;
    left: -4px;
    width: 20px;
    height: 20px;
    margin-right: 4px
}

.compact .values[data-v-e428484b] {
    color: #3a3939;
    text-align: right;
    display: flex;
    flex-direction: column
}

.theme-dark .compact .values[data-v-e428484b] {
    color: #eee
}

.wallet[data-v-e428484b] {
    padding: 0 16px 32px
}

.wallet .name[data-v-e428484b] {
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px
}

.theme-dark .wallet .name[data-v-e428484b] {
    background-color: #031a2a;
    border: 1px solid #40494f
}

.wallet .name[data-v-e428484b]:hover {
    box-shadow: 0 8px 16px #23232329;
    transition: box-shadow .2s ease;
    cursor: pointer
}

.wallet .name img[data-v-e428484b] {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    float: left
}

.wallet .name .cholder[data-v-e428484b] {
    display: inline-block
}

.wallet .name .cname[data-v-e428484b] {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0px;
    color: #959595;
    display: block
}

.wallet .name .ccurrency[data-v-e428484b] {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0px;
    color: #3a3939;
    display: block
}

.theme-dark .wallet .name .ccurrency[data-v-e428484b] {
    color: #eee
}

.wallet .name .coptions[data-v-e428484b] {
    display: inline-block;
    float: right
}

.wallet .values[data-v-e428484b] {
    margin-top: 32px
}

.wallet .values small[data-v-e428484b] {
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px
}

.wallet .values .cavailable[data-v-e428484b] {
    text-transform: uppercase
}

.wallet .values .cbalance[data-v-e428484b] {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0px;
    color: #3a3939
}

.theme-dark .wallet .values .cbalance[data-v-e428484b] {
    color: #eee
}

.wallet .values .cfiat[data-v-e428484b] {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0px;
    color: #959595
}

.wallet .inactive-buttons[data-v-e428484b] {
    margin-top: 24px;
    clear: both;
    margin-left: -8px;
    margin-right: -8px
}

.wallet .inactive-buttons .col-sm-6[data-v-e428484b] {
    padding: 0 8px
}

.wallet .inactive-buttons .btn[data-v-e428484b] {
    padding: 6px;
    margin-top: 8px;
    height: 24px
}

.list[data-v-e428484b] {
    box-shadow: 0 1px 4px #23232329;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 32px
}

.theme-dark .list[data-v-e428484b] {
    border: 1px solid #40494f;
    background: #031a2a
}

.list .header[data-v-e428484b] {
    display: flex;
    width: 100%;
    background: #fff;
    flex-direction: row;
    border-bottom: 2px solid #EEE
}

.theme-dark .list .header[data-v-e428484b] {
    background: #031a2a;
    border-bottom: 2px solid #40494f
}

.list .header>div[data-v-e428484b] {
    padding: 16px;
    text-transform: uppercase;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #959595;
    position: relative
}

.list .header .sort[data-v-e428484b] {
    display: inline-block;
    position: absolute;
    top: 27px
}

.list .header .sort[data-v-e428484b] i {
    position: absolute;
    display: block;
    color: #959595;
    margin-left: 4px
}

.list .header .sort[data-v-e428484b] i.up {
    top: -13px
}

.list .header .sort[data-v-e428484b] i.down {
    top: -7px
}

.list .header .sort.show-up[data-v-e428484b] i.up {
    color: #fba342
}

.list .header .sort.show-up[data-v-e428484b] i.down,.list .header .sort.show-down[data-v-e428484b] i.up {
    display: none
}

.list .header .sort.show-down[data-v-e428484b] i.down {
    color: #fba342
}

.list .wallet-row[data-v-e428484b] {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EEE;
    color: #3a3939;
    align-items: center
}

.theme-dark .list .wallet-row[data-v-e428484b] {
    border-bottom: 1px solid #40494f;
    color: #eee
}

.list .wallet-row[data-v-e428484b]:last-child {
    border-bottom: 0
}

.list .wallet-row>div[data-v-e428484b] {
    padding: 16px
}

.list .wallet-row[data-v-e428484b]:hover {
    box-shadow: 0 8px 16px #23232329
}

.list .col-name[data-v-e428484b] {
    flex: 1;
    padding-left: 16px!important
}

.list .col-name img[data-v-e428484b] {
    margin-right: 12px;
    width: 40px;
    height: 40px;
    float: left
}

.list .col-name .currency[data-v-e428484b] {
    margin-top: 4px
}

.list .col-name .name[data-v-e428484b] {
    font-size: 12px;
    margin-top: 4px;
    color: #959595
}

.list .col-pending[data-v-e428484b] {
    text-align: right;
    width: 170px
}

.list .col-available[data-v-e428484b],.list .col-total[data-v-e428484b] {
    width: 170px;
    text-align: right
}

.list .col-actions[data-v-e428484b] {
    width: 56px;
    padding-right: 16px!important;
    text-align: right
}

@media only screen and (min-width : 1440px) {
    .list .col-actions[data-v-e428484b] {
        width:300px
    }
}

@media only screen and (min-width : 1440px) {
    .list .col-actions .dropdown[data-v-e428484b] {
        display:none
    }
}

.list .col-actions .links[data-v-e428484b] {
    display: none
}

.list .col-actions .links a[data-v-e428484b] {
    padding: 8px;
    color: #959595
}

.list .col-actions .links a[data-v-e428484b]:last-child {
    padding-right: 0
}

.list .col-actions .links a[data-v-e428484b]:hover {
    color: #fba342
}

@media only screen and (min-width : 1440px) {
    .list .col-actions .links[data-v-e428484b] {
        display:block
    }
}

.list .col-inactive-buttons[data-v-e428484b] {
    width: 300px;
    text-align: right
}

.list .col-inactive-buttons .btn[data-v-e428484b] {
    display: inline-block;
    vertical-align: middle;
    min-width: 90px
}

.list.compact-view .col-name img[data-v-e428484b] {
    width: 24px;
    height: 24px
}

.list.compact-view small[data-v-e428484b] {
    display: none
}

div div:first-child>a.compact[data-v-e428484b] {
    border-top: 0
}

#app_holder.gray-bg {
    background: #f5f5f5;
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.box .available .deposit-withdraw[data-v-d45d9c5b] {
    margin: 16px 0;
}

.pull-right {
    float: right;
}

.list .col-name .name[data-v-e428484b] {
    font-size: 12px;
    margin-top: 4px;
    color: #959595;
}

.list .col-name[data-v-e428484b] {
    flex: 1;
    padding-left: 16px!important;
}

.list .wallet-row>div[data-v-e428484b] {
    padding: 16px;
}

.box .total .deposit-withdraw .btn {
    padding: 6px!important;
    width: calc(50% - 4px);
    margin: 0;
}

.box .total .deposit-withdraw {
    margin: 16px 0;
}

.balances {
    font-size: 14px;
    font-weight: 700;  
    font-family: Lora, serif;
    font-style: italic;
    color: #959595;
}

</style>