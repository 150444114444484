<template>
  <footer data-v-6ea084ab="" data-v-82c01ab1="">
   <div data-v-6ea084ab="" class="row">
      <div data-v-6ea084ab="" class="col-lg-7 col-sm-12 left-part">
         <ul data-v-6ea084ab="">
            <li data-v-6ea084ab=""><router-link data-v-6ea084ab="" to="/"><i data-v-6ea084ab="" class="fa fa-level-up-alt fa-rotate-270 mr8"></i>{{$t('homePage')}}</router-link></li>
            <!-- <li data-v-6ea084ab=""><router-link data-v-6ea084ab="" to="/support" target="_blank"><i data-v-6ea084ab="" class="fa fa-question-circle mr8"></i><span v-html="$t('help')"></span></router-link></li>
            <li data-v-6ea084ab=""><router-link data-v-6ea084ab="" to="/blog" target="_blank"><i data-v-6ea084ab="" class="fa fa-newspaper mr8"></i>Blog</router-link></li> -->
         </ul>
      </div>
      <div data-v-6ea084ab="" class="col-lg-5 col-sm-12 right-part">
         <div data-v-6ea084ab="" class="copy-rights">© {{ currentYear() }} CloudHashX. All rights reserved</div>
            <div data-v-6ea084ab class="settings-part">
                <div @click="toggleDropdown" v-click-outside="hideDropdown" data-v-97e8b8a2="" data-v-0d75714a="" data-v-6ea084ab="" class="selectbox up locale locale-select locale" :class="{ 'open': isDropdownOpen }" tabindex="0">
                <div data-v-97e8b8a2="" class="field" aria-haspopup="true" :aria-expanded="isDropdownOpen ? 'true' :'false'">
                    <div data-v-97e8b8a2="" class="arrows"><i data-v-97e8b8a2="" class="fa fa-caret-up"></i><i data-v-97e8b8a2="" class="fa fa-caret-down"></i></div>
                    <div data-v-0d75714a="" class="flag" :class="selectedLanguage.flag"></div>
                    {{ selectedLanguage.label }}
                </div>
                <div data-v-97e8b8a2="" class="dropdown flex flex--no-wrap">
                    <div data-v-97e8b8a2="" class="options" role="listbox">
                        <div v-for="(lang, index) in supportedLanguages" 
                        :key="index" data-v-97e8b8a2=""   
                        class="option" 
                        :class="{ selected: lang.value === selectedLanguage.value }" 
                        role="option" 
                        :aria-selected="lang.value === selectedLanguage.value">
                            <div @click.stop="changeLocale(lang)">
                                <div data-v-0d75714a="" class="flag" :class="lang.flag"></div>
                            {{ lang.label }}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import clickOutside from '../directives/clickOutside';
import i18n from '@/i18n/index.js';
import moment from 'moment';
export default {
  name: 'CloudhashxFrontendFooterDash',

  data() {
    return {
        supportedLanguages: [
          { label: 'English', value: 'en', flag: 'gb' },
          { label: 'Español', value: 'es', flag: 'es' }
        ],
        isDropdownOpen: false
    };
  },
  computed: {
    ...mapGetters(['locale', 'supportedLanguages2']), // Obtener el idioma seleccionado y los idiomas admitidos desde Vuex
    selectedLanguage: {
      get() {
        // Devolver el idioma seleccionado del array de idiomas admitidos según el estado de Vuex
        return this.supportedLanguages.find(lang => lang.value === this.locale);
      },
      set(value) {
        // Podemos añadir lógica aquí si necesitamos actualizar selectedLanguage desde el componente
      }
    },
  },
  mounted() {
    i18n.locale = this.locale;
  },

  methods: {
    currentYear() {
        return moment().year();
    },
    ...mapActions(['setLocale']),
    changeLocale(locale) {
        i18n.locale = locale.value;
        this.setLocale(locale.value);
        this.hideDropdown()
        //this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    hideDropdown() {
      this.isDropdownOpen = false;
    },
  },
  watch: {
    locale(newLocale) {
      this.selectedLanguage = this.supportedLanguages2.find(lang => lang.value === newLocale);
    }
  },
  directives: {
    clickOutside,
  },
};
</script>
<style scoped>
.flex, .row {
    display: flex;
    flex-wrap: wrap;
}

.row {
    margin-right: -16px;
    margin-left: -16px;
}

@media only screen and (min-width : 1200px) {
    .col-lg-1 {
        max-width:8.3333333333%;
        flex-basis: 8.3333333333%
    }

    .col-lg-2 {
        max-width: 16.6666666667%;
        flex-basis: 16.6666666667%
    }

    .col-lg-3 {
        max-width: 25%;
        flex-basis: 25%
    }

    .col-lg-4 {
        max-width: 33.3333333333%;
        flex-basis: 33.3333333333%
    }

    .col-lg-5 {
        max-width: 41.6666666667%;
        flex-basis: 41.6666666667%
    }

    .col-lg-6 {
        max-width: 50%;
        flex-basis: 50%
    }

    .col-lg-7 {
        max-width: 58.3333333333%;
        flex-basis: 58.3333333333%
    }

    .col-lg-8 {
        max-width: 66.6666666667%;
        flex-basis: 66.6666666667%
    }

    .col-lg-9 {
        max-width: 75%;
        flex-basis: 75%
    }

    .col-lg-10 {
        max-width: 83.3333333333%;
        flex-basis: 83.3333333333%
    }

    .col-lg-11 {
        max-width: 91.6666666667%;
        flex-basis: 91.6666666667%
    }

    .col-lg-12 {
        max-width: 100%;
        flex-basis: 100%
    }
}

footer[data-v-6ea084ab] {
    min-height: 64px;
    background: #eee;
    line-height: 64px;
    padding: 0 20px
}

.theme-dark footer[data-v-6ea084ab] {
    background: #031a2a;
    color: #eee
}

footer .left-part[data-v-6ea084ab] {
    display: flex;
    align-items: center
}

@media only screen and (max-width : 991px) {
    footer .left-part[data-v-6ea084ab] {
        justify-content:center
    }
}

footer ul[data-v-6ea084ab] {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row
}

@media only screen and (max-width : 768px) {
    footer ul[data-v-6ea084ab] {
        flex-direction:column;
        width: 100%
    }
}

footer ul li a[data-v-6ea084ab] {
    display: block;
    padding: 0 20px;
    color: #959595;
    white-space: nowrap;
    font-size: 12px
}

@media only screen and (max-width : 768px) {
    footer ul li a[data-v-6ea084ab] {
        padding:0 8px;
        line-height: 40px;
        text-align: center
    }
}

footer .right-part[data-v-6ea084ab] {
    min-height: 64px;
    display: flex;
    justify-content: flex-end;
    align-items: center
}

@media only screen and (max-width : 991px) {
    footer .right-part[data-v-6ea084ab] {
        justify-content:center
    }
}

@media only screen and (max-width : 768px) {
    footer .right-part[data-v-6ea084ab] {
        justify-content:center;
        flex-wrap: wrap
    }
}

footer .right-part .copy-rights[data-v-6ea084ab] {
    font-size: 12px;
    margin-right: 32px;
    display: inline-block;
    height: 64px;
    line-height: 64px;
    white-space: nowrap
}

@media only screen and (max-width : 768px) {
    footer .right-part .copy-rights[data-v-6ea084ab] {
        order:2;
        margin-left: 16px;
        margin-right: 16px
    }
}

footer .right-part .settings-part[data-v-6ea084ab] {
    display: flex;
    align-items: center
}

@media only screen and (max-width : 768px) {
    footer .right-part .settings-part[data-v-6ea084ab] {
        order:1;
        justify-content: center
    }

    footer .right-part .settings-part .locale[data-v-6ea084ab] {
        margin-right: 0
    }
}

footer .right-part .currency[data-v-6ea084ab],footer .right-part .locale[data-v-6ea084ab] {
    text-align: left;
    margin-right: 16px;
    display: inline-block;
    position: relative;
    margin-top: 13px;
    margin-bottom: 12px;
    line-height: 1
}

footer .right-part .currency[data-v-6ea084ab] {
    width: 89px
}

footer .right-part .locale[data-v-6ea084ab] {
    width: 145px
}

</style>