<template>
  <div id="app_holder">
    
    <div id="content">
        <Sidebar/>

        <div class="container">

          <div data-v-ced38ea6="" class="text-center pb8 text-muted">
            <small data-v-ced38ea6="">{{$t('contractDetail.exchange')}} 1 {{contract.coin}} ≈ ${{price}} {{$t('contractDetail.used')}}</small>
          </div>


          <div data-v-a119367d="" class="tour-projected-income">
            <h2 data-v-a119367d="" class="mb16">{{$t('contractDetail.title')}}</h2>
            <div data-v-a119367d="" class="info-group mb24">
                <div data-v-255455cb="" data-v-a119367d="" class="info-box unpaid">
                  <div data-v-a119367d="" data-v-255455cb-s="" class="label mb4" v-html="$t('contractDetail.semanal')"></div>
                  <div data-v-a119367d="" data-v-255455cb-s="" class="value">{{calculateWeeklyCryptoProfit(contract)}} <span data-v-a119367d="" data-v-255455cb-s="" class="currency">{{contract.coin}}</span></div>
                  <div data-v-a119367d="" data-v-255455cb-s="" class="fiat">≈ ${{calculateWeeklyProfit(contract)}}</div>
                </div>
                <div data-v-255455cb="" data-v-a119367d="" class="info-box payout">
                  <div data-v-a119367d="" data-v-255455cb-s="" class="label mb4" v-html="$t('contractDetail.mensual')"></div>
                  <div data-v-a119367d="" data-v-255455cb-s="" class="value">{{calculateMonthlyCryptoProfit(contract)}} <span data-v-a119367d="" data-v-255455cb-s="" class="currency">{{contract.coin}}</span></div>
                  <div data-v-a119367d="" data-v-255455cb-s="" class="fiat">≈ ${{calculateMonthlyProfit(contract)}}</div>
                </div>
                <div data-v-255455cb="" data-v-a119367d="" class="info-box balance">
                  <i data-v-02f89b65="" data-v-a119367d="" data-v-255455cb-s="" v-tooltip="$t('contractDetail.tooltip')" class="fa fa-info-circle info v-popper--has-tooltip"></i>
                  <div data-v-a119367d="" data-v-255455cb-s="" class="label mb4" v-html="$t('contractDetail.final')"></div>
                  <div data-v-a119367d="" data-v-255455cb-s="" class="value">{{calculateEndContractProfitCrypto(contract)}} <span data-v-a119367d="" data-v-255455cb-s="" class="currency">{{contract.coin}}</span></div>
                  <div data-v-a119367d="" data-v-255455cb-s="" class="fiat">≈ ${{calculateEndContractProfitFiat(contract)}}</div>
                </div>
            </div>
          </div>

          <!-- grafico -->
          <div data-v-a119367d="" class="tour-mining-payments">
            <div data-v-a119367d="" class="row mb16">
                <div data-v-a119367d="" class="col-sm-6">
                  <h2 data-v-a119367d="">{{$t('contractDetail.history')}}</h2>
                </div>
            </div>

            <div class="stats-block interactive-stats mb16">


              <LineChartGenerator 
        v-if="loaded"
        :data="chartData" 
        :options="options"/>
            </div>

          </div>
          <!-- grafico -->


          <!-- tabla de pagos -->
          <div data-v-a119367d="" class="tour-mining-payments">
            <div data-v-a119367d="" class="row mb16">
                <div data-v-a119367d="" class="col-sm-6">
                  <h2 data-v-a119367d="">{{$t('contractDetail.lastPaymets')}}</h2>
                </div>
            </div>
            

            <div data-v-d723abed="" data-v-a119367d="" class="table-wrap relative table mb48">
   <!---->
   <div data-v-d723abed="" class="table-container">
      <table data-v-d723abed="" cellspacing="0" cellpadding="0" class="nh padding-normal">
         <thead data-v-d723abed="">
            <tr data-v-d723abed="">
               <th data-v-d723abed="" class="pointer">
                  {{$t('contractDetail.paymentDate')}}
               </th>
               <th data-v-d723abed="" class="pointer">
                {{$t('contractDetail.algorithm')}}
               </th>
               <th data-v-d723abed="" class="pointer">
                {{$t('contractDetail.amount')}}
               </th>
               <th data-v-d723abed="" class="pointer">
                {{$t('contractDetail.hashrate')}}
               </th>
            </tr>
         </thead>
         <tbody data-v-d723abed="">
          <tr v-for="(log, index) in logs" :key="index">
                <td>{{ formatDate(log.created_at) }}</td>
                <td >
                  <template v-if="log.currency === 'BTC' || 'BCH'">
                    SHA256
                  </template>
                  <template v-else>
                    Scrypt
                  </template>
                </td>
                <td >${{log.amount_usd}}</td>
                <td >{{log.hash_rate}}</td>
            </tr>
         </tbody>
      </table>
      
      <div data-v-d723abed="" class="empty-state" v-if="logs.length === 0">
         <div data-v-d723abed="" class="empty-content">
            <div data-v-d723abed="" class="empty-icon">
              <i data-v-d723abed="" class="far fa-file"></i>
            </div>
            <div data-v-d723abed="" class="empty-title">{{$t('noData')}}</div>
         </div>
      </div>
   </div>
   
</div>

          </div>



        </div><!-- container -->



    </div>
    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue'
import axios from 'axios'
import { mapGetters } from "vuex";
import moment from 'moment';
import { Line } from 'vue-chartjs'
import { Line as LineChartGenerator } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    TimeScale
  } from 'chart.js';
  import 'chartjs-adapter-date-fns'; // Import the date adapter
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    TimeScale // Register the TimeScale for time axis
  );
export default {
  name: 'CloudhashxFrontendContractDetail',
  components: {
    Sidebar, FooterDash, LineChartGenerator
  },
  props: ['uuid'],
  data() {
    return {
      contract: {}, // Datos del contrato
      price: 0,
      logs: [],
      interval: null,
      loaded: false,
      chartData: {
      labels: [], // Fechas
      datasets: [
        {
          label: "Hash Rate",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          data: [], // Hash rates
        },
        {
          label: "Average Hash Rate",
          backgroundColor: "rgba(153, 102, 255, 0.2)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
          data: [], // Promedio
        },
      ],
    },
    options: {
        responsive: true,
        plugins: {
          legend: { display: true },
          title: { display: true, text: `Hash Rate` },
        },
      },
    };
  },

  mounted() {
    this.fetchContract()
  },
  beforeDestroy() {
      clearInterval(this.interval);
  },
  methods: {
    formatDate(date){
      return moment.utc(date).local().fromNow();
      // moment(date).fromNow();
    },
    calculateEndContractProfitFiat(contract) {
        const durationInDays = this.calculateContractDuration(this.contract);
        if (!durationInDays || !contract?.daily_payment) {
            return 0; // Retorna 0 si no hay días válidos o pago diario
        }
        return (durationInDays * contract.daily_payment).toFixed(2);
    },
    calculateEndContractProfitCrypto(contract) {
        const durationInDays = this.calculateContractDuration(this.contract);
        if (!durationInDays || !contract?.daily_payment || !this.price) {
            return 0; // Retorna 0 si no hay días válidos, pago diario o precio de la cripto
        }
        const profitInFiat = durationInDays * contract.daily_payment;
        return (profitInFiat / this.price).toFixed(8); // Conversión a cripto
    },
    calculateContractDuration(contract) {
        if (!contract?.start_date || !contract?.end_date) {
            return 0; // Retorna 0 si no hay fechas válidas
        }

        const startDate = new Date(contract.start_date);
        const endDate = new Date(contract.end_date);

        // Calcula la diferencia en milisegundos
        const differenceInTime = endDate - startDate;

        // Convierte la diferencia a días
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

        return differenceInDays;
    },
    calculateWeeklyProfit(contract) {
        if (!contract?.daily_payment || isNaN(contract.daily_payment)) {
            return '0.00'; // Retorna 0.00 si el valor no es válido
        }
        return (contract.daily_payment * 7).toFixed(2);
    },
    calculateMonthlyProfit(contract) {
        if (!contract?.daily_payment || isNaN(contract.daily_payment)) {
            return '0.00'; // Retorna 0.00 si el valor no es válido
        }
        return (contract.daily_payment * 30).toFixed(2);
    },
    calculateWeeklyCryptoProfit(contract) {
        if (!contract?.daily_payment || isNaN(contract.daily_payment) || !this.price || isNaN(this.price)) {
            return '0.00000000'; // Retorna 0 si los valores no son válidos
        }
        const weeklyFiat = contract.daily_payment * 7;
        return (weeklyFiat / this.price).toFixed(8); // Convierte a cripto y redondea a 8 decimales
    },
    calculateMonthlyCryptoProfit(contract) {
        if (!contract?.daily_payment || isNaN(contract.daily_payment) || !this.price || isNaN(this.price)) {
            return '0.00000000'; // Retorna 0 si los valores no son válidos
        }
        const monthlyFiat = contract.daily_payment * 30;
        return (monthlyFiat / this.price).toFixed(8); // Convierte a cripto y redondea a 8 decimales
    },
    fetchContract() {
            // Reemplaza con tu llamada al backend para obtener los detalles
            axios
                .get(`${process.env.VUE_APP_API_URL}contract/${this.uuid}`)
                .then((response) => {
                  //console.log(response.data)
                  const { chartData } = response.data;

                  this.chartData.labels = chartData.dates;
                  this.chartData.datasets[0].data = chartData.hashRates;
                  this.chartData.datasets[1].data = Array(chartData.dates.length).fill(chartData.averageHashRate);
                  this.loaded = true;
                  this.contract = response.data.contract;
                  this.price = response.data.price;
                  this.logs = response.data.logs;
                })
                .catch((error) => {
                    console.error(error);
                });
    },
  },
};
</script>
<style scoped>
.info-group {
    display: flex;
}

.mb24 {
    margin-bottom: 24px;
}

.info-group div.info-box:first-child {
    border-radius: 4px 0px 0px 4px;
    border-right: 0px;
}

.info-group div.info-box {
    border-radius: 0px;
    text-align: center;
}

.info-box {
    height: 116px;
    padding: 22px 24px 24px;
    background: #fff;
    border: 1px solid #EEE;
    border-radius: 4px;
    position: relative;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 8px;
}

@media only screen and (max-width : 768px) {
    .info-box {
        padding:8px 16px;
        height: 90px
    }
}

.info-box .label {
    font-size: 10px;
    letter-spacing: 1px;
    color: #959595;
    padding: 0;
    font-weight: 500
}

.info-box .active {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 1px
}

.info-box .active .of {
    font-size: 14px;
    font-weight: 700
}

.info-box .value {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -.1px;
    margin-bottom: 6px
}

.info-box .value .currency {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: normal
}

.info-box .temp {
    font-size: 48px;
    font-weight: 700
}

.info-box .temp .unit {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: -22px;
    margin-left: 4px
}

.info-box .time {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 6px
}

.info-box .fiat {
    font-size: 12px;
    color: #959595
}

.info-box.activity {
    border: 0
}

.info-box.activity i,.info-box.activity .label,.info-box.activity .fiat {
    color: inherit
}

.info-box.activity.success {
    background: #dbeed2;
    color: #68b641
}

.info-box.activity.warning {
    background: #fee8d0;
    color: #fba342
}

.info-box.activity.danger {
    background: #fce9e9;
    color: #e92424
}

.info-group div.info-box:last-child {
    border-radius: 0px 4px 4px 0px;
    border-left: 0px;
}

@media only screen and (max-width: 768px) {
    .info-group {
        flex-wrap: wrap;
    }
}

.info-box i.fa-info-circle {
    position: absolute;
    top: 10px;
    right: 10px;
}

.fa-info-circle {
    color: #cecdcd;
    font-size: 12px;
}


.active-algorithms {
    border: 1px solid rgb(238, 238, 238);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
}

.theme-dark .active-algorithms {
    background: transparent;
    box-shadow: none;
    border: 1px solid rgb(64, 73, 79);
}

.active-algorithms .table-container table thead th {
    white-space: nowrap;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

.empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 64px;
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

.empty-state .empty-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.empty-state .empty-icon {
    width: 85px;
    height: 85px;
    background: #23232329;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-state .empty-icon i {
    font-size: 40px;
    color: #19345D;
}

.empty-state .empty-title {
    margin-top: 8px;
    font-size: 20px;
    line-height: 1.6;
    color: #959595;
    font-weight: 700;
}

.dashboard-container {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

.enable2fa-banner {
    position: relative;
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    padding: 16px 24px;
    line-height: 1.5;
    background-color: #fff;
}

.enable2fa-banner .text {
    font-size: 14px;
}

.enable2fa-banner .text-bold {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 4px;
}

.mb24 {
    margin-bottom: 24px;
}

.btn.primary.normal {
    box-shadow: 0 12px 4px -10px #19345D66;
}

.btn.fluid {
    width: 100%;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.primary {
    background: linear-gradient(90deg,#19345D,#19344D);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

#content {
    flex: 1 0 auto;
    position: relative;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
}

.content {
    box-shadow: none;
    background: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-radius: 4px;
}

.box {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    margin-bottom: 16px;
    background: #fff;
}

.box .total {
    padding: 24px 16px 0;
    border-bottom: 1px solid #EEE;
    position: relative;
}

.box .available {
    padding: 24px 16px 12px;
    position: relative;
}

.box i.info {
    position: absolute;
    top: 12px;
    right: 12px;
}

.box .total h1 {
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.mined {
  background-color: rgb(25, 52, 93);
  color: white;
  border-radius: 4px;
}

.box .available h1 {
  font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -.1px;
    line-height: 40px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb16 {
    margin-bottom: 16px;
}

.mt16 {
    margin-top: 16px;
}

.ml32 {
  margin-left: 32px;
}

.ml24 {
  margin-left: 18px;
}

.fs12 {
  font-size: 12px
}

.text-toggle {
  color: #fba342;
}

.input-group.copy-on-hover .copy {
    display: none
}

.input-group.copy-on-hover:hover .copy {
    display: block
}

.input-group input:disabled[type=copy], .input-group input:read-only[type=copy] {
    cursor: default;
}

.input-group.auth input, .input-group.medium input {
    padding: 18px 14px;
}

.input-group input:disabled, .input-group input:read-only {
    box-shadow: none;
    border: 1px solid #EEE;
}

.input-group .copy {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn.small {
    padding: 6px 23px;
    font-size: 10px;
    letter-spacing: .4px;
}

.input-group.medium .copy {
    top: 16px;
    right: 16px;
}

.copy-feedback {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    line-height: 20px;
    padding: 13px 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .05em;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    color: #00ba94;
}

.copy-address {
    border: none;
    color: #495463;
    line-height: 20px;
    padding: 12px 50px 12px 40px;
    border-radius: 4px;
    border: 1px solid rgba(211, 224, 243, 0.5);
    width: 100%;
    background: #fff;
}

.table {
    box-shadow: 0 1px 4px #23232329;
    margin: 0 auto 80px;
}

.table-container {
    position: relative;
    min-height: 90px;
    overflow-x: auto;
}

table.nh {
    width: 100%;
    border: none;
}

table.nh thead {
    background: #fff;
}

table.nh th:first-child {
    padding-left: 32px;
}

.table th {
    color: #959595;
}

table.nh th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 400;
    padding: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

table.nh tbody tr:nth-child(odd) {
    background-color: #fcfcfc;
}

table.nh tbody tr:first-child {
    box-shadow: inset 1px 4px 6px -5px #23232373;
}

table.nh tbody tr {
    background-color: #fff;
}

table.nh tbody tr td:first-child {
    padding-left: 32px;
}

table.nh tbody tr td {
    padding: 12px 16px;
    border-bottom: 1px solid #EEE;
    font-size: 12px;
    line-height: 1.33;
    color: #3a3939;
}

.skeleton-balance .line {
     height: 12px;
     margin-top: 6px;
     margin-bottom: 6px;
     border-radius: 4px;
     background: rgba(130, 130, 130, 0.2);
     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
     background-size: 800px 100px;
     animation:  shimmer 1.5s infinite linear;
}

.skeleton-left {
    flex:1;
}

.skeleton-balance {
     padding:15px 15px 15px 0;
     max-width: 300px;
     width: 135px;
     height: 10px;
     color: transparent; /* Texto transparente para simular un esqueleto */
     margin-top: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 4px;
}

.stats-block {
    box-shadow: 0 1px 4px #23232329;
    margin-bottom: 40px;
    background: #fff;
}
</style>