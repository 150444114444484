<template>
  <div id="app_holder">
  <div id="content">
    <Sidebar/>



    <div data-v-81e6c6ab="" data-v-eee2d117="" class="container">
      <div data-v-81e6c6ab="" class="notifications-container">
          <div data-v-81e6c6ab="" class="row mb32">
            <div data-v-81e6c6ab="" class="col-xs-12">
                <h2 data-v-81e6c6ab="">{{ $t('notifySection.title')}}</h2>
            </div>

            <div class="toast-container position-fixed top-50 start-50 translate-middle">
                    <div class="toast align-items-center border-0 fade" :class="{'show': showToastError ,'text-bg-danger': showToastError }" role="alert" aria-live="assertive" aria-atomic="true">
                        <div class="d-flex">
                            <div class="toast-body">
                                <p>{{errorMessage}}</p>
                            </div>
                            <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                    </div>
              </div>


              <div class="toast-container position-fixed top-50 start-50 translate-middle">
                      <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-success': showToast }" role="alert" aria-live="assertive" aria-atomic="true">
                          <div class="d-flex">
                              <div class="toast-body">
                                  <p>{{message}}</p>
                              </div>
                              <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                          </div>
                      </div>
              </div>


            <!-- <div data-v-81e6c6ab="" class="col-xs-6 text-right">
              <router-link data-v-81e6c6ab="" to="/my/settings/notification" class="btn secondary normal"><span data-v-81e6c6ab=""><i data-v-81e6c6ab="" class="fa fa-cog"></i> {{$t('notifySection.settings')}}</span></router-link>
            </div> -->
          </div>
          <div data-v-81e6c6ab="" class="list">
            <div class="overlay loader" v-if="isLoading">
                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
            </div>
            <div data-v-7a42a8f9="" data-v-81e6c6ab="" class="item big" v-for="(notify, index) in notifications" 
            :key="notify.id" 
            :class="{ 'read-notification': notify.is_read == 1 }"
            @click="notify.is_read == 0 && markAsRead(notify.id, index)">
                <div data-v-7a42a8f9="" class="icon">
                  <img data-v-7a42a8f9="" src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='34'%20height='34'%20viewBox='0%200%2034%2034'%3e%3cg%20fill='none'%20fill-rule='evenodd'%3e%3ccircle%20cx='16'%20cy='16'%20r='16'%20stroke='%23DBEED2'%20stroke-width='2'%20transform='translate(1%201)'/%3e%3cpath%20d='M9%209.003h16v16.32H9z'/%3e%3cg%20fill='%2368B641'%20fill-rule='nonzero'%3e%3cpath%20d='M20.805%2016.281l-.943-.961-2.195%202.24v-7.2h-1.334v7.2l-2.195-2.24-.943.961L17%2020.162zM21%2021.24v1.36h-8v-1.36h-1.333v1.36c0%20.75.597%201.36%201.333%201.36h8c.736%200%201.333-.61%201.333-1.36v-1.36H21z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e"><!---->
                </div>
                <div data-v-7a42a8f9="" class="text-wrap">{{ notify.message }}</div>
                <div data-v-7a42a8f9="" class="text-muted mt4">
                  <small data-v-7a42a8f9="">
                      <time >{{ convertDate(notify.created_at) }}</time>
                  </small>
                </div>
            </div>
          </div>
          <div data-v-82182c58="" class="empty-state" v-if="notifications.length === 0 && !isLoading">
            <div data-v-82182c58="" class="empty-content">
                <div data-v-82182c58="" class="empty-icon"><i data-v-82182c58="" class="far fa-file"></i></div>
                <div data-v-82182c58="" class="empty-title mt16 mb16">{{$t('notifySection.nodataTl')}}</div>
                <div data-v-82182c58="" class="empty-subtitle" v-html="$t('notifySection.nodataSub')"></div>
            </div>
          </div>
      </div>
    </div>
  </div>

    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue';
import axios from 'axios';
import moment from 'moment';
export default {
  name: 'CloudhashxFrontendNotifications',
    components: { Sidebar, FooterDash },
  data() {
    return {
      toastTimeout: null,
      showToastError: false,
      errorMessage: '',
      message: '',
      showToast: false,
      notifications: [],
      isLoading: false,
      selectedNotification: null, // Notificación seleccionada
      isModalVisible: false, // Controlar la visibilidad del modal
    };
  },
  destroyed() {
    clearTimeout(this.toastTimeout);
  },
  mounted() {
    this.getNotifications()
  },

  methods: {
    closeToast() {
        this.showToastError = false;
    },
    closeToastSc() {
        this.showToast = false;
    },
    async markAsRead(notificationId,index) {
      // Confirmación antes de marcar como leída
      if (!confirm("Are you sure you want to mark this notification as read?")) {
        return;
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}mark-as-read`, {
          id: notificationId,
        });

        if (response.data.success) {
          this.showToast = true;
                this.message = response.data.message;
                this.toastTimeout = setTimeout(() => {
                    this.showToast = false;
                    this.message = '';
                }, 5000);
          this.getNotifications()
          this.$store.dispatch('user/fetchNotifications');
          // Eliminar la notificación del listado
          //this.notifications.splice(index, 1);
        } else {
          alert("Failed to mark notification as read.");
        }
      } catch (error) {
        console.error(error);
        this.showToastError = true;
                this.errorMessage = error.response?.data?.error || 'An error occurred. Please try again.';
                this.toastTimeout = setTimeout(() => {
                    this.showToastError = false;
                    this.errorMessage = '';
                }, 5000);
        //alert("Something went wrong.");
      }
    },
    convertDate(date){
      return moment.utc(date).local().fromNow();
    },
    getNotifications() {
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL+'getNotifications')
      .then((res)=>{
        //console.log(res.data)
        this.notifications = res.data
        this.isLoading = false;
      })
      .catch((error)=>{
        console.log('error getting user notifications')
        this.isLoading = false;
      })
    },
  },
};
</script>
<style scoped>
.notifications-container[data-v-81e6c6ab] {
    width: 673px;
    margin: 0px auto;
}

@media only screen and (max-width: 768px) {
    .notifications-container[data-v-81e6c6ab] {
        width: 95%;
    }
}

.notifications-container .list[data-v-81e6c6ab] {
    background: rgb(255, 255, 255);
    box-shadow: rgba(35, 35, 35, 0.16) 0px 1px 4px;
}

.theme-dark .notifications-container .list[data-v-81e6c6ab] {
    background: rgb(3, 26, 42);
    border: 1px solid rgb(64, 73, 79);
}

.notifications-container .empty[data-v-81e6c6ab] {
    text-align: center;
    padding: 24px;
}

.notifications-container .empty .icon[data-v-81e6c6ab] {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 80px;
    background-color: rgb(254, 232, 208);
    margin: 0px auto 24px;
    background-image: url("data:image/svg+xml,%3csvg%20fill='none'%20height='30'%20viewBox='0%200%2030%2030'%20width='30'%20xmlns='http://www.w3.org/2000/svg'%3e%3cg%20fill='%23fbc241'%3e%3cpath%20d='m15%200h15v10h-15z'/%3e%3ccircle%20cx='5'%20cy='5'%20r='5'/%3e%3cpath%20d='m15%2030c8.2843%200%2015-6.7157%2015-15h-10c0%202.7614-2.2386%205-5%205s-5-2.2386-5-5h-10c0%208.2843%206.71572%2015%2015%2015z'/%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
}

.notifications-container .empty .icon.nicex[data-v-81e6c6ab] {
    background-image: url("data:image/svg+xml,%3csvg%20width='40'%20height='40'%20viewBox='0%200%2040%2040'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3ccircle%20cx='6.66667'%20cy='6.66667'%20r='6.66667'%20fill='%23FB8842'/%3e%3cpath%20d='M20%2010.6666L0%2030.5376L9.52381%2040L20%2029.5914L30.4762%2040L40%2030.5376L20%2010.6666Z'%20fill='%23FB8842'/%3e%3cpath%20d='M0%2030.5377L30.6666%200L40%209.65319L9.52381%2040.0001L0%2030.5377Z'%20fill='%23FB8842'/%3e%3c/svg%3e");
}

.notifications-container .empty h3[data-v-81e6c6ab] {
    font-size: 20px;
    color: rgb(58, 57, 57);
    font-weight: 700;
    margin-bottom: 16px;
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#app_holder.gray-bg {
    background: #f5f5f5;
}

.read-notification {
  opacity: 0.6;
  pointer-events: none;
}

</style>