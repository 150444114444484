<template>
  <div id="app_holder">
    <div id="content">
          <Sidebar/>

        <div class="container flex mb32 settings-panel">
          <SidebarSettings/>
          
          



        </div>

      </div><!-- content -->
    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue';
import SidebarSettings from './SidebarSettings.vue';
export default {
  name: 'CloudhashFrontendNotification',
  components: { FooterDash, SidebarSettings, Sidebar },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style scoped>
#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#app_holder.gray-bg {
    background: #f5f5f5;
}
</style>