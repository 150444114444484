<template>
  <div id="app_holder" data-v-eee2d117>
    <div id="content">
          <Sidebar/>

    </div>
    
  </div>
</template>

<script>
import Sidebar from '../Sidebar.vue';
export default {
  name: 'CloudhashxFrontendIndex',
  components: { Sidebar},
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>