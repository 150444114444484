<template>
<div>
  <!-- <header class="navbar navbar-expand-lg blur blur-rounded shadow bd-navbar" id="tnh">
    <div class="container">
      
      <router-link to="/" class="navbar-brand">
        <img src="@/assets/logo-cloud.svg" alt="CloudHashX" style="width: 160px;">
        
      </router-link>
      
      
      <button class="navbar-toggler" type="button" @click="toggleNavbar" v-click-outside="hide" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      
      <div class="collapse navbar-collapse" :class="{ 'show': isNavbarOpen }" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link to="/" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('home') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/pricing" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('pricing') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/insights" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('insights') }}</router-link>
          </li>
          
          <li class="nav-item">
            <router-link to="/faqs" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('faqsNav') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/affiliate" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('affiliate') }}</router-link>
          </li>

          <li class="nav-item">
            <router-link to="/mining-partners" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('partnerProgram') }}</router-link>
          </li>

          <li class="nav-item">
            <router-link to="/contact-us" class="nav-link" style="color: #262626;font-weight: 500;">{{ $t('contactUs') }}</router-link>
          </li>

        </ul>
      </div>
      <div class="btn-group">
        <template v-if="!autenticado">
          <router-link to="/login" class="btn btn-outline-dark" >{{ $t('login') }}</router-link>
          <router-link to="/sign-up" class="btn btn-dark register-btn" >{{ $t('signUp') }}</router-link>
        </template>
        <template v-else>
          <router-link to="/my/dashboard" class="btn btn-outline-dark" >{{ $t('dashboard') }}</router-link>
          <a href="javascript:void(0)" class="btn btn-dark register-btn" @click="onLogOut">{{ $t('logOut') }}</a>
        </template>

        <div class="dropdown ml-3 d-sm-block d-md-none">
          <button class="btn btn-secondary dropdown-toggle" @click="toggleDropdown" v-click-outside="hideDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedLanguage.label }}
          </button>
          <ul class="dropdown-menu" :class="{ 'show': isDropdownOpen }">
            <li v-for="(lang, index) in supportedLanguages" 
                    :key="index">
                    <a class="dropdown-item" @click="changeLocale(lang)" href="javascript:void(0)">{{ lang.label }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header> -->

  <nav :class="{ fixed: isFixed }" :style="$mq === 'mobile' ? 'top: 0px' : ''">
   <div class="container flex flex--align-vertical">
      <div class="logo pointer"></div>
      <div class="nav-links hidden-xs-down">
          <router-link to="/" class="nav-link">{{ $t('home') }}</router-link>
          <router-link to="/pricing" class="nav-link">{{ $t('pricing') }}</router-link>
          <router-link to="/insights" class="nav-link">{{ $t('insights') }}</router-link>
          <router-link to="/faqs" class="nav-link">{{ $t('faqsNav') }}</router-link>
          <router-link to="/affiliate" class="nav-link">{{ $t('affiliate') }}</router-link>
          <router-link to="/mining-partners" class="nav-link">{{ $t('partnerProgram') }}</router-link>
          <div class="right">
            <template v-if="!autenticado">
              <router-link to="/login"  >{{ $t('login') }}</router-link>
              <span class="spacer">|</span>
              <router-link to="/sign-up"  >{{ $t('signUp') }}</router-link>
            </template>
            <template v-else>
              <router-link to="/my/dashboard"  >{{ $t('dashboard') }}</router-link>
              <span class="spacer">|</span>
              <a href="javascript:void(0)" @click="onLogOut">{{ $t('logOut') }}</a>
            </template>
          </div>
      </div>
      <div class="mobile-menu" :class="{ 'open': isNavbarOpen }" @click="toggleNavbar">
         <div class="selected">
            <i class="fa" :class="isNavbarOpen ? 'fa-times icon' : 'fa-bars icon'" ></i>
         </div>
         <div class="options">
            <router-link to="/" class="nav-link">{{ $t('home') }}</router-link>
            <router-link to="/pricing" class="nav-link">{{ $t('pricing') }}</router-link>
            <router-link to="/insights" class="nav-link">{{ $t('insights') }}</router-link>
            <router-link to="/faqs" class="nav-link">{{ $t('faqsNav') }}</router-link>
            <router-link to="/affiliate" class="nav-link">{{ $t('affiliate') }}</router-link>
            <router-link to="/mining-partners" class="nav-link">{{ $t('partnerProgram') }}</router-link>
            <div class="right">
              <template v-if="!autenticado">
                <router-link to="/login"  >{{ $t('login') }}</router-link>
                <router-link to="/sign-up" >{{ $t('signUp') }}</router-link>
              </template>
              <template v-else>
                <router-link to="/my/dashboard" >{{ $t('dashboard') }}</router-link>
                <a href="javascript:void(0)" @click="onLogOut">{{ $t('logOut') }}</a>
              </template>
            </div>
          </div>
      </div>
   </div>
</nav>

</div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import clickOutside from '../directives/clickOutside';
import i18n from '@/i18n/index.js';
export default {
  name: 'CloudhashFrontendHeader',

  data() {
    return {
      isFixed: false,
      typingStarted: false,
      isNavbarOpen: false,
      supportedLanguages: [
          { label: 'English', value: 'en', flag: 'gb' },
          { label: 'Español', value: 'es', flag: 'es' }
        ],
        isDropdownOpen: false
    };
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
      ...mapGetters(['locale', 'supportedLanguages2']), // Obtener el idioma seleccionado y los idiomas admitidos desde Vuex
    selectedLanguage: {
      get() {
        // Devolver el idioma seleccionado del array de idiomas admitidos según el estado de Vuex
        return this.supportedLanguages.find(lang => lang.value === this.locale);
      },
      set(value) {
        // Podemos añadir lógica aquí si necesitamos actualizar selectedLanguage desde el componente
      }
    },
  },
  watch: {
    locale(newLocale) {
      this.selectedLanguage = this.supportedLanguages2.find(lang => lang.value === newLocale);
    }
  },
  directives: {
    clickOutside,
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    i18n.locale = this.locale;
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Detectar si el usuario hizo scroll más de 50px
      this.isFixed = window.scrollY > 50;
    },
    ...mapActions(['setLocale']),
    changeLocale(locale) {
        i18n.locale = locale.value;
        this.setLocale(locale.value);
        this.isDropdownOpen = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    hideDropdown() {
      this.isDropdownOpen = false;
    },
    hide() {
        this.isNavbarOpen = false;
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    ...mapActions({
        logout: 'user/logout'
    }),
    onLogOut() {
        this.logout().then((res) => {
            this.$router.push({ name: "Login" });
        })
    },
  },
};
</script>
<style scoped>
.navbar-toggler {
    background-color: white;
}

.register-btn {
  border-bottom-right-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

/* #tnh {
    position: fixed;
    width: 100%;
    height: 72px;
    background: #fff;
    z-index: 9999;
}

#tnh #tnl a, #tnh #tnl .elnk {
    color: #262626;
    font-weight: 500;
} */

@media only screen and (max-width: 768px){
nav {
    display: block;
    width: 100%;
    height: 44px;
    padding: 16px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10
}
}

nav {
    position: fixed;
    top: 52px; /* Ajustamos para que quede debajo del top-bar */
    left: 0;
    width: 100%;
    height: 44px;
    padding: 16px 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0 4px 4px #00000040;
    transition: all .3s ease-in-out;
}

nav .logo {
    width: 96px;
    height: 20px;
    background-image: url(../assets/logo-cloud.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 44px;
}

nav.fixed .logo {
    background-image: url(../assets/logo-cloud.svg);
}

nav .nav-links {
    display: flex;
    -moz-column-gap: 32px;
    column-gap: 32px;
    font-size: 14px;
}

nav .mobile-menu {
    display: none;
    border-bottom: 2px solid transparent;
}

nav .nav-links a {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #000;
    text-decoration: none;
}

nav.fixed {
    background-color: #fff;
    box-shadow: 0 4px 4px #00000040;
    transition: all .3s ease-in-out;
}

nav.fixed .nav-links a {
    color: #000;
}

@media only screen and (max-width: 768px){
  nav .mobile-menu {
      display: inline-flex;
  }
}

nav.fixed .mobile-menu .selected {
    color: #000;
}

nav .mobile-menu .selected {
    height: 22px;
    line-height: 22px;
    padding: 0 0 0 24px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
    overflow: hidden;
}

@media only screen and (max-width: 768px){
  nav .container {
      justify-content: space-between;
  }
}

nav .mobile-menu .options {
    display: none;
    background: #fff;
    padding: 12px 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 44px;
    box-shadow: 0 8px 16px #23232329;
    z-index: 2;
}

nav .mobile-menu.open .options {
    display: block;
}

nav .mobile-menu .options a {
    display: block;
    padding: 12px 24px;
    color: #3a3939;
    font-weight: 500;
}

nav .mobile-menu .selected i {
    width: 20px;
    font-size: 18px;
}

@media only screen and (max-width: 1199px){
  nav .options .right {
      text-align: center;
      margin-top: 8px;
      padding-right: 0;
      display: flex;
      align-items: center;
      line-height: 32px;
      justify-content: center;
  }
}

@media only screen and (max-width: 480px){
  nav .options .right>div, nav .options .right a {
      margin-left: 8px;
      margin-right: 8px;
  }
}

nav .right>div, nav .right a {
    margin-left: 16px;
    margin-right: 16px;
    padding-right: 0;
    padding-left: 0;
}

nav .right span.spacer {
    color: #fff;
    font-size: 10px;
}

nav .right {
    padding-right: 16px;
}
</style>