<template>
  <div>
    <section class="slider-element " :class="$mq !== 'mobile' ? 'py-2' : ''">
      
  <div class="page">

    <div class="row">
      <div class="col">
        <div class="intro-cloudhashx">
          <img class="logo mb48" src="@/assets/logo-white.svg" alt="CloudHashX">
          <div class="limit-width">
            <div v-html="$t('leader')"></div>
            <h2>{{$t('earnUpTo12Percent')}}</h2>
            <template v-if="!autenticado">
              <router-link to="/sign-up" class="button bg-white text-dark button-light button-rounded button-large color ms-0">{{$t('signUpNow')}} <i class="fa fa-arrow-right fw-semibold"></i></router-link>
            </template>
            <template v-else>
              <router-link to="/my/dashboard" class="button bg-white text-dark button-light button-rounded button-large color ms-0">{{$t('dashboard')}} <i class="fa fa-arrow-right fw-semibold"></i></router-link>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center slider-box-wrap">
      <div class="col-10">
        <div class="slider-bottom-box package gold">
          <div class="row align-items-center">
            <div class="col-lg-4 mb-3 mb-lg-0">
              <h2 class="mb-3 h1 fw-light">{{$t('features')}}</h2>
              <p class="fw-normal text-muted mb-0">{{$t('featuresDescription')}}</p>
            </div>
            <div class="col-lg-4 col-sm-6">
              <ul class="iconlist m-0">
                <li><i class="fas fa-check-circle me-2 text-primary"></i>{{ $t('guaranteedMiningPower')}}</li>
                <li class="pt-3"><i class="fas fa-check-circle text-primary me-2"></i>{{ $t('advancedSecurity')}}</li>
                <li class="pt-3"><i class="fas fa-check-circle text-primary me-2"></i>{{ $t('withdrawalFlexibility')}}</li>
              </ul>
            </div>
            <div class="col-lg-4 col-sm-6">
              <ul class="iconlist m-0">
                <li class="pt-3 pt-lg-0"><i class="fas fa-check-circle text-primary me-2"></i>{{ $t('support24_7')}}</li>
                <li class="pt-3"><i class="fas fa-check-circle text-primary me-2"></i>{{ $t('regularUpdates')}}</li>
                <li class="pt-3"><i class="fas fa-check-circle text-primary me-2"></i>{{ $t('intuitiveUserExperience')}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
</section>


      <section id="content" class="mining">
        <div class="content-wrap py-0">
          <div class="container">
            <div class="heading-block text-center mt-6 mx-auto border-bottom-0" style="max-width: 700px">
              <h2>{{$t('how_to_title')}}</h2>
            </div>

            <div class="row col-mb-50 mb-0 mt-4">
              <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                  <lottie-vue-player src="images/animations/section2_img1.json"
                           :player-controls="false"
                           background="transparent"
                           loop
                           autoplay
                           class="w-full h-48 mx-auto">
                  </lottie-vue-player>
                <div class="fbox-content">
                  <h3 class="fw-medium text-dark">{{ $t('signup_title') }}</h3>
                  <p class="fw-light">{{ $t('signup_description') }}</p>
                </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                  <lottie-vue-player src="images/animations/section2_img2.json"
                           :player-controls="false"
                           background="transparent"
                           loop
                           autoplay
                           class="w-full h-48 mx-auto">
                  </lottie-vue-player>
                <div class="fbox-content">
                  <h3 class="fw-medium text-dark">{{ $t('deposit_title') }}</h3>
                  <p class="fw-light">{{ $t('deposit_description') }}</p>
                </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                  <lottie-vue-player src="images/animations/section2_img3.json"
                           :player-controls="false"
                           background="transparent"
                           loop
                           autoplay
                           class="w-full h-48 mx-auto">
                  </lottie-vue-player>
                <div class="fbox-content">
                  <h3 class="fw-medium text-dark">{{ $t('profits_title') }}</h3>
                  <p class="fw-light">{{ $t('profits_description') }}</p>
                </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center mb32 mt8 estimate">
              <router-link to="/pricing" class="btn normal ternary"> {{$t('calculateFuture')}} </router-link>
            </div>

          </div>
        </div>
    </section>

    <section id="rewards-section" class="mining">
    <!-- <div class="container text-center"> -->
      <div class="content-wrap py-0">
          <div class="container">
            <div class="heading-block text-center mt-6 mx-auto border-bottom-0" style="max-width: 700px">
              <h2 class="section-title">{{ $t('rewardsSection.title') }}</h2>
              <p class="section-description mt-4">{{ $t('rewardsSection.subtitle') }}</p>
            </div>
        

        <div class="row col-mb-50 mb-0 mt-4">
            <!-- Tarjeta: Inicio de Sesión Diario -->
              <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                  <div class="fbox-icon">
                    <i class="fas fa-calendar-check"></i>
                  </div>
                  <div class="fbox-content">
                    <h3 class="fw-medium text-dark">{{ $t('rewardsSection.dailyLogin') }}</h3>
                    <p class="fw-light">{{ $t('rewardsSection.dailyLoginDescription') }}</p>
                  </div>
                </div>
            </div>

            <!-- Tarjeta: Beneficios -->
            <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                  <div class="fbox-icon">
                    <i class="fas fa-coins reward-icon"></i>
                  </div>
                  <div class="fbox-content">
                    <h4 class="reward-title">{{ $t('rewardsSection.benefits') }}</h4>
                    <p class="reward-description">{{ $t('rewardsSection.benefitsDescription') }}</p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta: Mantén tu Racha -->
            <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                  <div class="fbox-icon">
                    <i class="fas fa-fire reward-icon"></i>
                  </div>
                    <div class="fbox-content">
                      <h4 class="reward-title">{{ $t('rewardsSection.streak') }}</h4>
                      <p class="reward-description">{{ $t('rewardsSection.streakDescription') }}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                    <div class="fbox-icon">
                      <i class="fas fa-wallet reward-icon"></i>
                    </div>
                    <div class="fbox-content">
                      <h4 class="reward-title">{{ $t('rewardsSection.deposit') }}</h4>
                      <p class="reward-description">{{ $t('rewardsSection.depositDescription') }}</p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta: Invita Amigos -->
            <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                    <div class="fbox-icon">
                      <i class="fas fa-user-friends reward-icon"></i>
                    </div>
                    <div class="fbox-content">
                      <h4 class="reward-title">{{ $t('rewardsSection.referrals') }}</h4>
                      <p class="reward-description">{{ $t('rewardsSection.referralsDescription') }}</p>
                    </div>
                </div>
            </div>


        </div>
    </div>
    </div>
</section>







    <section id="content" class="mining">
        <div class="content-wrap py-0">
          <div class="container">
            <div class="heading-block text-center mt-6 mx-auto border-bottom-0" style="max-width: 700px">
              <h2 class="section-title">{{$t('bestServicesTitle')}}</h2>
              <p class="section-description mt-4">{{$t('bestServicesDescription')}}</p>
            </div>

            <div class="row col-mb-50 mb-0 mt-4">
              <div class="col-lg-4 col-md-6">
                <div class="feature-box not-dark">
                <div class="fbox-icon">
                  <i class="fas fa-shield-alt"></i>
                </div>
                <div class="fbox-content">
                  <h3 class="fw-medium text-dark">{{ $t('guaranteedMiningPower')}}</h3>
                  <p class="fw-light">{{$t('guaranteedMiningPowerDescription')}}</p>
                </div>
                </div>
              </div>

            <div class="col-lg-4 col-md-6">
              <div class="feature-box not-dark">
                <div class="fbox-icon">
                  <i class="fas fa-lock"></i>
                </div>
                <div class="fbox-content">
                  <h3 class="fw-medium text-dark">{{ $t('advancedSecurity')}}</h3>
                  <p class="fw-light">{{$t('advancedSecurityDescription')}}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
            <div class="feature-box not-dark">
            <div class="fbox-icon">
            <i class="fas fa-money-bill-wave"></i>
            </div>
            <div class="fbox-content">
            <h3 class="fw-medium text-dark">{{ $t('withdrawalFlexibility')}}</h3>
            <p class="fw-light">{{$t('withdrawalFlexibilityDescription')}}</p>
            </div>
            </div>
            </div>

            <div class="col-lg-4 col-md-6">
            <div class="feature-box not-dark">
            <div class="fbox-icon">
            <i class="fas fa-headset"></i>
            </div>
            <div class="fbox-content">
            <h3 class="fw-medium text-dark">{{ $t('support24_7')}}</h3>
            <p class="fw-light">{{$t('support24_7Description')}}</p>
            </div>
            </div>
            </div>

            <div class="col-lg-4 col-md-6">
            <div class="feature-box not-dark">
            <div class="fbox-icon">
            <i class="fas fa-sync"></i>
            </div>
            <div class="fbox-content">
            <h3 class="fw-medium text-dark">{{ $t('regularUpdates')}}</h3>
            <p class="fw-light">{{$t('regularUpdatesDescription')}}</p>
            </div>
            </div>
            </div>

            <div class="col-lg-4 col-md-6">
            <div class="feature-box not-dark">
            <div class="fbox-icon">
            <i class="fas fa-user-check"></i>
            </div>
            <div class="fbox-content">
            <h3 class="fw-medium text-dark">{{ $t('intuitiveUserExperience')}}</h3>
            <p class="fw-light">{{$t('intuitiveUserExperienceDescription')}}</p>
            </div>
            </div>
            </div>
            </div>

            

          </div>
        </div>
    </section>


    <!-- <div class="locations">
  <div class="container">
    <h2 class="mb32">{{$t('ourDataCenter')}}
    </h2>
    <div class="row mb40">
      <div class="col-sm-6">
        <p>{{$t('dataCenterDescription')}}</p>
        
      </div>
      <div class="col-sm-6 text-center">
        <div class="text-center text-uppercase text-bold mb16">24 {{$t('locationsLower')}}</div>
        <div class="btn-group mb-4">
            <button class="btn secondary normal" :class="{ 'unselected': selectedLocation !== 'ASIA', 'selected': selectedLocation === 'ASIA' }" @click="filterLocations('ASIA')">ASIA</button>
            <button class="btn secondary normal" :class="{ 'unselected': selectedLocation !== 'EUROPE', 'selected': selectedLocation === 'EUROPE' }" @click="filterLocations('EUROPE')">EUROPE</button>
            <button class="btn secondary normal" :class="{ 'unselected': selectedLocation !== 'N. AMERICA', 'selected': selectedLocation === 'N. AMERICA' }" @click="filterLocations('N. AMERICA')">N. AMERICA</button>
            <button class="btn secondary normal" :class="{ 'unselected': selectedLocation !== 'S. AMERICA', 'selected': selectedLocation === 'S. AMERICA' }" @click="filterLocations('S. AMERICA')">S. AMERICA</button>
            <button class="btn secondary normal" :class="{ 'unselected': selectedLocation !== 'AUSTRALIA', 'selected': selectedLocation === 'AUSTRALIA' }" @click="filterLocations('AUSTRALIA')">AUSTRALIA</button>
        </div>
        <div class="selectbox">
          <div class="selectbox" tabindex="0">
            <div class="field" aria-haspopup="true" aria-expanded="false">
              <div class="arrows">
                <i class="fa fa-caret-up"></i>
                <i class="fa fa-caret-down"></i>
              </div>AUSTRALIA
            </div>
            <div class="dropdown flex flex--no-wrap">
              <div class="options" role="listbox">
                <div class="option" role="option" aria-selected="false">ASIA</div>
                <div class="option" role="option" aria-selected="false">EUROPE</div>
                <div class="option" role="option" aria-selected="false">N. AMERICA</div>
                <div class="option" role="option" aria-selected="false">S. AMERICA</div>
                <div class="option selected" role="option" aria-selected="true">AUSTRALIA</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          
          <template v-if="selectedLocation === 'AUSTRALIA'">
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('dataCenterTl')}}</strong>
                <div class="mb-2 text12">australia-southeast1</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('hashAvailableTl')}}</strong>
                <div class="mb-2 text12">315 PH/s</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('locationAndRegionTl')}}</strong>
                <div class="mb-2 text12">Sydney, Australia</div>
              </div>
            </div>
          </template>
          <template v-else-if="selectedLocation === 'N. AMERICA'">
            <div  class="col-md-4">
              <div  class="mb-3 mt-3">
                <strong >{{$t('dataCenterTl')}}</strong>
              <div  class="mb-2 text12">us-central1</div>
              <div  class="mb-2 text12">us-east1</div>
              <div  class="mb-2 text12">us-east4</div>
              <div  class="mb-2 text12">us-west1</div>
              <div  class="mb-2 text12">us-west2</div>
              <div  class="mb-2 text12">us-west3</div>
              <div  class="mb-2 text12">us-west4</div>
              <div  class="mb-2 text12">us-north1</div>
            </div>
            </div>
            <div  class="col-md-4">
              <div class="mb-3 mt-3">
                  <strong>{{$t('hashAvailableTl')}}</strong>
                  <div  class="mb-2 text12">313 PH/s</div>
                  <div  class="mb-2 text12">313 PH/s</div>
                  <div  class="mb-2 text12">313 PH/s</div>
                  <div  class="mb-2 text12">313 PH/s</div>
                  <div  class="mb-2 text12">313 PH/s</div>
                  <div  class="mb-2 text12">313 PH/s</div>
                  <div  class="mb-2 text12">313 PH/s</div>
                  <div  class="mb-2 text12">313 PH/s</div>
              </div>
            </div>
            <div  class="col-md-4">
              <div  class="mb-3 mt-3">
                <strong >{{$t('locationAndRegionTl')}}</strong>
              <div  class="mb4 text12">Council Bluffs, Iowa, USA</div>
              <div  class="mb4 text12">Moncks Corner,  USA</div>
              <div  class="mb4 text12">Ashburn,  USA</div>
              <div  class="mb4 text12">The Dalles, Oregon, USA</div>
              <div  class="mb4 text12">Los Angeles, California, USA</div>
              <div  class="mb4 text12">Salt Lake City, Utah, USA</div>
              <div  class="mb4 text12">Las Vegas, Nevada, USA</div>
              <div  class="mb4 text12">Montréal, Québec, Canada</div>
            </div>
            </div>

          </template>
          <template v-else-if="selectedLocation === 'S. AMERICA'">
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('dataCenterTl')}}</strong>
                <div class="mb-2 text12">southamerica-east1</div>
                
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('hashAvailableTl')}}</strong>
                <div class="mb-2 text12">310 PH/s</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('locationAndRegionTl')}}</strong>
                <div class="mb-2 text12">Osasco (São Paulo), Brazil</div>
              </div>
            </div>
          </template>
          <template v-else-if="selectedLocation === 'EUROPE'">
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('dataCenterTl')}}</strong>
                <div class="mb-2 text12">europe-north1</div>
                <div class="mb-2 text12">europe-west1</div>
                <div class="mb-2 text12">europe-west2</div>
                <div class="mb-2 text12">europe-west3</div>
                <div class="mb-2 text12">europe-west4</div>
                <div class="mb-2 text12">europe-west5</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('hashAvailableTl')}}</strong>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('locationAndRegionTl')}}</strong>
                <div class="mb-2 text12">Hamina, Finland</div>
                <div class="mb-2 text12">St. Ghislain, Belgium</div>
                <div class="mb-2 text12">London, England, UK</div>
                <div class="mb-2 text12">Frankfurt, Germany</div>
                <div class="mb-2 text12">Eemshaven, Netherlands</div>
                <div class="mb-2 text12">Zürich, Switzerland</div>
              </div>
            </div>
          
          </template>
          <template v-else-if="selectedLocation === 'ASIA'">
            
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('dataCenterTl')}}</strong>
                
                <div class="mb-2 text12">asia-east1</div>
                <div class="mb-2 text12">asia-east2</div>
                <div class="mb-2 text12">asia-northeast1</div>
                <div class="mb-2 text12">asia-northeast2</div>
                <div class="mb-2 text12">asia-northeast3</div>
                <div class="mb-2 text12">asia-south1</div>
                <div class="mb-2 text12">asia-southeast1</div>
                <div class="mb-2 text12">asia-southeast2</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('hashAvailableTl')}}</strong>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">4800 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
                <div class="mb-2 text12">313 PH/s</div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3 mt-3">
                <strong>{{$t('locationAndRegionTl')}}</strong>
                <div class="mb-2 text12">Changhua County, Taiwan</div>
                <div class="mb-2 text12">Hong Kong</div>
                <div class="mb-2 text12">Tokyo, Japan</div>
                <div class="mb-2 text12">Osaka, Japan</div>
                <div class="mb-2 text12">Seoul, South Korea</div>
                <div class="mb-2 text12">Mumbai, India</div>
                <div class="mb-2 text12">Jurong West, Singapore</div>
                <div class="mb-2 text12">Jakarta, Indonesia</div>

                
              </div>
            </div>
            
          </template>
          
          
        </div>

        
      </div>
    </div>
  </div>
</div> -->

<!--Section -->
       	<!-- <div class="section section-pad mining">
       		<div class="container">
       			<div class="section-head">
					
          <div class="heading-block text-center mt-6 mx-auto border-bottom-0" style="max-width: 700px">
              <h2 class="section-title">{{$t('bestServicesTitle')}}</h2>
              <p class="section-description mt-4">{{$t('bestServicesDescription')}}</p>
            </div>
       			</div>
       			<div class="gaps size-3x"></div>
				<div class="row text-center row-vm">
					<div class="col-md-4 res-m-bttm-lg">
						<div class="box-alt">
              <span class="fas fa-shield-alt"></span>
							
							<h4>Payment Options</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
						<div class="box-alt">
							<span class="pe pe-7s-note"></span>
							<h4>Legal Compliance</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
						<div class="box-alt">
							<span class="pe pe-7s-airplay"></span>
							<h4>Cross-Platform Trading</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
					</div>
					<div class="col-md-4 res-m-bttm-lg">
						<div class="box-alt">
							<span class="pe pe-7s-lock"></span>
							<h4>Strong Security</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
						<div class="box-alt img">
							<img src="@/assets/images/square-md-a.png" alt="square">
						</div>
						<div class="box-alt">
							<span class="pe pe-7s-cash"></span>
							<h4>Competitive Commissions</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
					</div>
					<div class="col-md-4">
						<div class="box-alt">
							<span class="pe pe-7s-global"></span>
							<h4>World Coverage</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
						<div class="box-alt">
							<span class="pe pe-7s-graph"></span>
							<h4>Advanced Reporting</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
						<div class="box-alt">
							<span class="pe pe-7s-graph1"></span>
							<h4>Margin Trading</h4>
							<p>Morbi eget varius risus, ut venenatis libero Pellentesque in porta dui.</p>
						</div>
					</div>
				</div>
       		</div>
       	</div> -->
       	<!--End Section -->


    <section class="slider-element m-0" style="padding: 10px 0px;">
      <div class="container">
      <div class="row justify-content-center mt-3">
        <div class="col-md-7 dotted-bg">
        <h1 class="text-center  mb-5 mt-4 fw-semibold">{{$t('faqTitle')}}</h1>
        <div class="toggle-wrap">
          <div class="toggle" v-for="(faq, key) in faqs" :key="key">
          <div class="toggle-header" @click="toggleFAQ(key)">
            <div class="toggle-icon">
              <i class="toggle-closed fa-solid fa-angle-right " v-if="!faq.open"></i>
              <i class="toggle-open fa-solid fa-angle-down " v-if="faq.open"></i>
            </div>
            <div class="toggle-title ">
              {{ $t(faq.question) }}
            </div>
          </div>
          <div class="toggle-content " :style="faq.open ? 'display: block;' : 'display: none;'">
            {{ $t(faq.answer) }}
          </div>
        </div>
      </div>
        <h2 class="mt-4 text-center fw-normal  mb-0">{{$t('faqNotFound')}} <router-link to="/faqs"><u>{{$t('visitFAQs')}}</u></router-link></h2>
        </div>
        </div>
      </div>
    </section>

    

      <Footer/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Footer from './Footer.vue';
export default {
  name: 'CloudhashFrontendHome',
  components: { Footer },
  data() {
    return {
        selectedLocation: 'AUSTRALIA',
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/1.svg')})`
      },
      faqs: {
        faq1: {
          question: 'faq1.question',
          answer: 'faq1.answer',
          open: false
        },
        faq2: {
          question: 'faq2.question',
          answer: 'faq2.answer',
          open: false
        },
        faq3: {
          question: 'faq3.question',
          answer: 'faq3.answer',
          open: false
        },
        faq4: {
          question: 'faq4.question',
          answer: 'faq4.answer',
          open: false
        },
        // Agrega más preguntas y respuestas aquí...
      },
      
    };
  },

  mounted() {
    
  },
  computed: {
    ...mapGetters({
          autenticado: 'user/autenticado',
          user: 'user/usuario'
      }),
  },
  methods: {
    toggleFAQ(key) {
      Object.keys(this.faqs).forEach(k => {
        if (k !== key) {
          this.faqs[k].open = false;
        }
      });
      this.faqs[key].open = !this.faqs[key].open;
    },
    filterLocations(region) {
        this.selectedLocation = region;
    }
  },
};
</script>
<style scoped>
.py-2 {
    padding-top: 96px !important;
}
.section5_video {
    position: absolute;
    top: -20px;
    left: -100px;
    width: 320px;
    height: 590px;
}

.slider-bottom-box {
    display: block;
    padding: 40px;
    background: #fff;
    box-shadow: 0 10px 40px 0 rgba(47,47,47,.1);
    border-radius: 3px;
}

a:not(.btn-link):not(.text-decoration-underline):not(.more-link) {
    text-decoration: none!important;
}

.button.button-large {
    --cnvs-btn-padding-x: 1.625rem;
    --cnvs-btn-padding-y: 0.5rem;
    --cnvs-btn-fontsize: 1.125rem;
}

.button.button-light {
    --cnvs-btn-color: var(--cnvs-btn-color-light);
    color: var(--cnvs-btn-color-dark);
}

.button {
    --cnvs-btn-padding-x: 1.375rem;
    --cnvs-btn-padding-y: 0.5rem;
    --cnvs-btn-fontsize: 1rem;
    --cnvs-btn-color: var(--cnvs-themecolor);
    --cnvs-btn-color-dark: var(--bs-gray-900);
    --cnvs-btn-color-light: #e9ecef;
    --cnvs-btn-color-hover: var(--cnvs-btn-color-dark);
    --cnvs-btn-lineheight: calc(var(--cnvs-btn-fontsize) * 1.6);
    --cnvs-btn-border-width: 1px;
    --cnvs-btn-icon-margin: 10px;
    --cnvs-btn-icon-margin-offset: 0px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    margin: 5px;
    padding: var(--cnvs-btn-padding-y) var(--cnvs-btn-padding-x);
    font-size: var(--cnvs-btn-fontsize);
    line-height: var(--cnvs-btn-lineheight);
    text-align: center;
    background-color: var(--cnvs-btn-color);
    color: #fff;
    font-weight: 500;
    border: var(--cnvs-btn-border-width) solid transparent;
    transition: all .2s ease-in-out;
}

.button-rounded {
    border-radius: 0.25rem;
}

.iconlist {
    --cnvs-iconlist-size: 1rem;
    --cnvs-iconlist-color: #3883dd;
    --cnvs-iconlist-margin: 0.5rem;
    --cnvs-iconlist-gutter: 0.75rem;
    --cnvs-iconlist-offset: 0;
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
}

.heading-block {
    --cnvs-heading-block-font-size-h1: 2.5rem;
    --cnvs-heading-block-font-size-h2: 2.125rem;
    --cnvs-heading-block-font-size-h3: 1.875rem;
    --cnvs-heading-block-font-size-h4: 1.375rem;
    --cnvs-heading-block-font-weight: 600;
    --cnvs-heading-block-font-spacing: 0;
    --cnvs-heading-block-font-color: var(--cnvs-heading-color);
    --cnvs-heading-block-font-transform: none;
    --cnvs-heading-block-center-container: 700px;
    --cnvs-title-block-border-size: 40px;
    --cnvs-heading-block-border: 2px solid var(--cnvs-heading-color);
    --cnvs-heading-block-span-color: var(--cnvs-contrast-600);
    margin-bottom: 50px;
}

.mt-6 {
    margin-top: 5rem!important;
}

.cloud-wrap {
    /*opacity: .05;  Ajustar la opacidad */
    pointer-events: none; /* Desactivar eventos de ratón */
}

.col-mb-50 {
    --cnvs-col-mb: 50px;
}

.mb32 {
    margin-bottom: 32px;
}

h2 strong {
    color: rgb(25, 52, 93);
}


.mb40 {
    margin-bottom: 40px;
}

.mb16 {
    margin-bottom: 16px;
}

.locations .btn-group .btn.secondary {
    padding-left: 16px;
    padding-right: 16px;
}

.btn-group .unselected {
    background: #eee;
    color: #464444;
    border-radius: 4px 0 0 4px;
    box-shadow: none;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn-group .selected {
    box-shadow: 0 4px 12px #23232329;
    color: #fba342;
}

.locations .selectbox {
    display: none;
}

div.selectbox {
    position: relative;
}

.mt32 {
    margin-top: 32px;
}

.text12 {
  font-size: 12px;
}

.mb4 {
    margin-bottom: 4px;
}

@media only screen and (min-width: 481px){
  .col-xs-6 {
      max-width: 50%;
      flex-basis: 50%;
  }
}

@media only screen and (min-width: 481px) {
  .col-xs-4 {
    max-width: 33.33333333%;
    flex-basis: 33.33333333%;
  }
}

@media (max-width: 991px){
  .img-map {
      display: none;
  }
}

@media only screen and (max-width: 991px){
.locations .btn-group {
    display: none;
}
}

.estimate {
    padding: 0 32px;
}

.btn.normal {
    padding: 13px 24px 12px;
    font-size: 12px;
    letter-spacing: .5px;
}

.btn.secondary {
    background: #fff;
    color: #3a3939;
    box-shadow: 0 1px 4px #23232329;
}

.btn.ternary {
    background: #002BAE;
    color: #fff;
    box-shadow: 0 1px 4px #23232329;
}

.btn.ternary:hover:not(.disabled) {
    box-shadow: 0 4px 12px #23232329
}

.btn.secondary:hover:not(.disabled) {
    box-shadow: 0 4px 12px #23232329
}

.btn {
    border-radius: 4px;
    border: 0;
    padding: 10px 12px 9px;
    transition: box-shadow .2s ease,border-radius .2s ease,border-radius .2s ease,background-color .2s ease,color .2s ease;
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mb32 {
    margin-bottom: 32px;
}

.mt8 {
    margin-top: 8px;
}

.section-pad {
    padding-top: 90px;
    padding-bottom: 90px;
}

.gaps.size-3x {
    height: 60px;
}

.gaps {
    clear: both;
    height: 20px;
    display: block;
    margin: 0;
}

.row-vm {
    align-items: center;
    display: flex;
}

.box-alt {
    padding: 0 30px 70px;
}

.box-alt:last-of-type {
    padding-bottom: 0;
}


.box.box-small-dot:hover .font-icon:before {
    background: #fff;
    opacity: .4
}

.box.box-small-dot .font-icon {
    margin-bottom: 17px;
    display: inline-block;
    position: relative
}

.box.box-small-dot .font-icon:before {
    position: absolute;
    content: "";
    background: #f7921a;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    top: 8px;
    left: 0;
    opacity: .6;
    z-index: 4;
    transition: all .5s ease
}

.box.box-small-dot .font-icon .pe {
    font-size: 42px;
    position: relative;
    z-index: 5;
    transition: all .5s ease
}

.box h4,.box h6 {
    margin-bottom: 14px;
    transition: all .5s ease
}

.box p {
    transition: all .5s ease
}

.box-alt {
    padding: 0 30px 70px
}

.box-alt:last-of-type {
    padding-bottom: 0
}

.box-alt .fas {
    color: #f7921a;
    font-size: 42px;
    padding-bottom: 15px
}

.box-alt .image-icon {
    position: relative;
    width: 50px;
    margin: 0 auto 23px
}

.box-alt .image-icon img {
    position: relative;
    z-index: 5
}

.box-alt.box-dot {
    position: relative
}

.box-alt.box-dot.four-column {
    padding: 0 0 70px
}

.box-alt.box-dot .image-icon:before {
    position: absolute;
    content: "";
    background: #f7921a;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    top: -4px;
    left: -10px;
    opacity: .6;
    z-index: 4
}

.box-alt.box-direction:after {
    position: absolute;
    left: calc(50% + 10px);
    width: 100%
}

img {
    outline: 0;
    border: 0 none;
    max-width: 100%;
    height: auto;
    vertical-align: top;
    -ms-border-radius: 5px;
    border-radius: 5px;
}

.heading-section, .heading-lead {
    margin-bottom: 20px;
}

.heading-section {
    line-height: 1.1;
    text-transform: uppercase;
    color: #373e45;
    font-weight: 500;
}
</style>