<template>
  <div id="app_holder">
    
    <div id="content">
        <Sidebar/>

        <div data-v-74541b3f="" data-v-cc4eb09c="" class="container">

   <div data-v-74541b3f="" class="wallet-container">
      <div data-v-74541b3f="" class="sidebar">
         <router-link data-v-74541b3f="" to="/my/balances" class="router-link-active back"><i data-v-74541b3f="" class="fa fa-angle-left"></i> {{$t('backToBalances')}}</router-link>
         <div data-v-74541b3f="" class="wallets hidden-xs-down">
            <span data-v-74541b3f="" class="wallet selected" >
               <div data-v-74541b3f="" class="name"><img data-v-74541b3f="" :src="getIconPath(currencyName)"><span data-v-74541b3f="" class="text-bold">{{convertName(currencyName)}}</span></div>
               <div data-v-74541b3f="" class="values"><small data-v-74541b3f="" class="text-medium">{{ isBalanceHidden ? '***' : valueInCrypto }}</small><small data-v-74541b3f="" class="text-muted pt4">$ {{ isBalanceHidden ? '***' : valueInFiat}}</small></div>
            </span>
         </div>
      </div>
      <div data-v-74541b3f="" class="content">
         <div data-v-74541b3f="" class="row mb24">
            
            <div data-v-74541b3f="" class="col-md-5">
               <h2 data-v-74541b3f="" class="inline-block mr16">{{convertName(currencyName)}}</h2>
            </div>
         </div>
         <div data-v-74541b3f="" class="coin-header">
            <div data-v-74541b3f="" class="balance">
               <label data-v-74541b3f="">Total {{convertName(currencyName)}}</label> 
               <div data-v-74541b3f="" class="value">{{ isBalanceHidden ? '***' : valueInCrypto }} <span data-v-74541b3f="" class="currency">{{currencyName}}</span></div>
               <small data-v-74541b3f="">$ {{ isBalanceHidden ? '***' : valueInFiat}}</small>
            </div>
            <div data-v-74541b3f="" class="flex flex--row">
               <div data-v-74541b3f="" class="available">
                  <label data-v-74541b3f="">{{$t('walletSection.available')}}</label> 
                  <i data-v-02f89b65="" data-v-74541b3f="" v-tooltip="$t('availableBalance')" class="fa fa-info-circle info v-popper--has-tooltip ml-2"></i>
                  <div data-v-74541b3f="" class="value small">{{ isBalanceHidden ? '***' : valueInCrypto }} <span data-v-74541b3f="" class="currency">{{currencyName}}</span></div>
               </div>
               <!-- <div data-v-74541b3f="" class="pending">
                  <div data-v-74541b3f="" class="row">
                     <div data-v-74541b3f="" class="col-md-6">
                        <label data-v-74541b3f="">Pending</label> 
                        <div data-v-74541b3f="" class="value small">{{ isBalanceHidden ? '***' : '0.00000000' }} <span data-v-74541b3f="" class="currency">{{currencyName}}</span></div>
                     </div>
                     <div data-v-74541b3f="" class="col-md-6">
                        <table data-v-74541b3f="">
                           
                        </table>
                     </div>
                  </div>
               </div> -->
            </div>
         </div>
         <div data-v-74541b3f="" class="row mb8">
            <div data-v-74541b3f="" class="col-sm-12 mb8">
                <router-link to="/my/withdraw" data-v-74541b3f="" class="btn primary outline medium fluid"><span data-v-74541b3f=""><i data-v-74541b3f="" class="fa fa-paper-plane"></i> {{$t('walletSection.withdraw')}}</span></router-link>
            </div>
         </div>
         <h2 data-v-74541b3f="" class="mb16 pt16">{{$t('transactionsCompleted')}}</h2>
         <div data-v-74541b3f="">
            <div data-v-74541b3f="" class="panel panel-transactions">
               <div data-v-74541b3f="" class="transactions-list">

                <div class="table-wrap relative table active-algorithms mb48">
                    <div class="table-container">
                        <table class="nh padding-normal">
                            <thead>
                                <tr>
                                    <th scope="col">{{$t('amount')}}</th>
                                    <th scope="col" class="text-right">{{$t('dateTime')}}</th>
                                    <th scope="col" class="text-right">{{$t('contractDetail.hashrate')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(referrals, index) in logs" :key="index">
                                    <td>{{parseFloat(referrals.amount_usd).toFixed(2)}} USD</td>
                                    <td class="text-right">{{ formatDate(referrals.payment_date) }}</td>
                                    <td class="text-right">{{referrals.hash_rate}}</td>
                                </tr>
                            </tbody>

                        </table>
                        <div class="overlay loader" v-if="isLoading">
                            <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                        </div>
                
                  
                  <div data-v-74541b3f="" class="empty-state" v-if="!isLoading && logs.length === 0">
                     <div data-v-74541b3f="" class="empty-content">
                        <div data-v-74541b3f="" class="empty-icon">
                            <i data-v-74541b3f="" class="far fa-file"></i>
                        </div>
                        <div data-v-74541b3f="" class="empty-title">{{$t('noTransactions')}}</div>
                        <div data-v-74541b3f="" class="empty-subtitle mt8 mb16">{{$t('noTransactionsSub')}}</div>
                     </div>
                  </div>
                </div>
                </div>

               </div>
               
            </div>
         </div>
      </div>
   </div>
</div>




    </div>


    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue'
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from "vuex";
export default {
  name: 'CloudhashxFrontendBalanceDetails',
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  components: {
    Sidebar, FooterDash
  },
  data() {
    return {
        isLoading: false,
        logs: []
    };
  },
  computed: {
    currencyName() {
      return this.$route.params.currency;
    },
    ...mapGetters({
      balances: "user/getBalances",
      prices: "user/getPrices",
      isBalanceHidden: 'user/isBalanceHidden'
    }),
    selectedBalance() {
      // Obtén el balance específico desde Vuex
      return parseFloat(this.balances[this.coinCode]) || 0;
    },
    selectedPrice() {
      // Obtén el precio específico desde Vuex
      return this.prices[this.cryptoName] || 1;
    },
    valueInFiat() {
        // Balance directamente en USD
        return this.selectedBalance.toFixed(2);
    },
    valueInCrypto() {
        // Balance convertido a la criptomoneda correspondiente
        return (this.selectedBalance / this.selectedPrice).toFixed(8);
    },
    coinCode() {
      // Determina el campo del balance en Vuex
      return `${this.currency.toLowerCase()}_balance`;
    },
    cryptoName() {
      // Mapea el coinType al nombre usado en los precios
      const mapping = {
        BTC: "bitcoin",
        LTC: "litecoin",
        DOGE: "dogecoin",
        BCH: "bitcoin-cash",
      };
      return mapping[this.coinType] || "bitcoin";
    },
  },
  mounted() {
    //console.log(this.currencyName)
    this.getTransactions();
  },
  methods: {
    getTransactions(){
        this.isLoading = true;
        axios.get(process.env.VUE_APP_API_URL + 'getTransactionsData/'+this.currencyName)
        .then((res)=>{
            //console.log(res.data)
            this.logs = res.data;
            this.isLoading = false;
        })
        .catch((error)=>{
            console.log(error)
            this.isLoading = false;
        })
    },
    formatDate(date){
      return moment.utc(date).local().fromNow();
      // moment(date).fromNow();
    },
    convertName(name){
      const names = {
        BTC: "Bitcoin",
        BCH: "Bitcoin Cash",
        LTC: "Litecoin",
        DOGE: "DogeCoin"
      };
      return names[name] || "No Name";
    },
    getIconPath(cryptoName) {
      const icons = {
        BTC: require("@/assets/images/currencies/btc.png"),
        BCH: require("@/assets/images/currencies/bch.png"),
        LTC: require("@/assets/images/currencies/ltc.png"),
        DOGE: require("@/assets/images/currencies/doge.png"),
      };
      return icons[cryptoName] || "@/assets/images/currency/default-logo.svg"; // Logo por defecto
    },
  },
};
</script>
<style scoped>
.ml-2 {
    margin-left: 3px
}
#app_holder.gray-bg {
    background: #f5f5f5;
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.wallet-container[data-v-74541b3f] {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 32px
}

@media only screen and (max-width : 768px) {
    .wallet-container[data-v-74541b3f] {
        flex-wrap:wrap
    }
}

.sidebar[data-v-74541b3f] {
    width: 264px
}

@media only screen and (max-width : 991px) {
    .sidebar[data-v-74541b3f] {
        width:224px
    }
}

@media only screen and (max-width : 768px) {
    .sidebar[data-v-74541b3f] {
        width:100%;
        margin-bottom: 8px
    }
}

.sidebar .back[data-v-74541b3f] {
    font-size: 16px;
    color: #002BAE;
    display: inline-block;
    margin-top: 16px;
    margin-bottom: 14px
}

.sidebar .back i.fa[data-v-74541b3f] {
    font-size: 1.1em;
    position: relative;
    top: 1px;
    margin-right: 8px
}

.sidebar .wallets[data-v-74541b3f] {
    position: relative;
    min-height: 70px;
    margin-top: 16px
}

.sidebar .wallet[data-v-74541b3f] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    position: relative;
    color: #3a3939;
    border-left: 4px solid transparent
}

.theme-dark .sidebar .wallet[data-v-74541b3f] {
    color: #eee
}

.sidebar .wallet button[data-v-74541b3f] {
    padding-left: 12px;
    padding-right: 12px
}

.sidebar .wallet .name[data-v-74541b3f] {
    display: flex;
    align-items: center;
    font-size: 12px
}

.sidebar .wallet .name img[data-v-74541b3f] {
    position: relative;
    left: -5px;
    width: 20px;
    height: 20px;
    margin-right: 4px
}

.sidebar .wallet .values[data-v-74541b3f] {
    text-align: right;
    display: flex;
    flex-direction: column
}

.sidebar .wallet.selected[data-v-74541b3f] {
    border-radius: 4px;
    box-shadow: 0 5px 10px #2323231a;
    background-color: #fff;
}

.theme-dark .sidebar .wallet.selected[data-v-74541b3f] {
    background-color: #052339;
    border-top: 1px solid #40494f;
    border-right: 1px solid #40494f;
    border-bottom: 1px solid #40494f
}

.content[data-v-74541b3f] {
    flex: 1;
    margin-left: 32px
}

@media only screen and (max-width : 991px) {
    .content[data-v-74541b3f] {
        margin-left:24px
    }
}

@media only screen and (max-width : 768px) {
    .content[data-v-74541b3f] {
        margin-left:0;
        width: 100%
    }
}

.content h2[data-v-74541b3f] {
    margin-top: 8px
}

.content .coin-buttons[data-v-74541b3f] {
    text-align: right
}

.content .coin-buttons .btn[data-v-74541b3f] {
    vertical-align: middle
}

@media only screen and (max-width : 991px) {
    .content .coin-buttons[data-v-74541b3f] {
        display:flex;
        flex-direction: row
    }

    .content .coin-buttons .btn[data-v-74541b3f] {
        margin-top: 16px;
        flex: 1;
        margin-left: 4px;
        margin-right: 4px
    }

    .content .coin-buttons .btn[data-v-74541b3f]:first-child {
        margin-left: 0
    }

    .content .coin-buttons .btn[data-v-74541b3f]:last-child {
        margin-right: 0
    }
}

@media only screen and (max-width : 768px) {
    .content .coin-buttons .btn[data-v-74541b3f] {
        padding-left:6px;
        padding-right: 6px
    }
}

.content .coin-header[data-v-74541b3f] {
    border-radius: 4px;
    box-shadow: 0 1px 5px #3a39391a;
    background-color: #fff;
    min-height: 200px;
    margin-bottom: 16px
}

.theme-dark .content .coin-header[data-v-74541b3f] {
    background-color: #031a2a;
    border: 1px solid #40494f
}

.content .coin-header label[data-v-74541b3f] {
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    color: #959595;
    text-transform: uppercase;
    display: inline-block
}

.content .coin-header .value[data-v-74541b3f] {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 4px;
    margin-top: 6px
}

.content .coin-header .value.small[data-v-74541b3f] {
    font-size: 16px;
    margin-top: 4px
}

.content .coin-header .value .currency[data-v-74541b3f] {
    color: #959595
}

.content .coin-header small[data-v-74541b3f] {
    font-size: 14px;
    color: #959595
}

.content .coin-header .balance[data-v-74541b3f] {
    padding: 24px 26px;
    border-bottom: 1px solid #EEE
}

.theme-dark .content .coin-header .balance[data-v-74541b3f] {
    border-bottom: 1px solid #40494f
}

.content .coin-header .available[data-v-74541b3f] {
    padding: 24px 26px;
    width: 230px
}

.content .coin-header .pending[data-v-74541b3f] {
    padding: 24px 26px;
    flex: 1;
    border-left: 1px solid #EEE
}

.theme-dark .content .coin-header .pending[data-v-74541b3f] {
    border-left: 1px solid #40494f
}

.content .coin-header .pending table td.plabel[data-v-74541b3f] {
    font-size: 12px;
    line-height: 1.33
}

.content .coin-header .pending table td.pvalue[data-v-74541b3f] {
    font-size: 12px;
    line-height: 1.33;
    color: #959595;
    text-align: right
}

@media only screen and (max-width : 768px) {
    .content .panel-transactions[data-v-74541b3f] {
        margin:0 -16px
    }
}

.content .panel-transactions .transactions-header[data-v-74541b3f] {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEE
}

.theme-dark .content .panel-transactions .transactions-header[data-v-74541b3f] {
    border-bottom: 1px solid #40494f
}

.content .panel-transactions .pagination[data-v-74541b3f] {
    align-items: baseline;
    padding: 16px 32px
}

.content .panel-transactions .pagination .btn[data-v-74541b3f] {
    border: 1px solid #EEE;
    font-size: 12px!important;
    padding: 8px 16px;
    color: #fba342;
    display: inline-block;
    cursor: pointer;
    margin: 0
}

.theme-dark .content .panel-transactions .pagination .btn[data-v-74541b3f] {
    border: 1px solid #40494f
}

.content .panel-transactions .pagination .btn[data-v-74541b3f]:hover {
    border: 1px solid #FBA342
}

.content .panel-transactions .pagination .next[data-v-74541b3f] {
    float: right
}

.content .empty-state[data-v-74541b3f] {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 64px
}

.content .empty-state .empty-content[data-v-74541b3f] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.content .empty-state .empty-icon[data-v-74541b3f] {
    width: 85px;
    height: 85px;
    background: #fee8d0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.content .empty-state .empty-icon i[data-v-74541b3f] {
    font-size: 40px;
    color: #fba342
}

.content .empty-state .empty-title[data-v-74541b3f] {
    margin-top: 8px;
    font-size: 20px;
    line-height: 1.6;
    color: #959595;
    font-weight: 700
}

.content .empty-state .empty-subtitle[data-v-74541b3f] {
    font-size: 12px;
    line-height: 1.33;
    color: #959595;
    text-align: center
}

</style>