<template>
  <div id="app_holder">
    
    <div id="content">
        <Sidebar/>


        <div class="container">


            <div data-v-8a33967c="" class="banxa-holder">
   <div data-v-8a33967c="" class="row mb24">
      <div data-v-8a33967c="" class="col-nano-8">
         <h1 data-v-8a33967c="" class="fs20">{{$t('withdrawCrypto')}}</h1>
      </div>
      <div data-v-8a33967c="" class="col-nano-4 text-right"></div>
   </div>
   <div data-v-8a33967c="" class="banxa-box">

        <div class="toast-container position-fixed top-50 start-50 translate-middle">
                <div class="toast align-items-center border-0 fade" :class="{'show': showToastError ,'text-bg-danger': showToastError }" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body">
                            <p>{{errorMessage}}</p>
                        </div>
                        <button type="button" @click="closeToast" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
           </div>


           <div class="toast-container position-fixed top-50 start-50 translate-middle">
                <div class="toast align-items-center border-0 fade" :class="{'show': showToast ,'text-bg-success': showToast }" role="alert" aria-live="assertive" aria-atomic="true">
                    <div class="d-flex">
                        <div class="toast-body">
                            <p>{{message}}</p>
                        </div>
                        <button type="button" @click="closeToastSc" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>
           </div>


    <div class="overlay loader" v-if="isLoading">
                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
            </div>
      <div data-v-8a33967c="" class="form" v-else>
        
         <div data-v-45b1ddc6="" data-v-8a33967c="" class="field-wrap input-undefined mb32">
            <div data-v-45b1ddc6="" class="label-row">
               <label data-v-45b1ddc6="">
                {{$t('amount')}}
               </label>
            </div>
            <div data-v-8a33967c="" class="field">
               <div data-v-f5470495="" data-v-8a33967c="" class="input-group spend-amount">
                  <input data-v-f5470495="" step="0.001" min="0" class="spend-amount form-control" type="text" placeholder="Enter amount" maxlength="" v-model="amountFiat">
               </div>
               <div data-v-551aea43="" data-v-8a33967c="" class="selectbox" tabindex="0">
                  
                  <div data-v-551aea43="" class="field" aria-haspopup="true" aria-expanded="false">
                     <span data-v-8a33967c=""><img data-v-8a33967c="" class="icon" src="@/assets/images/currencies/usd.png"> USD</span>
                  </div>
                  
                  </div>
               </div>
            
         </div>
         <div data-v-45b1ddc6="" data-v-8a33967c="" class="field-wrap input-undefined mb32">
            <div data-v-45b1ddc6="" class="label-row">
               <label data-v-45b1ddc6="">
                  {{$t('receiving')}}
               </label>
            </div>
            <div data-v-8a33967c="" class="field">
               <div data-v-f5470495="" data-v-8a33967c="" class="input-group receive-amount">
                  <input data-v-f5470495="" readonly="" 
                  class="receive-amount form-control" type="text" 
                maxlength="" 
                  :value="calculatedCrypto" 
                  placeholder="≈ 0.00000000"
                  >
               </div>
               <!-- value="≈ 0.00094256" -->
               <div data-v-551aea43="" data-v-8a33967c="" class="selectbox" :class="{ open: isDropdownOpen }" tabindex="0">
                  <div data-v-551aea43="" class="field" @click="toggleDropdown" v-click-outside="closeDropdown" aria-haspopup="true" :aria-expanded="isDropdownOpen ? true :false">
                     <div data-v-551aea43="" class="arrows"><i data-v-551aea43="" class="fa fa-caret-up"></i><i data-v-551aea43="" class="fa fa-caret-down"></i></div>
                     <span data-v-8a33967c="">
                        <img data-v-8a33967c="" class="icon" 
                        :src="getCurrencyImage(selectedCurrency)"> {{ selectedCurrency.value }}
                    </span>
                  </div>
                  <div data-v-551aea43="" class="dropdown flex flex--no-wrap">
                     <div data-v-551aea43="" class="options" role="listbox">
                        <div class="option" 
                                :class="selectedCurrency.value === currency.value ? 'selected' : ''" 
                                role="option" 
                                :aria-selected="selectedCurrency.value === currency.value ? true :false" 
                                v-for="(currency, index) in supportedCurrencies" 
                                :key="index"
                                @click="changeCurrency2(currency)"
                                >
                            <img class="icon" :src="getCurrencyImage(currency)">
                            <span class="name">{{ currency.value }}</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div data-v-45b1ddc6="" data-v-8a33967c="" class="field-wrap input-undefined mb32">
            <div data-v-45b1ddc6="" class="label-row">
               <label data-v-45b1ddc6="">
                {{$t('btcWallet')}} 
               </label>
            </div>

            <div data-v-8a33967c="" class="field">
               <div data-v-f5470495="" data-v-8a33967c="" class="input-group">
                  <input data-v-f5470495="" readonly="" class="receive-amount form-control" type="text" placeholder="Enter amount" maxlength="" v-model="withdrawAddress">
               </div>
            </div>
        </div>
         
         
         <div data-v-8a33967c="" class="row mb32 mt24 text-muted">
            <div data-v-8a33967c="" class="col-sm-6 mb16">{{$t('coinBalance')}}</div>
            <div data-v-8a33967c="" class="col-sm-6 text-right">{{parseFloat(balanceFiat).toFixed(2)}} USD</div>
            <div data-v-8a33967c="" class="col-sm-6 mb16">{{$t('coinPrice')}}</div>
            <div data-v-8a33967c="" class="col-sm-6 text-right">$ {{priceCoin}} ≈ 1 {{coinType}}</div>
            <div data-v-8a33967c="" class="col-sm-6">{{$t('transactionLimits')}}</div>
            <div data-v-8a33967c="" class="col-sm-6 text-right"> Min 10 USD &nbsp; - &nbsp; Max 15000 USD</div>
         </div>
         <strong data-v-8a33967c="" class="fs14">{{$t('disclaimerW')}}</strong> 
         <br data-v-8a33967c="">
         <p data-v-8a33967c="" class="text-muted fs12 mb24">{{$t('withdrawDisclaimer')}}</p>
         <button type="submit" data-v-8a33967c="" class="btn primary medium fluid" @click="processWithdraw" :disabled="isSubmitting">
            <span v-if="!isSubmitting">{{$t('proceedBtn')}}</span>
            <span v-else>
                <span class="spinner-border spinner-border-sm align-middle ms-2" role="status" aria-hidden="true"></span>
                {{ $t('loggingIn') }}...
            </span>
        </button>
      </div>
      
   </div>
</div>

        </div><!-- container -->

    </div><!-- content -->
    


    <FooterDash/>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import FooterDash from './FooterDash.vue'
import axios from 'axios'
import clickOutside from '../directives/clickOutside';
import { mapGetters, mapActions, mapState } from 'vuex';
export default {
  name: 'CloudhashxFrontendNewWithdraw',
  components: {
    Sidebar, FooterDash
  },
  data() {
    return {
        toastTimeout: null,
        isLoading: false,
        balanceDetails: null,
        balanceFiat: 0,
        priceCoin: 0,
        amountFiat: 10,
        isDropdownOpen: false,
        coinName: 'Bitcoin (BTC)',
        coinType: 'BTC',
        selectedCurrency: { value: 'BTC', image: 'btc.png' },
        supportedCurrencies: [
            { value: 'BTC', image: 'btc.png' },
            { value: 'DOGE', image: 'doge.png' },
            { value: 'BCH', image: 'bch.png' },
            { value: 'LTC', image: 'ltc.png' }
        ],
        withdrawAddress: '',
        showToastError: false,
        errorMessage: '',
        message: '',
        showToast: false,
        calculatedCrypto: 0,
        isSubmitting: false,
    };
  },
  destroyed() {
    clearTimeout(this.toastTimeout);
  },
  mounted() {
    this.getBalance()
  },
  watch: {
    amount(newValue) {
      this.calculateCrypto(newValue);
    },
    priceCoin(newPrice) {
      this.calculateCrypto(this.amountFiat);
    },
  },
  directives: {
    clickOutside,
  },
  methods: {
    ...mapActions({
        fetchBalances: "user/fetchBalances", 
        fetchPrices: "user/fetchPrices"
    }),
    async initializeBalances() {
      await Promise.all([this.fetchBalances(), this.fetchPrices()]);
    },
    calculateCrypto(amount) {
      if (!this.priceCoin || amount <= 0) {
            this.calculatedCrypto = "0.00000000";
            return;
        }
        this.calculatedCrypto = (amount / this.priceCoin).toFixed(8);
    },
    closeToast() {
        this.showToastError = false;
    },
    closeToastSc() {
        this.showToast = false;
    },
    processWithdraw() {
        if (this.isSubmitting) return;
    
        this.isSubmitting = true; // Evitar múltiples envíos

        // Verificar la conexión con el servidor
        if (!navigator.onLine) {
            // Mostrar un mensaje de error si no hay conexión a internet
            this.showToastError = true;
            this.errorMessage = 'No internet connection available. Please try again later.';
            this.toastTimeout = setTimeout(() => {
                this.showToastError = false;
                this.errorMessage = '';
                this.isSubmitting = false;
            }, 5000);
            return;
        }

        // Verificar si el monto a retirar es mayor que el saldo disponible
        if (this.amountFiat > this.balanceFiat) {
            // Mostrar un mensaje de error si el monto es mayor que el saldo
            this.showToastError = true;
            this.errorMessage = `Insufficient ${this.coinType} balance`;
            this.toastTimeout = setTimeout(() => {
                this.showToastError = false;
                this.errorMessage = '';
                this.isSubmitting = false;
            }, 5000);
            return;
        }


        // Crear un objeto con los datos del retiro
        const withdrawData = {
            usd: this.amountFiat,
            amount: this.calculatedCrypto,
            address: this.withdrawAddress,
            coinType: this.coinType
        };

        // Enviar la solicitud al backend
        axios.post(`${process.env.VUE_APP_API_URL}withdraw`, withdrawData)
            .then((response) => {
                // Mostrar mensaje de éxito
                this.showToast = true;
                this.message = response.data.message;
                this.toastTimeout = setTimeout(() => {
                    this.showToast = false;
                    this.message = '';
                }, 5000);

                // Actualizar el saldo local
                this.amountFiat = 10;
                this.getBalance();
                this.initializeBalances();

            })
            .catch((error) => {
                // Manejar errores del backend
                this.showToastError = true;
                this.errorMessage = error.response?.data?.error || 'An error occurred. Please try again.';
                this.toastTimeout = setTimeout(() => {
                    this.showToastError = false;
                    this.errorMessage = '';
                }, 5000);
            })
            .finally(() => {
                this.isSubmitting = false;
            });

    },
    getBalance() {
        this.isLoading = true;
        axios.get(process.env.VUE_APP_API_URL + 'getBalanceToWithdraw',{
            params: {
                coinType: this.coinType
            }
        })
        .then((res)=>{
            this.priceCoin = parseFloat(res.data.balance_in_crypto);
            this.balanceFiat = res.data.balance_in_fiat;
            this.withdrawAddress = res.data.wallet
            this.isLoading = false;
        })
        .catch((error)=>{
            this.showToastError = true;
            this.errorMessage = error.response?.data?.error;
            this.toastTimeout = setTimeout(() => {
                this.showToastError = false;
                this.errorMessage = '';
            }, 5000);
            console.log(error);
            this.isLoading = false;
        })
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeDropdown() {
        this.isDropdownOpen = false;
    },
    getCurrencyImage(currency) {
        if (currency.value === 'BNB') {
            return require(`@/assets/images/currencies/${currency.image}`);
        } else {
            return require(`@/assets/images/currencies/${currency.image.toLowerCase()}`);
        }
    },
    changeCurrency2(currency) {
      this.selectedCurrency = currency;
      this.coinType = currency.value;
      this.isDropdownOpen = false;
      this.getBalance();
    },
  },
};
</script>
<style scoped>
#app_holder.gray-bg {
    background: #f5f5f5;
}

#content {
    flex: 1 0 auto;
    padding-bottom: 32px;
    position: relative;
}

#app_holder {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.banxa-holder {
    max-width: 576px;
    margin: 40px auto
}

.banxa-holder h1 img {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
    position: relative;
    top: -2px
}

.banxa-holder .btn-group[data-v-8a33967c] {
    width: 100%
}

.banxa-holder .btn-group .btn[data-v-8a33967c] {
    width: 50%
}

.banxa-holder .btn-group .btn[data-v-8a33967c] span {
    width: 90%;
    display: inline-block
}

.banxa-box[data-v-8a33967c] {
    box-shadow: 0 1px 4px #23232329;
    border-radius: 4px;
    margin-top: 16px;
    background: #fff
}

.theme-dark .banxa-box[data-v-8a33967c] {
    background: #031a2a;
    border: 1px solid #40494f
}

.banxa-box .form[data-v-8a33967c] {
    padding: 24px
}

.banxa-box .form .field[data-v-8a33967c] {
    display: flex;
    flex-direction: row;
    box-shadow: 0 1px 4px #23232329;
    height: 62px;
    padding: 11px 8px;
    border-radius: 4px
}

.theme-dark .banxa-box .form .field[data-v-8a33967c] {
    border: 1px solid #40494f
}

.banxa-box .form .field[data-v-8a33967c] .spend-amount,.banxa-box .form .field[data-v-8a33967c] .receive-amount,.banxa-box .form .field[data-v-8a33967c] .min-receive-amount {
    flex: 1;
    box-shadow: none!important
}

.banxa-box .form .field[data-v-8a33967c] .spend-amount input,.banxa-box .form .field[data-v-8a33967c] .receive-amount input,.banxa-box .form .field[data-v-8a33967c] .min-receive-amount input {
    flex: 1;
    border: 0!important;
    font-size: 24px;
    padding: 6px 0 0 8px;
    background: transparent
}

.banxa-box .form .field[data-v-8a33967c] .spend-amount input::-moz-placeholder,.banxa-box .form .field[data-v-8a33967c] .receive-amount input::-moz-placeholder,.banxa-box .form .field[data-v-8a33967c] .min-receive-amount input::-moz-placeholder {
    font-size: 24px
}

.banxa-box .form .field[data-v-8a33967c] .spend-amount input::placeholder,.banxa-box .form .field[data-v-8a33967c] .receive-amount input::placeholder,.banxa-box .form .field[data-v-8a33967c] .min-receive-amount input::placeholder {
    font-size: 24px
}

.banxa-box .form .field .selectbox[data-v-8a33967c] {
    width: 120px;
    border: 0!important
}

.banxa-box .form .field .selectbox[data-v-8a33967c] .option .icon,.banxa-box .form .field .selectbox[data-v-8a33967c] .field .icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 4px
}

.banxa-box .form .field .selectbox[data-v-8a33967c] .option div.icon,.banxa-box .form .field .selectbox[data-v-8a33967c] .field div.icon {
    line-height: 24px;
    color: #fff;
    border-radius: 24px;
    background: #171796;
    text-align: center;
    font-size: 9px;
    font-weight: 700;
    display: inline-block
}

.theme-dark .banxa-box .form .field .selectbox[data-v-8a33967c] .field {
    border: 0!important
}

.banxa-box .form .field .append[data-v-8a33967c] {
    padding: 11px 6px
}

.banxa-box .form .payment-method[data-v-8a33967c] .options {
    padding: 16px
}

.banxa-box .form .payment-method[data-v-8a33967c] .options .option:not(.group) {
    background: #fafafa;
    padding: 16px;
    margin-bottom: 4px
}

.theme-dark .banxa-box .form .payment-method[data-v-8a33967c] .options .option:not(.group) {
    background: #052339
}

.banxa-box .form .payment-method[data-v-8a33967c] .options .option:not(.group).selected {
    background: #fba342
}

.banxa-box .form .payment-method[data-v-8a33967c] .options .group {
    padding-left: 0
}

.banxa-box .form .payment-method[data-v-8a33967c] .group {
    font-size: 12px;
    background-color: transparent;
    color: #959595
}

.banxa-box .form .payment-method[data-v-8a33967c] .group .icon {
    width: 16px;
    margin-right: 6px;
    display: inline-block
}

.banxa-box .form .payment-method[data-v-8a33967c] .group .icon img {
    width: 100%;
    vertical-align: middle
}

.banxa-box .form .payment-method[data-v-8a33967c] .group:hover {
    background-color: transparent!important
}

.banxa-box .form .payment-method[data-v-8a33967c] .method {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 16px;
    position: relative;
    white-space: pre-wrap
}

.banxa-box .form .payment-method[data-v-8a33967c] .method .zerodepfee {
    position: absolute;
    top: -24px;
    right: -16px;
    background: linear-gradient(90deg,#fba342,#fbc241 97.15%);
    color: #fff;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: 700
}

.banxa-box .form .payment-method[data-v-8a33967c] .method .icon {
    width: 24px;
    margin-right: 12px
}

.banxa-box .form .payment-method[data-v-8a33967c] .method .icon img {
    width: 24px;
    vertical-align: middle;
    height: auto
}

.banxa-box .form .payment-method[data-v-8a33967c] .method .name {
    flex: 1
}

.banxa-box .form .payment-method[data-v-8a33967c] .method .right {
    text-align: right;
    font-size: 12px
}

.banxa-box .form .payment-method[data-v-8a33967c] .field .method {
    align-items: center;
    height: 72px
}

.banxa-box .form .bank-providers[data-v-8a33967c] .method {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 16px;
    position: relative;
    white-space: pre-wrap
}

.banxa-box .form .bank-providers[data-v-8a33967c] .method .flag {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 12px
}

.banxa-box .form .bank-providers[data-v-8a33967c] .method .flag.eu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAABtlBMVEUAAAAAM5kAM5kAM5kAMpkAMpkAMpkAMpkAMpkAM5kAM5kAM5kAMpkAM5kAM5kAM5kAMpkAMpkAM5kAM5kAMpkAM5kAMpkAM5kAMpkAM5kgRoUAM5kAMplzeFMAMpkAM5gNO5BqclludVbUshlYZ2Q5VXbeuBP+ywAqTH/TshlfbF+SikG9pCeZjj2RikEvT3y0nyxjbl0GNpWMh0TswQpPYmkCNJclSYIjSIP6yQLNrh1yd1R8fU4aQom4oSohR4QVQIsBM5gUP4wENZZ4e1APPI/2xgUYQYpEW3C/piXRsBvBpiVMYGs7VnXtwQryxAdDW3ALOpH1xgXduBMTPo1OYmlvdVZmcFtYaGMZQomjlTYQPI/0xQZibl0fRYbEqCPrwAtNYWoDNZbIqyAHN5SPiEPjuxAuTn04VXYSPo1UZWZBWnEDNJfbthU9WHOIhEePiUKNh0T9ywCWjT4JOJPlvA/vwgkFNpU0Unm5oilGXW4kSYImSYJgbF/YtBefkjnFqSKlljWdkTqAf0zovg08V3QnSoGOiENwdlWtmzCZjzzDqCPwwwgQPY4KOZKmljXuwgkeRYYRd2VxAAAAH3RSTlMAHlaJr8/n9/8CPpHbPBJ43fMEbO8oyWD1k/8Isf+5fP690wAAA01JREFUeAGk1IOSK0EUBuAT/7Ft9tq2ray97/8UF506Uxmls5UvmGm7yY7D6XJ7vD7A5/W4XU4H/YY/EAzBIBQM+2k4kWgMlmLRyDDFXXHYirtUVSSSKQyUSiZogHQGSpk02crmMIRc1q77eZgVCjDLWw6jWIKFchkWSkWL8hVoUIVUqzeazUa9pkVqKkVT/3Xtt9DT7gjRafdHspJxFLrxj43zW0OIBr+Pj+nmgXSy0FQnJqfE9Mws/pubn5+TL7Mz02JqcqIKjW4t0v3rt7AoxNKyfF1ZBVZX5OvykhCLC/2rmSZNQr9/1sT6Bkw21sWafkcliCWhs7m1vbMLg92d7a1N6CS185OCzh6wfwCDg32Z0C/FJ8sFdggVXSYXSZE42BFUdJnivS5EwY5PTqF0enIMFpX3F98/Z+cXonl5hYGuLpvi4vyM7yg/EQXArm/E7R0U7m7FzTVYmIiCYN37h/VHKDyuP9x3wYJEFAJ7et5/eYXC68v+8xNYiMgBzYL8KRgyOciJkTjJhZG4yI2RuMkD9gYlc1YPecHeuxhS9x3MSz5IH2+Fz6+30yqUqqdvX5+Ftw9IPkLP98/f0suaeW4YDqLhbKhO9R+Ff12YWpdhqIPHzEw+ZvzEAd/IkUGRZ/SaYz7vviWiHz8RgJ8/iOj7NxzhT4BQmOglAvGSKBziF/hHQOR9NPYMgXgWi76PgH8E/iXGE0imEIhUEom4/SWehxbntf9I+n9l7YPpNATSUOO802kxUJDJRqAkks0I8idGWu5Wngq3Pioi8VaB8rdyYqRds7OmWCIqV/BfKmWiUvGxGKp2rMOoUq0OBfUaVQ3Yse4olrtvGs0WFLSajTd37WJxVls7A6MDBR0Dmbaj2sRydUqW8sqzknoH68KHLoOk3t2CgXiPwQPrxSERDKfisK9mnwbDB7B4/hwWD4YD6ptfmUNxJJIVGhGNef9OJry7x0SjkESyHJoHTOnza35+NuPnXn+mKQSuy0XzZJ5cvLBUd7lar1dLS3VfLJLzE4doylV3A7Bjam93RLvtMfcZsHGorky2HeT3+zwcOGRbqfvMPBxM5qf7AQfH8xAQeh5wcOhPHr3RpT/79Ien/vT9x6Vrwce39vz/DcL9z+2n3bHKAAAAAElFTkSuQmCC)
}

.banxa-box .form .bank-providers[data-v-8a33967c] .method .flag.ch {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAWlBMVEUAAAD/AAD+AAD/AAD+AAD+AAD+AAD/AAD+AAD+AAD+AAD+AAD+AAD/AAD+AAD+AAD+AAD+AAD+AAD+AAD+AAD+AAD+AAD/AAD+AAD+AAD+AAD+AAD+AAD+/v62Omn/AAAAHHRSTlMAEokEVufPApOv2+/3Ht3zkWyxKHhg9QjJPLk+WumsqgAAATNJREFUeNqll9mygjAAQ0PZ9x1Bc///N++DzoiC2jbnvWdK6ZLgE3Vk8iSuyCpOchPVcCG9dQXfKLothR3B1PCUZgpshpuWH2nNL0XYl/xK2Yf4wrjyJ+uIj8wLLVhmnBNmtCQLccJloDXD5WT8lQ5cD4ZwoBNDiFcyOpLhhZlH/nbwyIwd4+IuWMbdAqx0F3B9LkNPHwF7PAhKP0EZ4I6hn4DmMYHWV9DepzDRV8AJANLGX9CkAG70F3AD0CmCDkChCAqgpiJgjUgTRDD7IZbwiUGuCXIkmiBBrAliVJqgAjUBZYH8CfIiyr9R3kjyVpYPk3yc9QtFv9I2RbDJ17r6sMhPm/q4ys+7GjDkiKOGLDXmyUFTj7p62Nbjvl449Mqjly699unFU6++R9LNvnzL9f8fnCxhhC7AUOcAAAAASUVORK5CYII=)
}

.banxa-box .form .bank-providers[data-v-8a33967c] .method .name {
    flex: 1
}

.banxa-box .form .bank-providers[data-v-8a33967c] .field .method {
    align-items: center;
    height: 72px
}

.banxa-box .review[data-v-8a33967c] {
    padding: 24px
}

.banxa-box .finish[data-v-8a33967c] {
    padding: 40px 24px;
    text-align: center
}

.banxa-box .finish .icon[data-v-8a33967c] {
    width: 96px;
    height: 96px;
    border-radius: 96px;
    line-height: 96px;
    color: #68b641;
    background: #dbeed2;
    margin: 0 auto 40px;
    font-size: 40px
}

.banxa-box .finish .icon.processing[data-v-8a33967c] {
    color: #fba342;
    background: #fee8d0
}

.banxa-box .finish p[data-v-8a33967c] {
    font-size: 20px
}

.banxa-box .finish .btn[data-v-8a33967c] {
    min-width: 200px;
    margin: 0 16px
}


div.selectbox .field[data-v-551aea43] {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.banxa-box .form .field .selectbox .option .icon, .banxa-box .form .field .selectbox .field .icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 4px;
}

div.selectbox .arrows[data-v-551aea43] {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 15px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
}

div.selectbox .dropdown[data-v-551aea43] {
    position: absolute;
    background: #fff;
    z-index: 2;
    min-width: 100%;
    box-shadow: 0 1px 4px #23232329;
    max-height: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: max-height .2s;
    flex-direction: column;
    overflow: hidden;
}

.flex--no-wrap {
    flex-wrap: nowrap;
}

div.selectbox .arrows i[data-v-551aea43] {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    top: 3px;
    left: 5px;
}

div.selectbox .arrows[data-v-551aea43] {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 15px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
}

div.selectbox .field[data-v-551aea43] {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px #23232329;
    font-size: 14px;
    border: 1px solid transparent;
    color: #3a3939;
    transition: .2s ease box-shadow;
    padding: 0 32px 0 16px;
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

div.selectbox.open .field[data-v-551aea43] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

div.selectbox .dropdown .options[data-v-551aea43] {
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
}

div.selectbox[data-v-551aea43] {
    position: relative;
}

div.selectbox .arrows i {
    display: block;
    font-size: 12px;
    line-height: 6px;
    color: #cecdcd;
    position: relative;
    top: 3px;
    left: 5px;
}

div.selectbox .arrows {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 15px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
}

div.selectbox.open .dropdown {
    max-height: 350px
}

.theme-dark div.selectbox.open .dropdown {
    border: 1px solid #40494f
}

div.selectbox.up.open .field {
    border-radius: 0 0 4px 4px
}

.banxa-box .form .field .selectbox .option .icon, .banxa-box .form .field .selectbox .field .icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 4px;
}

div.selectbox .dropdown .options .option:hover {
    background-color: #3883dd40
}

div.selectbox .dropdown .options .option.selected {
    background-color: #3883dd;
    color: #fff
}

div.selectbox .dropdown .options .option.disabled {
    opacity: .5;
    cursor: default
}

div.selectbox .option .icon {
    width: 24px
}

div.selectbox .option .icon i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 24px;
    background: #fee8d0;
    color: #fba342;
    text-align: center;
    display: inline-block;
    vertical-align: middle
}

div.selectbox .dropdown .options .option {
    padding: 8px 16px;
    cursor: pointer;
}

div.selectbox .dropdown .options .option .icon, .banxa-box .form .field .selectbox[data-v-8a33967c] .field .icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 4px;
}

.input-group[data-v-f5470495] {
    position: relative;
}

.btn.primary {
    background: linear-gradient(90deg,#19345D,#19344D);
    color: #fff;
    box-shadow: 0 20px 4px -18px #19345D52;
}
</style>