import axios from 'axios';

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || null,
        user: null,
        balance: 0,
        balances: {}, // Saldos por moneda
        prices: {}, // Precios de las criptomonedas
        requiresTwoFactorAuth: JSON.parse(localStorage.getItem('requiresTwoFactorAuth')) || false,
        hideAmounts: JSON.parse(localStorage.getItem('hideAmounts')) || false, // Carga el valor inicial desde localStorage
        notifications: [], // Lista de notificaciones
        newNotifications: [], // Lista de notificaciones no leídas
    },
    mutations: {
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
            state.newNotifications = notifications.filter(notification => !notification.is_read);
        },
        MARK_AS_READ(state, id) {
            const notification = state.notifications.find(n => n.id === id);
            if (notification) {
                notification.is_read = true;
            }
            state.newNotifications = state.notifications.filter(notification => !notification.read);
        },
        SET_BALANCES(state, balances) {
            state.balances = balances;
        },
        SET_PRICES(state, prices) {
            state.prices = prices;
        },
        TOGGLE_HIDE_AMOUNTS(state) {
            state.hideAmounts = !state.hideAmounts;
            localStorage.setItem('hideAmounts', JSON.stringify(state.hideAmounts)); // Persiste en localStorage
        },
        SET_HIDE_AMOUNTS(state, value) {
            state.hideAmounts = value;
            localStorage.setItem('hideAmounts', JSON.stringify(value)); // Persiste en localStorage
        },
        EL_TOKEN(state, token) {
            state.token = token
        },
        EL_USUARIO(state, data) {
            state.user = data
        },
        setBalance(state, newBalance) {
            state.balance = newBalance;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token); // Guardar token en localStorage
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Configurar encabezado global
        },
        CLEAR_TOKEN(state) {
            state.token = null;
            localStorage.removeItem('token');
            delete axios.defaults.headers.common['Authorization'];
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_BALANCE(state, newBalance) {
            state.balance = newBalance;
        },
        SET_REQUIRES_TWO_FACTOR_AUTH(state, status) {
            state.requiresTwoFactorAuth = status;
            localStorage.setItem('requiresTwoFactorAuth', JSON.stringify(status));
        },
    },
    getters: {
        allNotifications: state => state.notifications,
        unreadNotifications: state => state.newNotifications,
        autenticado(state) {
            return state.token && state.user
        },
        usuario(state) {
            return state.user
        },
        balance(state) {
            return state.balance
        },
        requiresTwoFactorAuth(state) {
            return state.requiresTwoFactorAuth;
        },
        isBalanceHidden: (state) => state.hideAmounts,
        getBalances: (state) => state.balances,
        getPrices: (state) => state.prices,
        convertedBalances: (state) => (currencies) => {
            return currencies.map((currency) => {
                const cryptoPrice = state.prices[currency.crypto] || 1; // Precio en USD
                const fiatBalance = parseFloat(state.balances[currency.code]) || 0; // Balance en FIAT
                return {
                    ...currency,
                    valueInCrypto: (fiatBalance / cryptoPrice).toFixed(8), // Balance en criptomonedas
                    valueInFiat: fiatBalance.toFixed(2), // Balance en FIAT
                };
            });
        },
        totalFiatBalance: (state, getters) => (currencies) => {
            return getters.convertedBalances(currencies).reduce(
                (total, currency) => total + parseFloat(currency.valueInFiat),
                0
            ).toFixed(2);
        },
    },
    actions: {
        async fetchNotifications({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'getNotifications');
                commit('SET_NOTIFICATIONS', response.data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        },
        toggleHideAmounts({ commit }) {
            commit('TOGGLE_HIDE_AMOUNTS');
        },
        setHideAmounts({ commit }, value) {
            commit('SET_HIDE_AMOUNTS', value);
        },
        async fetchBalances({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + "getCoinsBalances");
                commit("SET_BALANCES", response.data);
            } catch (error) {
                console.error("Error fetching balances:", error);
            }
        },
        async fetchPrices({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + "getPrices");
                const prices = response.data.prices.reduce((acc, item) => {
                    acc[item.crypto_name] = parseFloat(item.price);
                    return acc;
                }, {});
                commit("SET_PRICES", prices);
            } catch (error) {
                console.error("Error fetching prices:", error);
            }
        },
        async fetchSaldo({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_API_URL + 'getUserBalance');
                const newBalance = parseFloat(response.data.balance); // Ajusta según la estructura de tu respuesta
                commit('setBalance', newBalance);
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        },
        async signIn({ dispatch, commit }, credenciales) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_API_URL}login`, credenciales);
                commit('SET_TOKEN', res.data.token);

                // Verificar 2FA
                if (res.data.twoFactorEnabled) {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', true);
                    return { twoFactorEnabled: true };
                } else {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', false);
                    await dispatch('intento', res.data.token);
                    return { twoFactorEnabled: false };
                }
            } catch (error) {
                throw error.response || { message: "Unexpected error" };
            }
        },
        /* async signIn({ commit }, credenciales) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_API_URL}login`, credenciales);

                // Guardar token
                commit('SET_TOKEN', res.data.token);

                // Verificar si requiere 2FA
                if (res.data.twoFactorEnabled) {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', true);
                    return { twoFactorEnabled: true };
                } else {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', false);
                    await dispatch('intento', res.data.token);
                    //  commit('SET_USER', res.data.user); // Cargar usuario
                    return { twoFactorEnabled: false };
                }
            } catch (error) {
                throw error.response.data || { message: 'Error al iniciar sesión' };
            }
        }, */

        async intento({ commit }, token) {
            if (token) {
                commit('SET_TOKEN', token);
            }

            try {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}authData`);
                commit('SET_USER', res.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                commit('CLEAR_TOKEN');
                commit('SET_USER', null);
            }
        },
        async logout({ commit }) {
            commit('CLEAR_TOKEN');
            commit('SET_USER', null);
            commit('SET_REQUIRES_TWO_FACTOR_AUTH', false);
        },
        async verifyTwoFactorCode({ commit, dispatch }, code) {
            try {
                const res = await axios.post(`${process.env.VUE_APP_API_URL}twoFactorLogin`, { code });
                if (res.data.success) {
                    commit('SET_REQUIRES_TWO_FACTOR_AUTH', false); // Marcar como completado
                    await dispatch('intento', res.data.token); // Cargar datos del usuario
                } else {
                    throw new Error(res.data.error || 'Invalid verification code');
                }
            } catch (error) {
                throw error.response || { message: "Unexpected error" };
            }
        },
    }
}